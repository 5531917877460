import React, { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from './Text';

interface ModalProps {
	open: boolean;
	onClose: () => void;
	children: ReactNode;
	title?: string;
	className?: string;
	animationType?: 'slideUp' | 'slideRight' | 'fadeIn';
	onExitComplete?: () => void;
}

const Modal: React.FC<ModalProps> = ({
	open,
	onClose,
	children,
	className,
	title,
	animationType = 'slideUp',
	onExitComplete,
}) => {
	const [isVisible, setIsVisible] = useState(open);

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (
				(event.target as HTMLElement).classList.contains(
					'modal-overlay'
				)
			) {
				onClose();
			}
		};

		if (isVisible) {
			window.addEventListener('click', handleOutsideClick);
		}

		return () => {
			window.removeEventListener('click', handleOutsideClick);
		};
	}, [isVisible, onClose]);

	useEffect(() => {
		const handleEscape = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				onClose();
			}
		};

		if (isVisible) {
			window.addEventListener('keydown', handleEscape);
		}

		return () => {
			window.removeEventListener('keydown', handleEscape);
		};
	}, [isVisible, onClose]);

	useEffect(() => {
		if (open) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	}, [open]);

	const modalVariants = {
		slideUp: {
			hidden: { y: '100%', opacity: 0 },
			visible: { y: '0%', opacity: 1 },
			exit: { y: '100%', opacity: 0 },
		},
		slideRight: {
			hidden: { x: '100%' },
			visible: { x: '0%' },
			exit: { x: '100%' },
		},
		fadeIn: {
			hidden: { opacity: 0 },
			visible: { opacity: 1 },
			exit: { opacity: 0 },
		},
	};

	return ReactDOM.createPortal(
		<AnimatePresence onExitComplete={onExitComplete}>
			{isVisible && (
				<div className='modal-overlay p-0 m-0 fixed w-full h-screen bottom-0 left-0 bg-black bg-opacity-50 flex justify-center items-end z-50'>
					<motion.div
						className={`bg-white w-full h-screen ${className}`}
						variants={modalVariants[animationType]}
						initial='hidden'
						animate='visible'
						exit='exit'
						transition={{
							type: 'spring',
							stiffness: 280,
							damping: 30,
						}}
					>
						<div className='flex h-full flex-col items-end justify-between p-4 space-y-4'>
							<div className='w-full items-center flex flex-row justify-between'>
								<Text variant='h3'>{title}</Text>
								<FontAwesomeIcon
									icon={faArrowRight}
									className='text-primary-blue-darker text-lg'
									onClick={onClose}
								/>
							</div>
							<div className='flex w-full flex-grow'>
								{children}
							</div>
						</div>
					</motion.div>
				</div>
			)}
		</AnimatePresence>,
		document.getElementById('modal-root') as HTMLElement
	);
};

export default Modal;
