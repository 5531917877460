import {
	faChevronLeft,
	faChevronRight,
	faFlag,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../../../Components/ui/Text';

type HoleInfoProps = {
	currentHoleIndex: number;
	holeNumber: string;
	holePar: number;
	holeLength: number;
	onHoleChange: (direction: 'next' | 'prev') => void;
	onRoundComplete: () => void;
};

const HoleInfo = ({
	currentHoleIndex,
	holeNumber,
	holePar,
	holeLength,
	onHoleChange,
	onRoundComplete,
}: HoleInfoProps) => {
	return (
		<div className='fixed w-full bottom-0 left-0 z-10 h-16 flex justify-between items-center border-b p-4 shadow-sm bg-white'>
			{currentHoleIndex > 0 && (
				<FontAwesomeIcon
					icon={faChevronLeft}
					className='absolute left-4 top-1/2 transform -translate-y-1/2 text-2xl text-gray-700'
					onClick={() => onHoleChange('prev')}
				/>
			)}
			<div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-row space-x-2 items-center'>
				<FontAwesomeIcon
					icon={faFlag}
					className='text-sm text-gray-500'
				/>
				<Text variant='h3' color='dark'>
					{holeNumber.toString().padStart(2, '0')}
				</Text>
				<div>
					<Text variant='fine1' color='default'>
						Par {holePar}
					</Text>
					<Text variant='fine1' color='default'>
						{holeLength} m
					</Text>
				</div>
			</div>
			{currentHoleIndex < 17 ? (
				<FontAwesomeIcon
					icon={faChevronRight}
					className='absolute right-4 top-1/2 transform -translate-y-1/2 text-2xl text-gray-700'
					onClick={() => onHoleChange('next')}
				/>
			) : (
				<Text
					className='absolute right-4 top-1/2 transform -translate-y-1/2 text-primary-blue-darker'
					onClick={onRoundComplete}
				>
					Save
				</Text>
			)}
		</div>
	);
};

export default HoleInfo;
