import { AnimatePresence, motion } from 'framer-motion';
import { ReactElement, useState, useEffect } from 'react';

type PopupModalProps = {
	open: boolean;
	onClose: () => void;
	children: ReactElement;
	className?: string;
};

const PopupModal = ({
	open,
	onClose,
	children,
	className,
}: PopupModalProps) => {
	const [isVisible, setIsVisible] = useState<boolean>(open);

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (
				(event.target as HTMLElement).classList.contains(
					'modal-overlay'
				)
			) {
				onClose();
			}
		};

		if (isVisible) {
			window.addEventListener('click', handleOutsideClick);
		}

		return () => {
			window.removeEventListener('click', handleOutsideClick);
		};
	}, [isVisible, onClose]);

	useEffect(() => {
		const handleEscape = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				onClose();
			}
		};

		if (isVisible) {
			window.addEventListener('keydown', handleEscape);
		}

		return () => {
			window.removeEventListener('keydown', handleEscape);
		};
	}, [isVisible, onClose]);

	useEffect(() => {
		if (open) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	}, [open]);

	return (
		<AnimatePresence>
			{isVisible && (
				<div className='modal-overlay p-4 fixed w-full h-screen bottom-0 left-0 bg-black bg-opacity-50 flex justify-center items-center z-999'>
					<motion.div className='w-full h-64 bg-white p-4 rounded'>
						{children}
					</motion.div>
				</div>
			)}
		</AnimatePresence>
	);
};

export default PopupModal;
