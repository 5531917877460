import { Text } from '../../Components/ui/Text';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToggleTextSwitch from '../../Components/ui/ToggleTextSwitch';
import { useEffect, useRef, useState } from 'react';
import ExploreTournaments from './tournaments/ExploreTournaments';
import { useLocation, useNavigate } from 'react-router-dom';

const TABS = {
	ROUNDS: 'rounds',
	TOURNAMENTS: 'tournaments',
};

const ExplorePage = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const currentPath = location.pathname;
	const initialTab =
		currentPath.includes(TABS.ROUNDS) ? 0 : 1;
	
	const [index, setIndex] = useState<number>(initialTab);
	const [headerHeight, setHeaderHeight] = useState<number>(0);
	const headerRef = useRef<HTMLDivElement>(null);

	const handleToggle = (newIndex: number) => {
		setIndex(newIndex);

		const newPath = newIndex === 0 ? `/explore/${TABS.ROUNDS}` : `/explore/${TABS.TOURNAMENTS}`;
		navigate(newPath);
	};

	const renderPage = () => {
		if (index === 0) {
			return <div>Explore Rounds Content</div>; // Replace with actual content
		} else {
			return <ExploreTournaments />;
		}
	};

	useEffect(() => {
		if (headerRef.current) {
			setHeaderHeight(headerRef.current.offsetHeight);
		}
	}, []);

	return (
		<div className=''>
			<div
				ref={headerRef}
				className='fixed top-0 left-0 w-full z-10 bg-muted-60 p-4'
			>
				<div className='flex justify-between mb-4'>
					<Text variant='h2'>Explore</Text>
					<span className='flex items-center justify-center w-10 h-10 rounded-full bg-muted-gray'>
						<FontAwesomeIcon
							icon={faBell}
							className='h-4 w-4 text-gray-700'
						/>
					</span>
				</div>
				<ToggleTextSwitch
					options={['Rounds', 'Tournaments']}
					onToggle={handleToggle}
					initialIndex={index}
				/>
			</div>
			<div
				style={{
					marginTop: headerHeight + 'px',
				}}
				className='px-4 pb-4 mb-16'
			>
				{renderPage()}
			</div>
		</div>
	);
};

export default ExplorePage;
