import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store.ts';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider } from '@mui/material';
import theme from './Shared/theme.js';
import { ToastContainer } from 'react-toastify';

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ThemeProvider theme={theme}>
					<App />
					<ToastContainer
						toastStyle={{
							width: 'calc(100vw - 2.5rem)',
							margin: '1.25rem',
						}}
						toastClassName={() =>
							'bg-white text-gray-800 p-3 flex items-center justify-between rounded-md shadow-lg'
						}
						position='top-center'
						hideProgressBar={true}
						newestOnTop={true}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						limit={3}
						bodyClassName='custom-toast-body'
					/>
				</ThemeProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>
);
