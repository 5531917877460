import { NewRoundRequest } from '../models/Round/newRoundRequest';
import axiosClient from '../Shared/ApiClient';
import RoundDetailsDto from '../models/Round/RoundDetailsDto';

export const roundApi = {
	fetchAll() {
		const url = 'Rounds/All';
		return axiosClient.get<RoundDetailsDto[]>(url);
	},
	create(request: NewRoundRequest) {
		const url = 'rounds';
		return axiosClient.post<string>(url, request);
	},
};
