import React, { useEffect, useState } from 'react';
import NoData from './NoData';

const withStats = (WrappedComponent, fetchStats) => {
	const HOC = (props) => {
		const [stats, setStats] = useState(null);
		const [noRounds, setNoRounds] = useState(false);

		useEffect(() => {
			fetchStats()
				.then((data) => {
					setStats(data);
				})
				.catch((error) => {
					if (error.message === 'Round.NotFound') {
						setNoRounds(true);
					} else {
						console.error(error);
					}
				});
		}, []);

		if (noRounds) {
			return <NoData />;
		}

		if (stats === null) {
			return <div>Loading...</div>;
		}

		return <WrappedComponent stats={stats} {...props} />;
	};

	return HOC;
};

export default withStats;
