import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../../../../Components/ui/Text';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import imageCompression from 'browser-image-compression';
import { useEffect, useState } from 'react';
import { Button } from '../../../../Components/ui/Button';
import { toast } from 'react-toastify';
import { TournamentFormData } from '../../types/TournamentFormData';
import { useFormikContext } from 'formik';

const UploadMediaStep = () => {
	const { values, setFieldValue, errors, touched } =
    useFormikContext<TournamentFormData>();

	const [error, setError] = useState<string | null>(null);
	const [imagePreview, setImagePreview] = useState<string | null>(null);
	const MAX_SIZE_BYTES = 250 * 1024;

	useEffect(() => {
		if (values.thumbnail) {
			setImagePreview(URL.createObjectURL(values.thumbnail));
		}else{
			setImagePreview(null)
		}

		return () => {
      if (values.thumbnail) {
        URL.revokeObjectURL(URL.createObjectURL(values.thumbnail));
      }
    };
	}, [values.thumbnail]);

	const handleImageUpload = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const file = event.target.files?.[0];
		if (file) {
			const validTypes = ['image/jpeg', 'image/png'];
			if (!validTypes.includes(file.type)) {
				setError('Only PNG and JPEG images are allowed.');
				return;
			}
			console.log(file.size);
			console.log('max size:', MAX_SIZE_BYTES);
			if (file.size > MAX_SIZE_BYTES) {
				try {
					const compressedFile = await imageCompression(file, {
						maxSizeMB: 0.25,
						useWebWorker: true,
					});

					if (compressedFile.size > MAX_SIZE_BYTES) {
						setError(
							'Compressed image is still larger than 250 KB. Please choose a smaller image.'
						);
						toast.error(
							'Image is too large. Must be smaler than 250kb'
						);
						return;
					}
					console.log('compressed size', compressedFile.size);
					setFieldValue('thumbnail', compressedFile);
					setImagePreview(URL.createObjectURL(compressedFile));
					setError(null);
				} catch (err) {
					setError('Image too large, try again');
				}
			} else {
				setFieldValue('thumbnail', file);
				setImagePreview(URL.createObjectURL(file));
				setError(null);
			}
		}
	};

	return (
		<div className='flex flex-col w-full'>
			<Text variant='h2' className='mb-10'>
				Upload Documents
			</Text>
			<div
				className={`flex flex-col justify-center items-center w-full aspect-video rounded-md bg-muted-gray cursor-pointer ${
					imagePreview
						? 'border-none'
						: 'border-dashed border border-gray-400'
				}`}
				onClick={() =>
					document.getElementById('thumbnailInput')?.click()
				}
			>
				{imagePreview ? (
					<div className='relative w-full aspect-video'>
						<img
							src={imagePreview}
							alt='Thumbnail Preview'
							className='relative w-full aspect-video object-cover rounded-md'
						/>
						<div className='absolute flex items-center justify-center inset-0 bg-black opacity-50 rounded-md'></div>
						<div className='absolute inset-0 flex items-center justify-center'>
							<Button variant='outline' className='bg-opacity-20'>
								Change
							</Button>
						</div>
					</div>
				) : (
					<>
						<FontAwesomeIcon
							icon={faCloudArrowUp}
							className='text-primary-blue text-4xl mb-4'
						/>
						<Text variant='h5'>Thumbnail Image</Text>
						<Text
							variant='fine2'
							color='light'
							className='w-3/4 text-center'
						>
							Click this box to upload a thumbnail image.
						</Text>
					</>
				)}
				<input
					id='thumbnailInput'
					type='file'
					accept='image/*'
					className='hidden'
					onChange={handleImageUpload}
				/>
			</div>
			<div className='flex'>
				<Text>Attachments</Text>
			</div>
		</div>
	);
};

export default UploadMediaStep;
