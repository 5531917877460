import React, { useEffect, useState } from 'react';
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNullOrWhitespace } from '../../utils/utils';
import { Course } from '../../models/courseDetails.type';
import useMyDebounce from '../../Hooks/useMyDebounce';
import { useCourseSearchQuery } from '../../features/course/courseApiSlice';
import { Text } from './Text';

type SearchBarProps = {
	onSelect: (course: Course) => void;
	selectedCourse: Course | null;
};

const SearchBar = ({ onSelect, selectedCourse }: SearchBarProps) => {
	//console.log(selectedCourse);
	const [searchTerm, setSearchTerm] = useState('');
	const [openDropdown, setOpenDropdown] = useState(false);
	const [selectedItem, setSelectedItem] = useState<Course | null>(
		selectedCourse
	);

	const debouncedSearchTerm = useMyDebounce<string>(searchTerm, 300);
	const { data: courses, isFetching } = useCourseSearchQuery(
		debouncedSearchTerm,
		{
			skip: isNullOrWhitespace(debouncedSearchTerm),
		}
	);

	const handleSearchTermUpdated = (value: string) => {
		console.log('search term', value);
		if (selectedItem !== null) setSelectedItem(null);
		if (!openDropdown) {
			setOpenDropdown(true);
		}
		if (isNullOrWhitespace(value)) {
			setOpenDropdown(false);
		}
		setSearchTerm(value);
	};

	const handleSelect = (course: Course) => {
		setOpenDropdown(false);
		setSelectedItem(course);
		onSelect(course);
	};

	return (
		<div className='w-full relative'>
			<div className='flex items-center w-full border border-gray-300 bg-white rounded h-10 py-4'>
				<div className='w-full px-3 space-x-4 flex flex-row justify-between items-center'>
					<FontAwesomeIcon
						icon={faSearch}
						className='text-base text-primary-blue'
					/>
					<input
						type='text'
						placeholder='Search Course'
						value={
							selectedItem != null && !openDropdown
								? selectedItem.name
								: searchTerm
						}
						onChange={(e) =>
							handleSearchTermUpdated(e.target.value)
						}
						className='placeholder:font-extralight font-light text-gray-600 rounded w-full focus:outline-none border-none focus:border-none c placeholder:tracking-widest'
					/>
					{!isNullOrWhitespace(searchTerm) ||
						(!isNullOrWhitespace(selectedItem?.name) && (
							<FontAwesomeIcon
								icon={faXmark}
								className='text-2xl text-gray-400 cursor-pointer hover:text-gray-500'
								onClick={() => handleSearchTermUpdated('')}
							/>
						))}
				</div>
			</div>

			{openDropdown && (
				<ul className='mt-4 p-4 space-y-4 max-h-60 border border-gray-300 rounded absolute w-full bg-white overflow-y-scroll overflow-x-hidden z-50'>
					{courses && courses.length > 0 ? (
						courses.map((course) => (
							<li
								key={course.courseId}
								onClick={() => {
									handleSelect(course);
								}}
								className='flex items-center w-full text-gray-700 border-gray-200 cursor-pointer hover:bg-gray-50'
							>
								<div>
									<Text className='whitespace-nowrap overflow-hidden text-ellipsis'>
										{course.name}
									</Text>
									<Text variant='fine1' color='default'>
										{course.city}, {course.country}
									</Text>
								</div>
							</li>
						))
					) : (
						<li className='w-full p-2 text-gray-700 border-gray-200 cursor-default'>
							No Results Found
						</li>
					)}
				</ul>
			)}
		</div>
	);
};

export default SearchBar;
