import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartDataLabels
);

const HorizontalBarChart = ({ averages }) => {
	const data = {
		labels: ['Average Putts Per Hole', 'Average Putts per GIR'],
		datasets: [
			{
				data: [
					averages.averagePuttsPerHole.toFixed(1),
					averages.averagePuttsPerGIR.toFixed(1),
				],
				backgroundColor: [
					'rgba(75, 192, 192, 0.2)',
					'rgba(153, 102, 255, 0.2)',
				],
				borderColor: [
					'rgba(75, 192, 192, 1)',
					'rgba(153, 102, 255, 1)',
				],
				borderWidth: 1,
			},
		],
	};

	const options = {
		indexAxis: 'y',
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
			},
		},
		scales: {
			x: {
				beginAtZero: true,
			},
		},
	};

	return <Bar data={data} options={options} />;
};

export default HorizontalBarChart;
