import { useFormikContext } from 'formik';
import { Text } from '../../../../Components/ui/Text';
import { Input } from '../../../../Components/ui/Input';
import { TournamentFormData } from '../../types/TournamentFormData';

const ContactInfoStep = () => {
	const { values, handleChange, handleBlur, errors, touched } =
		useFormikContext<TournamentFormData>();

	return (
		<div className='flex flex-col w-full'>
			<Text variant='h2' className='mb-10'>
				Contact Details
			</Text>
			<div className='flex flex-col space-y-3'>
				<Input
					type='text'
					label='First Name'
					name='contactGivenName'
					value={values.contactGivenName}
					onChange={handleChange}
					onBlur={handleBlur}
					variant={
						touched.contactGivenName && errors.contactGivenName
							? 'error'
							: 'default'
					}
					error={
						touched.contactGivenName && errors.contactGivenName
							? errors.contactGivenName
							: ''
					}
				/>
				<Input
					type='text'
					label='Last Name'
					name='contactSurname'
					value={values.contactSurname}
					onChange={handleChange}
					onBlur={handleBlur}
					variant={
						touched.contactSurname && errors.contactSurname
							? 'error'
							: 'default'
					}
					error={
						touched.contactSurname && errors.contactSurname
							? errors.contactSurname
							: ''
					}
				/>
				<Input
					type='email'
					label='Email'
					name='contactEmail'
					value={values.contactEmail}
					onChange={handleChange}
					onBlur={handleBlur}
					variant={
						touched.contactEmail && errors.contactEmail
							? 'error'
							: 'default'
					}
					error={
						touched.contactEmail && errors.contactEmail
							? errors.contactEmail
							: ''
					}
				/>
				<Input
					type='text'
					label='Ph. (Mobile)'
					name='contactPhone'
					value={values.contactPhone}
					onChange={handleChange}
					onBlur={handleBlur}
					variant={
						touched.contactPhone && errors.contactPhone
							? 'error'
							: 'default'
					}
					error={
						touched.contactPhone && errors.contactPhone
							? errors.contactPhone
							: ''
					}
				/>
			</div>
		</div>
	);
};

export default ContactInfoStep;
