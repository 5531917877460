import { Text } from './Text';
import { Button } from './Button';

type ConfirmationBoxProps = {
	onClose: () => void;
	onConfirm: () => void;
	title: string;
	description: string;
};
const ConfirmationBox = ({
	onClose,
	onConfirm,
	title,
	description,
}: ConfirmationBoxProps) => {
	return (
		<div className='relative w-full h-full bottom-0'>
			<button
				className='absolute top-0 right-0 w-8 h-8 border rounded-full border-gray-400 focus:outline-none'
				onClick={onClose}
			>
				<span className='block w-[70%] h-px bg-gray-600 transform rotate-45 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'></span>
				<span className='block w-[70%] h-px bg-gray-600 transform -rotate-45 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'></span>
			</button>
			<div className='flex flex-col w-full h-full items-start justify-between space-y-10'>
				<div className='flex h-8 justify-center'>
					<Text variant='h3' className='text-left'>
						{title}
					</Text>
				</div>
				<div className='flex flex-col space-y-6 w-full'>
					<Text variant='body2'>{description}</Text>
					<span className='w-full h-px bg-gray-300' />
					<div className='flex w-full justify-end space-x-2'>
						<Button
							variant='outline'
							className='w-20'
							onClick={onClose}
						>
							Cancel
						</Button>
						<Button className='w-20' onClick={onConfirm}>
							Yes
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConfirmationBox;
