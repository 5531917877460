import { cva } from 'class-variance-authority';
import React from 'react';
import { cn } from '../../utils/utils';
import AnimatedDiv from './AnimatedDiv';

const alertVariants = cva(
	'relative bg-red-100 w-full p-4 text-sm text-gray-800 traking-wider before:absolute before:left-0 before:bottom-0 before:h-full before:w-[3px]',
	{
		variants: {
			variant: {
				error: 'before:bg-red-500 bg-red-100',
				warning: 'before:bg-yellow-400 bg-yellow-100',
				success: 'before:bg-green-500 bg-green-100',
			},
		},
	}
);

const Alert = ({ className, variant, message, ...props }) => {
	return (
		<AnimatedDiv
			role='alert'
			className={cn(alertVariants({ variant, className }))}
			{...props}
		>
			<span>{message}</span>
		</AnimatedDiv>
	);
};

export { Alert, alertVariants };
