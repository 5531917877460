import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PublicRoute = ({ element }) => {
	const token = useSelector((state) => state.auth.token);

	return token ? <Navigate to='/stats/overview' replace /> : element;
};

export default PublicRoute;
