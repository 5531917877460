import { useEffect, useState, useRef } from 'react';
import { cn } from '../../utils/utils';
import { Text } from './Text';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

export interface DropdownOptions {
	label: string;
	value: string;
}

type SelectBoxProps = {
	placeholder: string;
	options: DropdownOptions[];
	selectedValue: string | null;
	onSelect: (value: string) => void;
	className?: string;
	error?: string;
};

const SelectBox = ({
	placeholder,
	options,
	selectedValue,
	onSelect,
	className,
	error,
}: SelectBoxProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const handleOptionClick = (value: string) => {
		onSelect(value);
		setIsOpen(false);
	};

	const selectedLabel =
		options.find((option) => option.value === selectedValue)?.label ||
		placeholder;

	const dropdownVariants = {
		closed: { height: 0, opacity: 0, transition: { duration: 0.25 } },
		open: { height: 'auto', opacity: 1, transition: { duration: 0.25 } },
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className={cn('relative w-full', className)} ref={dropdownRef}>
			<div
				className={cn(
					'flex items-center justify-between w-full px-4 h-10 border shadow-sm text-white rounded focus:outline-none cursor-pointer',
					error ? 'border-red-500' : 'border-gray-200'
				)}
				onClick={() => setIsOpen(!isOpen)}
			>
				<Text variant='h6'>{selectedLabel}</Text>
				<FontAwesomeIcon
					icon={faChevronDown}
					className={`text-gray-700 text-base transition-transform duration-200 ${
						isOpen ? '-rotate-90' : 'rotate-0'
					}`}
				/>
			</div>
			{error && <p className='text-red-500 text-sm mt-1'>{error}</p>}
			<motion.div
				initial='closed'
				animate={isOpen ? 'open' : 'closed'}
				variants={dropdownVariants}
				style={{ overflow: 'hidden' }}
				className='absolute w-full mt-2 bg-white border border-gray-200 rounded shadow-sm max-h-60 z-10'
			>
				<ul className='overflow-y-auto'>
					{options.map((option) => (
						<li
							key={option.value}
							className='px-4 py-2 hover:bg-primary-blue hover:text-white cursor-pointer'
							onClick={() => handleOptionClick(option.value)}
						>
							<Text color='light'>{option.label}</Text>
						</li>
					))}
				</ul>
			</motion.div>
		</div>
	);
};

export default SelectBox;
