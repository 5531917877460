import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../redux/features/auth/authActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowRightFromBracket,
	faCamera,
	faPencil,
} from '@fortawesome/free-solid-svg-icons';
import NavLinks from '../../../Components/navigation/NavLinks';
import { Text } from '../../../Components/ui/Text';
import PopupModal from '../../../Components/ui/modals/PopupModal';
import ConfirmationBox from '../../../Components/ui/ConfirmationBox';
import { useModal } from '../../../Hooks/useModal';
import {
	useGetUserDetailsQuery,
	useUpdateProfileImageMutation,
} from '../../../features/user/userApiSlice';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { processImage } from '../../../utils/imageUtils';
import FileUpload from '../../../Components/FileUpload';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ProfileMenuPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		data: userDetails,
		error,
		isLoading: isGetUserDetailsLoading,
	} = useGetUserDetailsQuery();
	const [updateProfileImage, { isLoading, isError, data }] =
		useUpdateProfileImageMutation();

	const [imagePreview, setImagePreview] = useState<string | null>(null);
	const [errorState, setErrorState] = useState<string | null>(null);

	const fileInputRef = useRef<HTMLInputElement>(null);

	const {
		isOpen: isModalOpen,
		openModal: openModal,
		closeModal: closeModal,
	} = useModal();

	const handleLogout = () => {
		dispatch(logoutUser());
		navigate('/login');
	};

	const handleProfileImageUpdated = async (image: File | null) => {
		console.log(image);
		if (!image) return;

		const { previewUrl, compressedFile, error } = await processImage(
			image,
			{
				maxWidth: 160,
			}
		);

		if (error) {
			setErrorState(error);
			toast.error(error);
		} else {
			console.log(previewUrl);
			console.log(compressedFile);

			const formData = new FormData();
			if (compressedFile) formData.append('image', compressedFile);

			try {
				const response = await updateProfileImage(formData).unwrap();
				console.log(response);
			} catch (error) {}
			setImagePreview(previewUrl);
			setErrorState(null);
		}
	};

	const profileImageSrc = imagePreview || userDetails?.profileImageUrl;

	return (
		<div className='p-4'>
			<div className='flex flex-col items-center justify-center space-y-4 py-8 bg-app-background'>
				<div className='relative w-2/5 aspect-square rounded-full bg-gray-300 flex items-center justify-center outline outline-2 outline-offset-2 outline-primary-blue'>
					{isGetUserDetailsLoading ? (
						<Skeleton
							circle={true}
							height={'100%'}
							width={'100%'}
						/>
					) : profileImageSrc ? (
						<img
							src={profileImageSrc}
							alt='Profile'
							className='flex w-full h-full rounded-full object-cover'
						/>
					) : (
						<FontAwesomeIcon
							icon={faCamera}
							className='text-4xl text-gray-500'
						/>
					)}
					<span
						className='absolute flex items-center justify-center bottom-0 translate-y-1/2 border-2 border-app-background bg-gray-500 p-3 rounded-full'
						onClick={() => {
							fileInputRef.current?.click();
						}}
					>
						<FontAwesomeIcon
							icon={faPencil}
							className='text-sm text-gray-200'
						/>
					</span>
					<FileUpload
						accept='image/*'
						onFileSelect={handleProfileImageUpdated}
						ref={fileInputRef}
					/>
				</div>
				<div className='flex flex-col items-center'>
					{isGetUserDetailsLoading ? (
						<>
							<Skeleton width={120} height={24} />
							<Skeleton width={180} height={16} />
						</>
					) : (
						<>
							<Text variant='h6'>
								{userDetails?.givenName} {userDetails?.surname}
							</Text>
							<Text variant='fine1'>{userDetails?.email}</Text>
						</>
					)}
				</div>
			</div>
			<div className='flex flex-col items-start justify-between flex-grow'>
				<NavLinks />
				<div className='flex items-center py-4' onClick={openModal}>
					<FontAwesomeIcon
						icon={faArrowRightFromBracket}
						className='text-gray-600 text-base mr-3'
					/>
					<Text>Sign out</Text>
				</div>
			</div>
			<PopupModal open={isModalOpen} onClose={closeModal}>
				<ConfirmationBox
					onClose={closeModal}
					onConfirm={handleLogout}
					title='Sign Out'
					description='Are you sure you want to sign out?'
				/>
			</PopupModal>
		</div>
	);
};

export default ProfileMenuPage;
