import { Text } from '../../../Components/ui/Text';
import Avatar from '../../../features/tournament/models/Avatar';
import { getRandomColor } from '../../../utils/avatarUtils';

type AvatarListProps = {
	avatars: Avatar[];
	totalPlayers: number;
};

const AvatarList = ({ avatars, totalPlayers }: AvatarListProps) => {
	console.log('avatars', avatars);
	return (
		<>
			{avatars?.map((avatar: Avatar, index: number) => (
				<div
					key={index}
					className='w-10 h-10 rounded-full absolute top-0 flex items-center justify-center'
					style={{
						left: `${index * 1.5}rem`,
						border: '2px solid white',
						backgroundColor: avatar.avatarImageUrl
							? 'transparent'
							: getRandomColor(),
					}}
				>
					{avatar.avatarImageUrl ? (
						<img
							src={avatar.avatarImageUrl}
							alt={avatar.initials}
							className='w-full h-full rounded-full object-cover'
						/>
					) : (
						<div>
							<Text variant='body2'>{avatar.initials}</Text>
						</div>
					)}
				</div>
			))}
			{totalPlayers > avatars.length && (
				<div
					className='w-10 h-10 bg-gray-300 rounded-full absolute top-0 flex items-center justify-center'
					style={{
						left: `${avatars.length * 1.5}rem`,
						border: '2px solid white',
					}}
				>
					<Text variant='body2' color='default'>
						+{totalPlayers - avatars.length}
					</Text>
				</div>
			)}
		</>
	);
};

export default AvatarList;
