import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../Shared/axiosBaseQuery';
import { courseApi } from '../../api/course.api';
import { Course } from '../../models/courseDetails.type';

// export const courseApiSlice = createApi({
//   reducerPath: 'courseApi',
//   baseQuery: fetchBaseQuery({
//     baseUrl: 'http://localhost:5154/api/'
//   }),
//   endpoints(builder) {
//     return {
//       fetchCourseDetails: builder.query<Course, number|void>({
//         query(id) {
//           return `course?courseId=${id}`;
//         },
//       })
//     }
//   }
// });

export const courseApiSlice = createApi({
	reducerPath: 'courseApi',
	baseQuery: axiosBaseQuery(),
	endpoints: (build) => ({
		getCourseDetails: build.query<Course, string>({
			queryFn: (courseId) => courseApi.get(courseId),
			// providesTags(result, error, courseId) {
			// 	return [{ type: 'Course', courseId }];
			// },
		}),
		courseSearch: build.query<Course[], string>({
			queryFn: (searchQ) => courseApi.autoCompleteCourseSearch(searchQ),
		}),
	}),
});

export const { useGetCourseDetailsQuery, useCourseSearchQuery } = courseApiSlice;
