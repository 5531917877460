export enum GameModeEnum {
	StrokePlay = 0,
	MatchPlay = 1,
	Stableford = 2,
	Scramble = 3,
	FourBall = 4,
	Foursomes = 5,
	BestBall = 6,
	AlternateShot = 7,
	Skins = 8,
}
