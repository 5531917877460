import { TournamentRound } from '../../../models/Tournament/TornamentRound.type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Text } from '../../../Components/ui/Text';

type RoundItemProps = {
	index: number;
	round: TournamentRound;
	onDelete: (index: number) => void;
	onEdit: () => void;
};
const RoundCard = ({ index, round, onDelete, onEdit }: RoundItemProps) => {
	return (
		<div
			className='flex flex-col w-full p-4 space-y-6 border rounded border-gray-200 shadow-sm'
			onClick={onEdit}
		>
			<div className='flex justify-between'>
				<Text variant='h6' color='dark' className='font-medium'>
					Round {round.roundNumber}
				</Text>
				<div className='flex items-center'>
					<FontAwesomeIcon
						icon={faTrash}
						className='text-gray-500 text-base'
						onClick={() => onDelete(index)}
					/>
				</div>
			</div>
			<div className='flex justify-between'>
				<div className='w-1/2 space-y-1 pr-4'>
					<Text variant='fine2' color='light'>
						Course
					</Text>
					<Text
						variant='body2'
						className='overflow-hidden whitespace-nowrap text-ellipsis'
					>
						{round.course?.name}
					</Text>
				</div>
				<span className='h-full w-px bg-gray-300' />
				<div className='w-1/2 space-y-1 pl-4'>
					<Text variant='fine2' color='light'>
						Date
					</Text>
					<Text variant='body2'>{round.date.toDateString()}</Text>
				</div>
			</div>
		</div>
	);
};

export default RoundCard;
