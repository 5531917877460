import { Input } from '../../../Components/ui/Input';
import { Button } from '../../../Components/ui/Button';
import DateInput from '../../../Components/ui/DateInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signUpUser } from '../../../redux/features/auth/authActions';

const SignUpForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const formik = useFormik({
		initialValues: {
			givenName: '',
			surname: '',
			email: '',
			dateOfBirth: null,
			password: '',
			confirmPassword: '',
		},
		validationSchema: Yup.object({
			givenName: Yup.string().required('Given name is required'),
			surname: Yup.string().required('Surname is required'),
			email: Yup.string()
				.email('Invalid Email')
				.required('Email is required'),
			dateOfBirth: Yup.date().required(
				'Please select your date of birth'
			),
			password: Yup.string().required('Please enter a password'),
			confirmPassword: Yup.string()
				.oneOf([Yup.ref('password'), null], 'Passwords do not match')
				.required('Please confirm your password'),
		}),
		onSubmit: async (values) => {
			console.log(values);
			const resultAction = await dispatch(signUpUser(values));
			if (signUpUser.fulfilled.match(resultAction)) {
				navigate('/');
			}
			// if (loginUser.rejected.match(resultAction)) {
			// 	console.log('failed mate', resultAction.payload);
			// 	if (resultAction.payload.description) {
			// 		console.log('error occurred');
			// 		setError(resultAction.payload.description);
			// 	}
			// }
		},
	});

	return (
		<div className='flex flex-col items-center justify-center w-full lg:w-1/5 space-y-12'>
			<div className='w-full flex justify-center items-center flex-col'>
				<h1 className='text-gray-700 text-3xl font-medium tracking-wider'>
					Sign up
				</h1>
			</div>
			<div className='w-full flex flex-col space-y-3'>
				<Input
					type='text'
					variant={
						formik.touched.givenName && formik.errors.givenName
							? 'error'
							: 'default'
					}
					name='givenName'
					value={formik.values.givenName}
					onChange={formik.handleChange}
					placeholder='Given name'
				/>
				<Input
					type='text'
					variant={
						formik.touched.surname && formik.errors.surname
							? 'error'
							: 'default'
					}
					name='surname'
					value={formik.values.surname}
					onChange={formik.handleChange}
					placeholder='Surname'
				/>
				<DateInput
					variant={
						formik.touched.dateOfBirth && formik.errors.dateOfBirth
							? 'error'
							: 'default'
					}
					placeholder='Date of Birth'
					selected={formik.values.dateOfBirth}
					onChange={(date) =>
						formik.setFieldValue('dateOfBirth', date)
					}
				/>
				<Input
					type='email'
					variant={
						formik.touched.email && formik.errors.email
							? 'error'
							: 'default'
					}
					name='email'
					value={formik.values.email}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					placeholder='Email'
				/>
				<Input
					type='password'
					variant={
						formik.touched.password && formik.errors.password
							? 'error'
							: 'default'
					}
					name='password'
					value={formik.values.password}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					placeholder='Password'
				/>
				<Input
					type='password'
					variant={
						formik.touched.confirmPassword &&
						formik.errors.confirmPassword
							? 'error'
							: 'default'
					}
					name='confirmPassword'
					value={formik.values.confirmPassword}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					placeholder='Confirm password'
				/>
				<div className='flex flex-col justify-end space-y-2'>
					<Button onClick={formik.handleSubmit} className='w-full'>
						Continue
					</Button>
					<footer className='flex justify-center'>
						<p className='text-gray-700 text-sm tracking-wider'>
							Already have an account?{' '}
							<span
								className='underline cursor-pointer font-medium text-gray-800'
								onClick={() => {
									navigate('/login');
								}}
							>
								Sign in
							</span>
						</p>
					</footer>
				</div>
			</div>
		</div>
	);
};

export default SignUpForm;
