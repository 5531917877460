import {
	faPersonRunning,
	faRankingStar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Text } from '../../Components/ui/Text';
import { getRandomColor } from '../../utils/avatarUtils';
import {
	useGetRoundByIdQuery,
	useGetRoundsForGroupQuery,
	useUpdateScoreForHoleMutation,
} from '../../features/round/roundApiSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetNewScorecardQuery } from '../../features/scorecard/scorecardApiSlice';
import ScoreInput from './Components/ScoreInput';
import { useEffect, useState } from 'react';
import HoleInfo from './Components/HoleInfo';
import PopupModal from '../../Components/ui/modals/PopupModal';
import ConfirmationBox from '../../Components/ui/ConfirmationBox';
import { useModal } from '../../Hooks/useModal';
import PlayerHoleInfo from './Components/PlayerHoleInfo';

type ScoreEntry = {
	playerId: string;
	score: number | null;
};

type ScoresByHole = {
	[holeIndex: number]: ScoreEntry[];
};

type OverallScoresByPlayer = {
	[playerId: string]: number;
};

type OverallGrossScoresByPlayer = {
	[playerId: string]: number;
};

type PlayerResult = {
	userId: string;
	givenName: string;
	surname: string;
	profileImageUrl?: string | null;
	grossScore: number;
	scoreToPar: number;
	initials: string;
};

const RoundEntryPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { roundId, holeIndex } = useParams<{
		roundId: string;
		holeIndex?: string;
	}>();

	const [lastSavedScoresByHole, setLastSavedScoresByHole] =
		useState<ScoresByHole>({});
	const initialHoleIndex = holeIndex ? parseInt(holeIndex, 10) - 1 : 0;

	const [groupId, setGroupId] = useState<string | undefined>(
		location.state?.groupId
	);

	const {
		isOpen: isModalOpen,
		openModal: openModal,
		closeModal: closeModal,
	} = useModal();

	const {
		data: roundData,
		isLoading,
		isError,
	} = useGetRoundByIdQuery(roundId!);

	const {
		data: scorecardData,
		isLoading: isScorecardLoading,
		isError: isScorecardError,
	} = useGetNewScorecardQuery(roundId ?? skipToken);

	const {
		data: playerScoresData,
		isLoading: isPlayerScoresLoading,
		isError: isPlayerScoresError,
	} = useGetRoundsForGroupQuery(groupId || skipToken);

	const [currentHoleIndex, setCurrentHoleIndex] = useState(initialHoleIndex);
	const [scoresByHole, setScoresByHole] = useState<ScoresByHole>({});
	const [updateScoreForHole] = useUpdateScoreForHoleMutation();
	const [overallScoresByPlayer, setOverallScoresByPlayer] =
		useState<OverallScoresByPlayer>({});
	const [overallGrossScoresByPlayer, setOverallGrossScoresByPlayer] =
		useState<OverallGrossScoresByPlayer>({});

	useEffect(() => {
		if (!groupId && roundData?.groupId != null) {
			setGroupId(roundData.groupId);
		}
	}, [roundData, groupId]);

	useEffect(() => {
		console.log(playerScoresData);
		console.log(scorecardData);
		if (playerScoresData && scorecardData) {
			const initialScoresByHole: ScoresByHole = {};

			const holeIdToIndexMap: { [holeId: string]: number } = {};
			scorecardData.scores.forEach((hole, index) => {
				if (hole.holeId) {
					holeIdToIndexMap[hole.holeId] = index;
				}
			});

			// For each player's scores
			playerScoresData.forEach((playerScores) => {
				const playerId = playerScores.userId;
				const scores = playerScores.scores;

				// For each holeId and score
				Object.entries(scores).forEach(([holeId, score]) => {
					const holeIndex = holeIdToIndexMap[holeId];
					if (holeIndex !== undefined) {
						// Initialize the hole in scoresByHole if it doesn't exist
						if (!initialScoresByHole[holeIndex]) {
							initialScoresByHole[holeIndex] = [];
						}

						initialScoresByHole[holeIndex].push({
							playerId,
							score,
						});
					}
				});
			});

			// Update the scoresByHole state
			console.log('initial scores', initialScoresByHole);
			setScoresByHole(initialScoresByHole);
			setLastSavedScoresByHole(initialScoresByHole);
		}
	}, [playerScoresData, scorecardData]);

	useEffect(() => {
		if (!roundData || !scorecardData) return;

		const newOverallScoresByPlayer: OverallScoresByPlayer = {};
		const newOverallGrossScoresByPlayer: OverallGrossScoresByPlayer = {};

		roundData.players.forEach((player) => {
			const playerId = player.userId;
			let totalScore = 0;
			let totalPar = 0;

			// Loop through all holes
			for (let i = 0; i < scorecardData.scores.length; i++) {
				const holePar = scorecardData.scores[i]?.par || 0;

				// Get the player's score for this hole from scoresByHole
				const holeScores = scoresByHole[i] || [];
				const playerScoreEntry = holeScores.find(
					(entry) => entry.playerId === playerId
				);

				if (playerScoreEntry && playerScoreEntry.score != null) {
					totalScore += playerScoreEntry.score;
					totalPar += holePar;
				} else {
					// If no score entered yet, you can decide how to handle it
					// Option 1: Assume par for the hole
					// Option 2: Exclude the hole from totalPar
					// For this example, we'll only include holes with entered scores
					continue;
				}
			}

			// Calculate overall score relative to par
			newOverallScoresByPlayer[playerId] = totalScore - totalPar;
			newOverallGrossScoresByPlayer[playerId] = totalScore;
		});

		setOverallScoresByPlayer(newOverallScoresByPlayer);
		setOverallGrossScoresByPlayer(newOverallGrossScoresByPlayer);
	}, [scoresByHole, roundData, scorecardData]);

	const handleScoreChange = (playerId: string, newScore: number | null) => {
		setScoresByHole((prevScoresByHole) => {
			const holeScores = prevScoresByHole[currentHoleIndex] || [];

			// Remove existing score for this player
			const updatedHoleScores = holeScores.filter(
				(scoreEntry) => scoreEntry.playerId !== playerId
			);

			// Add new score if it's not null
			if (newScore !== null) {
				updatedHoleScores.push({ playerId, score: newScore });
			}

			return {
				...prevScoresByHole,
				[currentHoleIndex]: updatedHoleScores,
			};
		});
	};

	const handleChangeHole = async (direction: 'next' | 'prev') => {
		try {
			await updateScores();
			let newHoleIndex = currentHoleIndex;
			if (
				direction === 'next' &&
				currentHoleIndex < (scorecardData?.scores.length - 1 ?? 0)
			) {
				newHoleIndex = currentHoleIndex + 1;
			} else if (direction === 'prev' && currentHoleIndex > 0) {
				newHoleIndex = currentHoleIndex - 1;
			}

			setCurrentHoleIndex(newHoleIndex);

			// Update the URL to reflect the new holeIndex
			window.history.replaceState(
				{ ...location.state, groupId }, // Retain state with groupId
				'',
				`/round-entry/${roundId}/${newHoleIndex + 1}`
			);
		} catch (error) {
			console.error('Failed to update scores', error);
		}
	};

	const handleSaveRound = async () => {
		await updateScores();

		const playerResults: PlayerResult[] =
			roundData?.players.map((player) => {
				const playerId = player.userId;
				return {
					userId: playerId,
					givenName: player.givenName,
					surname: player.surname,
					initials: player.initials,
					profileImageUrl: player.profileImageUrl ?? undefined, // Normalize null to undefined
					grossScore: overallGrossScoresByPlayer[playerId] || 0,
					scoreToPar: overallScoresByPlayer[playerId] || 0,
				};
			}) || [];

		navigate(`/round-entry/${roundId}/review`, {
			state: {
				results: playerResults,
			},
		});
	};

	const updateScores = async () => {
		const tournamentGroupId = roundData?.groupId;
		const holeId = scorecardData?.scores[currentHoleIndex]?.holeId;
		const holeScores = scoresByHole[currentHoleIndex] || [];

		if (!tournamentGroupId || !holeId) {
			console.error('Missing tournamentGroupId or holeId');
			return;
		}

		const savedHoleScores = lastSavedScoresByHole[currentHoleIndex] || [];
		const hasChanges =
			JSON.stringify(savedHoleScores) !== JSON.stringify(holeScores);

		if (!hasChanges) {
			console.log('No changes detected, skipping update.');
			return;
		}

		const updateScoreRequest = {
			tournamentGroupId,
			PlayerScores: holeScores.map(({ playerId, score }) => ({
				userId: playerId,
				holeId,
				score: score ?? 0,
			})),
		};

		try {
			await updateScoreForHole(updateScoreRequest).unwrap();
			setLastSavedScoresByHole((prev) => ({
				...prev,
				[currentHoleIndex]: [...holeScores],
			}));
			setScoresByHole((prev) => ({
				...prev,
				[currentHoleIndex]: [...holeScores],
			}));
		} catch (error) {
			console.error('Failed to update scores', error);
		}
	};

	const handleViewLeaderboard = async () => {
		await updateScores();
		navigate(`/tournament/${roundData?.tournamentId}/leaderboard`);
	};

	if (isLoading || isScorecardLoading) {
		return <div>Loading...</div>;
	}

	if (isError || isScorecardError) {
		return <div>Error loading data.</div>;
	}

	const currentHole = scorecardData?.scores[currentHoleIndex];

	return (
		<div className='min-h-full'>
			<div className='pb-16 px-4 pt-4 flex flex-col'>
				<div className='flex flex-col'>
					<Text variant='h4'>Bogey Men Taupo 2024</Text>
					<Text variant='h6'>Round 1 - Fri 15 Nov 2024</Text>
				</div>
				<div className='space-y-4 flex flex-col items-end mt-8'>
					<div className='w-12 flex flex-col items-center'>
						<div className='w-12 aspect-square border flex items-center justify-center rounded-md bg-gray-400'>
							<FontAwesomeIcon
								icon={faPersonRunning}
								className='text-2xl text-gray-100'
								onClick={openModal}
							/>
							<PopupModal open={isModalOpen} onClose={closeModal}>
								<ConfirmationBox
									onClose={closeModal}
									onConfirm={() => navigate(-1)}
									title='Exit Round'
									description='Are you sure you want to Exit. Your changes will be saved?'
								/>
							</PopupModal>
						</div>
					</div>
					<div className='flex flex-col items-center'>
						<div className='w-12 aspect-square border flex items-center justify-center rounded-md bg-gray-400'>
							<FontAwesomeIcon
								icon={faRankingStar}
								className='text-2xl text-gray-100'
								onClick={handleViewLeaderboard}
							/>
						</div>
					</div>
				</div>
				<div className='flex flex-col space-y-4 mt-8'>
					{roundData?.players.map((player, index) => {
						const holeScores = scoresByHole[currentHoleIndex] || [];
						console.log(holeScores);
						const playerScoreEntry = holeScores.find(
							(scoreEntry) =>
								scoreEntry.playerId === player.userId
						);
						const playerScore = playerScoreEntry
							? playerScoreEntry.score
							: null;
						const overallScoreToPar =
							overallScoresByPlayer[player.userId] || 0;
						return (
							<div className='flex justify-between'>
								<PlayerHoleInfo
									profileImageUrl={player.profileImageUrl}
									playerInitials={player.initials}
									givenName={player.givenName}
									surname={player.surname}
									holePar={currentHole?.par ?? 4}
									playerScore={playerScore}
									overallScoreToPar={overallScoreToPar}
								/>
								<ScoreInput
									par={currentHole?.par ?? 4}
									onScoreChange={(newScore) =>
										handleScoreChange(
											player.userId,
											newScore
										)
									}
									value={playerScore}
								/>
							</div>
						);
					})}
				</div>
			</div>
			<HoleInfo
				currentHoleIndex={currentHoleIndex}
				holeNumber={currentHole!.holeNumber}
				holePar={currentHole!.par}
				holeLength={currentHole!.length}
				onHoleChange={handleChangeHole}
				onRoundComplete={handleSaveRound}
			/>
		</div>
	);
};

export default RoundEntryPage;
