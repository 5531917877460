import React from 'react';
import logo from '../../../assets/logo-white.svg';
import LoginForm from './LoginForm';
import AnimatedDiv from '../../../Components/ui/AnimatedDiv';

const Login = () => {
	return (
		<div className='flex flex-col justify-between w-full h-screen'>
			<div className='flex'>
				<div className='bg-primary-blue-darker w-full p-4 lg:p-7'>
					<AnimatedDiv className='flex items-center'>
						<img
							src={logo}
							alt='Logo'
							className='h-5 w-5 lg:h-10 lg:w-10 mr-2'
						/>
						<h1 className='text-2xl text-white tracking-widest font-inter font-bold uppercase inter'>
							Scramble
						</h1>
					</AnimatedDiv>
				</div>
			</div>
			<AnimatedDiv className='flex h-3/4 p-4 lg:p-16 w-full justify-center items-start'>
				<LoginForm />
			</AnimatedDiv>
			<p className='absolute bottom-4 right-4 text-gray-700 text-xs tracking-wider'>
				© Scramble.nz 2024
			</p>
		</div>
	);
};

export default Login;
