import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../../../Components/ui/Text';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { useGetUpcomingRoundsQuery } from '../../../features/round/roundApiSlice';
import RoundSummary from '../../../features/round/types/RoundSummary';
import { useNavigate, useParams } from 'react-router-dom';
import RoundList from './components/RoundList';
import { RoundStatusEnum } from '../../../features/round/enums/roundStatusEnum';

const MyRoundsPage = () => {
	const navigate = useNavigate();
	const { username } = useParams<{ username: string }>();
	const { data: rounds } = useGetUpcomingRoundsQuery();

	console.log(rounds);

	const handleViewRound = (roundId: string) => {
		navigate(`/${username}/rounds/${roundId}`);
	};

	const createdRounds =
		rounds?.filter(
			(round: RoundSummary) =>
				round.roundStatus === RoundStatusEnum.Created
		) || [];

		const inProgressRounds =
		rounds?.filter(
			(round: RoundSummary) =>
				round.roundStatus === RoundStatusEnum.InProgress
		) || [];

	return (
		<div className='p-4'>
			<div className='flex justify-between mb-4'>
				<Text variant='h2'>My Rounds</Text>
				<span className='flex items-center justify-center w-10 h-10 rounded-full bg-muted-gray'>
					<FontAwesomeIcon
						icon={faBell}
						className='h-4 w-4 text-gray-700'
					/>
				</span>
			</div>
			<div className='mt-4'>
				<Text variant='body2' className='font-semibold'>
					In Progress ({rounds?.length})
				</Text>
				<RoundList
					rounds={inProgressRounds}
					onRoundClick={handleViewRound}
				/>
			</div>
			<div className='mt-4'>
				<Text variant='body2' className='font-semibold'>
					Upcoming ({rounds?.length})
				</Text>
				<RoundList
					rounds={createdRounds}
					onRoundClick={handleViewRound}
				/>
			</div>
		</div>
	);
};

export default MyRoundsPage;
