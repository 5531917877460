/* eslint-disable react-refresh/only-export-components */
import React from 'react';
import { getAroundTheGreenStats } from '../../Services/statsService';
import StatDisplay from '../ui/displays/StatDisplay';
import withStats from './withStats';

function AroundGreenStats({ stats }) {
	return (
		<div className='flex w-full flex-row space-x-6'>
			<div className='w-1/3'>
				<StatDisplay
					value={`${stats.scrambling}%`}
					label='Scrambling'
				/>
			</div>
			<div className='w-1/3'>
				<StatDisplay
					value={`${stats.upAndDown}%`}
					label='Up and Down'
				/>
			</div>
			<div className='w-1/3'>
				<StatDisplay
					value={
						stats.sandSave !== null ? `${stats.sandSave}%` : 'N/A'
					}
					label='Sand Save'
				/>
			</div>
		</div>
	);
}

export default withStats(AroundGreenStats, getAroundTheGreenStats);
