import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import NavLinks from './NavLinks';

const Navbar = () => {
	const navigate = useNavigate();

	return (
		<header
			className=' 
			hidden
			lg:block
			w-52
			h-full
			fixed z-50
			border-r space-y-6 bg-white'
		>
			<div
				className='px-4 h-14 flex items-center cursor-pointer'
				onClick={() => navigate('/')}
			>
				<img src={logo} alt='Logo' className='h-8 w-8 mr-2' />
				<h1 className='text-lg text-gray-700 tracking-widest font-inter font-bold uppercase inter'>
					Scramble
				</h1>
			</div>
			<NavLinks />
		</header>
	);
};

export default Navbar;
