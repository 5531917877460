import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddPlayerButton from '../components/AddPlayerButton';
import { faChevronLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useModal } from '../../../../Hooks/useModal';
import Modal from '../../../../Components/ui/Modal';
import PlayersModal from '../components/PlayersModal';
import {
	useCreateGroupMutation,
	useDeleteGroupMutation,
	useGetPlayersQuery,
	useUpdateGroupMutation,
} from '../../../../features/tournament/tournamentApiSlice';
import { useEffect, useState } from 'react';
import NewGroupRequest from '../../../../features/tournament/models/NewGroupRequest';
import { Button } from '../../../../Components/ui/Button';
import Player from '../../../../Shared/types/player.type';
import { toast } from 'react-toastify';
import Group from '../../../../features/tournament/models/Group';
import PopupModal from '../../../../Components/ui/modals/PopupModal';
import ConfirmationBox from '../../../../Components/ui/ConfirmationBox';
import { handleApiError } from '../../../../utils/apiErrorHandler';

const EditPairingsPage = () => {
	const { tournamentId } = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const { roundId, groupedUserIds, group } = location.state as {
		roundId: string;
		groupedUserIds: string[];
		group: Group;
	};

	console.log(group);

	const [createGroup, { isLoading: isCreatingGroup }] =
		useCreateGroupMutation();

	const [updateGroup, { isLoading: isUpdatingGroup }] =
		useUpdateGroupMutation();

	const [deleteGroup, { isLoading: isDeletingGroup }] =
		useDeleteGroupMutation();

	const { data: playerData, isLoading } = useGetPlayersQuery(tournamentId!);

	const {
		isOpen: isModalOpen,
		openModal: openModal,
		closeModal: closeModal,
	} = useModal();

	const {
		isOpen: isConfirmationModalOpen,
		openModal: openConfirmationModal,
		closeModal: closeConfirmationModal,
	} = useModal();

	const [editingIndex, setEditingIndex] = useState<number | null>(null);

	const [availablePlayers, setAvailablePlayers] = useState<Player[]>(
		playerData || []
	);

	const [newGroup, setNewGroup] = useState<NewGroupRequest>({
		tournamentRoundId: '',
		teeTime: null,
		startingTee: null,
		tournamentEntryIds: ['', '', '', ''],
	});

	useEffect(() => {
		if (playerData) {
			const filteredPlayers = playerData.filter(
				(player) => !groupedUserIds.includes(player.userId)
			);
			setAvailablePlayers(filteredPlayers);
		}
	}, [playerData, groupedUserIds]);

	useEffect(() => {
		if (roundId) {
			if (group) {
				console.log('called');
				let playerIds = group?.players.map((player) => player.userId);
				while (playerIds.length < 4) {
					playerIds.push('');
				}

				setNewGroup({
					tournamentGroupId: group.tournamentGroupId,
					tournamentRoundId: roundId,
					teeTime: group.teeTime || null,
					startingTee: group.startingHole || null,
					tournamentEntryIds: playerIds,
				});
			} else {
				console.log('else called');
				setNewGroup((prev) => ({
					...prev,
					tournamentRoundId: roundId,
				}));
			}
		}
	}, [roundId, group]);

	const handleBackClick = () => {
		navigate(`/tournament/${tournamentId}/pairings`);
	};

	const handleAddPlayer = (index: number) => {
		setEditingIndex(index);
		openModal();
	};

	const handlePlayerSelect = (playerId: string) => {
		if (editingIndex !== null) {
			const updatedGroup = { ...newGroup };

			const currentPlayerId =
				updatedGroup.tournamentEntryIds[editingIndex];
			if (currentPlayerId) {
				const currentPlayer = playerData?.find(
					(player) => player.userId === currentPlayerId
				);
				if (currentPlayer) {
					setAvailablePlayers((prev) => [...prev, currentPlayer]);
				}
			}

			updatedGroup.tournamentEntryIds[editingIndex] = playerId;
			setNewGroup(updatedGroup);

			setAvailablePlayers((prev) =>
				prev.filter((p) => p.userId !== playerId)
			);

			setEditingIndex(null);
			closeModal();
		}
	};

	const handleRemovePlayer = (playerId: string) => {
		const currentPlayer = playerData?.find(
			(player) => player.userId === playerId
		);

		if (currentPlayer) {
			setAvailablePlayers((prev) => [...prev, currentPlayer]);
			const updatedGroup = { ...newGroup };
			const index = updatedGroup.tournamentEntryIds.indexOf(playerId);
			updatedGroup.tournamentEntryIds[index] = '';
			setNewGroup(updatedGroup);
		}
	};

	const handleSaveGroup = async () => {
		const selectedPlayers = newGroup.tournamentEntryIds.filter(
			(playerId) => playerId !== ''
		);

		if (selectedPlayers.length < 2) {
			toast.error('A group needs at least 2 players');
		}

		const groupToSave = {
			...newGroup,
			tournamentEntryIds: selectedPlayers,
		};

		try {
			await createGroup(groupToSave).unwrap();
			toast.success('Group created');
			navigate(`/tournament/${tournamentId}/pairings`);
		} catch (error) {
			toast.error('Failed to create group');
		}
	};

	const handleUpdateGroup = async () => {
		const selectedPlayers = newGroup.tournamentEntryIds.filter(
			(playerId) => playerId !== ''
		);

		if (selectedPlayers.length < 2) {
			toast.error('A group needs at least 2 players');
			return;
		}

		const groupToUpdate = {
			...newGroup,
			tournamentEntryIds: selectedPlayers,
		};

		try {
			await updateGroup(groupToUpdate).unwrap();
			toast.success('Group updated');
			navigate(`/tournament/${tournamentId}/pairings`);
		} catch (err) {
			handleApiError(err);
		}
	};

	const handleDeleteGroup = async () => {
		try {
			await deleteGroup(group.tournamentGroupId).unwrap();
			toast.success('Group deleted');
			navigate(`/tournament/${tournamentId}/pairings`);
		} catch (error) {
			handleApiError(error);
		}
		closeConfirmationModal();
	};

	return (
		<div className='bg-muted-60 min-h-full'>
			<div className='w-full pt-20 pb-4 px-4'>
				<div className='fixed w-full top-0 left-0 z-10 h-16 flex justify-between items-center border-b p-4 shadow-sm bg-white'>
					<FontAwesomeIcon
						icon={faChevronLeft}
						className='text-lg text-gray-600'
						onClick={handleBackClick}
					/>
					<FontAwesomeIcon
						icon={faTrash}
						className='text-lg text-gray-600'
						onClick={openConfirmationModal}
					/>
				</div>
				<div className='space-y-4'>
					{newGroup.tournamentEntryIds.map((playerId, index) => {
						const player = playerData?.find(
							(p) => p.userId === playerId
						);
						return (
							<AddPlayerButton
								key={index}
								player={player}
								onClick={() => handleAddPlayer(index)}
								onRemovePlayer={handleRemovePlayer}
							/>
						);
					})}
				</div>
			</div>
			<Modal
				open={isModalOpen}
				onClose={closeModal}
				//onExitComplete={() => setEditingRoundIndex(null)}
				animationType='slideUp'
			>
				<PlayersModal
					players={availablePlayers}
					onPlayerSelect={handlePlayerSelect}
				/>
			</Modal>
			<PopupModal
				open={isConfirmationModalOpen}
				onClose={closeConfirmationModal}
			>
				<ConfirmationBox
					onClose={closeConfirmationModal}
					onConfirm={handleDeleteGroup}
					title='Delete group'
					description='Are you sure you want to delete this Group?'
				/>
			</PopupModal>
			<div className='fixed w-full bottom-0 right-0 p-4'>
				<Button
					className='w-full'
					onClick={group ? handleUpdateGroup : handleSaveGroup}
					loading={isCreatingGroup || isUpdatingGroup}
				>
					Save changes
				</Button>
			</div>
		</div>
	);
};

export default EditPairingsPage;
