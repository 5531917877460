import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Input } from '../../../../Components/ui/Input';
import DateInput from '../../../../Components/ui/DateInput';
import { TextArea } from '../../../../Components/ui/TextArea';

type RegistrationFormProps = {
	userDetails?: {
		givenName: string;
		surname: string;
		email: string;
		dateOfBirth: Date;
	};
	handleSubmit: (values: {
		givenName: string;
		surname: string;
		email: string;
		phone: string;
		dateOfBirth: Date;
		comments: string;
	}) => void;
	isSubmitting: boolean;
};

const RegistrationForm = ({
	userDetails,
	handleSubmit,
	isSubmitting,
}: RegistrationFormProps) => {
	const formik = useFormik({
		initialValues: {
			givenName: userDetails?.givenName || '',
			surname: userDetails?.surname || '',
			email: userDetails?.email || '',
			phone: '',
			dateOfBirth: userDetails?.dateOfBirth || new Date(),
			comments: '',
		},
		validationSchema: Yup.object({
			givenName: Yup.string().required('Given name is required'),
			surname: Yup.string().required('Surname is required'),
			email: Yup.string()
				.email('Invalid Email')
				.required('Email is required'),
			dateOfBirth: Yup.date().required(
				'Please select your date of birth'
			),
			phone: Yup.string().required('Phone number is required'),
			comments: Yup.string(),
		}),
		onSubmit: (values) => {
			handleSubmit(values);
		},
	});

	return (
		<form
			onSubmit={formik.handleSubmit}
			className='py-[5.5rem] px-4 pb-4 space-y-3'
			id='register-form'
		>
			<Input
				type='text'
				label='First Name'
				name='givenName'
				value={formik.values.givenName}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				variant={
					formik.touched.givenName && formik.errors.givenName
						? 'error'
						: 'default'
				}
				error={
					formik.touched.givenName && formik.errors.givenName
						? formik.errors.givenName
						: ''
				}
			/>
			<Input
				type='text'
				label='Surname'
				name='surname'
				value={formik.values.surname}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				variant={
					formik.touched.surname && formik.errors.surname
						? 'error'
						: 'default'
				}
				error={
					formik.touched.surname && formik.errors.surname
						? formik.errors.surname
						: ''
				}
			/>
			<Input
				type='email'
				label='Email'
				name='email'
				value={formik.values.email}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				variant={
					formik.touched.email && formik.errors.email
						? 'error'
						: 'default'
				}
				error={
					formik.touched.email && formik.errors.email
						? formik.errors.email
						: ''
				}
			/>
			<Input
				type='text'
				label='Ph. (Mobile)'
				name='phone'
				value={formik.values.phone}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				variant={
					formik.touched.phone && formik.errors.phone
						? 'error'
						: 'default'
				}
				error={
					formik.touched.phone && formik.errors.phone
						? formik.errors.phone
						: ''
				}
			/>
			<DateInput
				label='Date of Birth'
				variant={
					formik.touched.dateOfBirth && formik.errors.dateOfBirth
						? 'error'
						: 'default'
				}
				placeholder='Date of Birth'
				selected={formik.values.dateOfBirth}
				onChange={(date) => formik.setFieldValue('dateOfBirth', date)}
			/>
			<TextArea
				label='Comments'
				name='comments'
				value={formik.values.comments}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				variant={
					formik.touched.comments && formik.errors.comments
						? 'error'
						: 'default'
				}
				rows={5}
			/>
		</form>
	);
};

export default RegistrationForm;
