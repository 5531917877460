import Player from '../../../../Shared/types/player.type';
import UserAvatar from '../../../../Components/ui/UserAvatar';
import { useState } from 'react';
import { Button } from '../../../../Components/ui/Button';

type PlayersProps = {
	players?: Player[];
	onPlayerSelect: (playerId: string) => void;
};

const PlayersModal = ({ players, onPlayerSelect }: PlayersProps) => {
	const [searchTerm, setSearchTerm] = useState<string>('');

	const handleSearchSubmit = (
		event: React.KeyboardEvent<HTMLInputElement>
	) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			(event.target as HTMLInputElement).blur();
		}
	};

	const filteredPlayers = players?.filter((player) =>
		player.normalisedName.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<div className='w-full h-screen flex flex-col overflow-y-scroll'>
			<div className='flex items-center px-4 border w-full h-10 rounded-3xl mb-4'>
				<input
					type='search'
					placeholder='Find player'
					className='w-full text-gray-600 tracking-wider outline-none focus:outline-none border-none focus:border-none h-8 placeholder:tracking-widest'
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					onKeyDown={handleSearchSubmit}
				/>
			</div>
			<div className='w-full flex-grow flex flex-col overflow-y-auto items-start space-y-4'>
				{filteredPlayers?.map((player) => (
					<UserAvatar
						key={player.userId}
						url={player.profileImageUrl}
						givenName={player.givenName}
						surname={player.surname}
						initials={player.initials}
						playerId={player.userId}
						onClick={onPlayerSelect}
					/>
				))}
			</div>
		</div>
	);
};

export default PlayersModal;
