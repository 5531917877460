/* eslint-disable react-refresh/only-export-components */
import React from 'react';
import { useState, useEffect } from 'react';
import { getScoringStats } from '../../Services/statsService';
import { getAllRounds } from '../../Services/roundService';
import StatDisplay from '../ui/displays/StatDisplay';
import { formatRoundToPar } from '../../utils/utils';
import BarChart from '../ui/charts/BarChart';
import LineChart from '../ui/charts/LineChart';
import withStats from './withStats';

const ScoringStats = ({ stats }) => {
	const [rounds, setRounds] = useState([]);

	useEffect(() => {
		getAllRounds().then((res) => {
			setRounds(res);
		});
	}, []);

	if (rounds.length === 0) {
		return <div>Loading...</div>;
	}
	return (
		<div className='flex w-full flex-col space-y-6'>
			<div className='w-full flex flex-row space-x-6'>
				<div className='w-1/3'>
					<StatDisplay
						value={stats.roundsPlayed}
						label='Rounds Played'
					/>
				</div>
				<div className='w-1/3'>
					<StatDisplay
						value={formatRoundToPar(stats.lowestRound)}
						label='Lowest Round'
					/>
				</div>
				<div className='w-1/3'>
					<StatDisplay
						value={`${stats.breakingParPercentage}%`}
						label='Breaking Par'
					/>
				</div>
			</div>
			<div className='w-full flex flex-row space-x-6'>
				<div className='w-1/2'>
					<div className='w-full space-y-4 p-8 border border-gray-300 rounded'>
						<h3 className='text-xl text-gray-600 font-medium tracking-wider'>
							Scoring Average
						</h3>
						<BarChart
							parThreeScoringAverage={
								stats.parThreeScoringAverage
							}
							parFourScoringAverage={stats.parFourScoringAverage}
							parFiveScoringAverage={stats.parFiveScoringAverage}
						/>
					</div>
				</div>
				<div className='w-1/2'>
					<div className='w-full h-full space-y-4 border border-gray-300 rounded p-8'>
						<h3 className='text-xl text-gray-600 font-medium tracking-wider'>
							Scores
						</h3>
						<LineChart rounds={rounds} />
					</div>
				</div>
			</div>
			<div className='w-full flex flex-row space-x-6'>
				<div className='w-1/3'>
					<StatDisplay
						value={`${stats.birdieAverage}`}
						label='Birdie Average'
					/>
				</div>
				<div className='w-1/3'>
					<StatDisplay
						value={`${stats.bogeyAvoidance}%`}
						label='Bogey Avoidance'
					/>
				</div>
				<div className='w-1/3'>
					<StatDisplay
						value={`${stats.bounceBack}%`}
						label='Bounce Back'
					/>
				</div>
			</div>
			<div className='w-full flex flex-row space-x-6'>
				<div className='w-1/2cd'>
					<StatDisplay
						value={`${stats.dblBogeyOrWorseAverage}`}
						label='Dbl Bogeys Per Round'
					/>
				</div>
				<div className='w-1/2'>
					<StatDisplay
						value={`${stats.dblBogeyFreeScoreStreak}`}
						label='Dbl Bogey free Round Streak'
					/>
				</div>
			</div>
		</div>
	);
};

export default withStats(ScoringStats, getScoringStats);
