import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../Shared/axiosBaseQuery';
import AutoCompleteUserSearchResponse from './models/AutoCompleteUserSearchResponse';
import FriendRequest from './models/FriendRequest';
import { GET, POST } from '../shared/httpMethods';
import RespondToFriendRequest from './models/RespondToFriendRequest';
import Friend from './models/Friend';

export const friendApiSlice = createApi({
	reducerPath: 'friendApi',
	baseQuery: axiosBaseQuery(),
	tagTypes: ['FriendRequests'],
	endpoints: (build) => ({
		autoCompleteUserSearch: build.query<
			AutoCompleteUserSearchResponse[],
			string
		>({
			query: (searchTerm: string) => ({
				url: `friends/autocompleteusersearch?searchTerm=${searchTerm}`,
				method: GET,
			}),
		}),
		getFriendsList: build.query<Friend[], void>({
			query: () => ({
				url: 'friends',
				method: GET,
			}),
		}),
		getFriendRequests: build.query<FriendRequest[], void>({
			query: () => ({
				url: 'friends/requests',
				method: GET,
			}),
			providesTags: (result) =>
				result
					? [
							...result.map(({ friendRequestId }) => ({
								type: 'FriendRequests' as const,
								id: friendRequestId,
							})),
							{ type: 'FriendRequests', id: 'LIST' },
					  ]
					: [{ type: 'FriendRequests', id: 'LIST' }],
		}),
		getSentFriendRequests: build.query<FriendRequest[], void>({
			query: () => ({
				url: 'friends/getsentfriendrequests',
				method: GET,
			}),
		}),
		sendFriendRequest: build.mutation<void, string>({
			query: (toUserId: string) => ({
				url: `friends/sendrequest?toUserId=${toUserId}`,
				method: POST,
			}),
		}),
		respondToFriendRequest: build.mutation<void, RespondToFriendRequest>({
			query: (response: RespondToFriendRequest) => ({
				url: `friends/request?friendRequestId=${response.friendRequestId}&accepted=${response.accepted}`,
				method: POST,
			}),
			invalidatesTags: (result, error, { friendRequestId }) => [
				{ type: 'FriendRequests', id: friendRequestId },
				{ type: 'FriendRequests', id: 'LIST' },
			],
		}),
	}),
});

export const {
	useAutoCompleteUserSearchQuery,
	useGetFriendsListQuery,
	useGetFriendRequestsQuery,
	useGetSentFriendRequestsQuery,
	useSendFriendRequestMutation,
	useRespondToFriendRequestMutation,
} = friendApiSlice;
