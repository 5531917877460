import React from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { IoIosClose } from 'react-icons/io';

const ConfirmationModal = ({ isOpen, title, message, onClose, onConfirm }) => {
	if (!isOpen) return null;

	return (
		<div className='fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center'>
			<div className='bg-white w-[500px] p-8 rounded shadow-lg'>
				<div className='flex flex-row items-start justify-between'>
					<h3 className='text-2xl text-gray-700 font-medium tracking-wider mb-8'>
						{title}
					</h3>

					<span
						className='rounded-full border-2 border-gray-400 hover:bg-gray-100'
						onClick={onClose}
					>
						<IoIosClose
							style={{
								cursor: 'pointer',
							}}
							className='text-gray-500 hover:text-gray-700'
							size={35}
						/>
					</span>
				</div>
				<p className='text-gray-600 tracking-wider'>{message}</p>
				<span className='w-full block h-px bg-gray-300 mt-8 mb-6'></span>
				<div className='flex justify-end space-x-2 mt-4'>
					<button
						className='
            rounded 
            tracking-wider
						bg-white
            border
            border-gray-400
            hover:bg-gray-100 
            hover:text-gray-700
						w-32
						py-2.5 
						ease-in
						duration-100 
						text-center 
						text-base 
						font-normal 
						text-gray-500 
						shadow-sm 
						focus-visible:outline 
						focus-visible:outline-2 
						focus-visible:outline-offset-2 '
						onClick={onClose}
					>
						<span className='block tracking-wider'>Cancel</span>
					</button>
					<button
						className='rounded 
            tracking-wider
						bg-primary-blue
            hover:bg-primary-blue-darker
						w-32
						py-2.5 
						ease-in
						duration-100 
						text-center 
						text-base 
						font-normal 
						text-white 
						shadow-sm 
						focus-visible:outline 
						focus-visible:outline-2 
						focus-visible:outline-offset-2'
						onClick={onConfirm}
					>
						<span className='block tracking-wider'>Confirm</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default ConfirmationModal;
