/* eslint-disable react-refresh/only-export-components */
import React from 'react';
import { getApproachStats } from '../../Services/statsService';
import StatDisplay from '../ui/displays/StatDisplay';
import withStats from './withStats';

const ApproachStats = ({ stats }) => {
	return (
		<div className='flex w-full flex-row space-x-6'>
			<div className='w-1/3'>
				<StatDisplay
					value={`${stats.greenInRegulation}%`}
					label='GIR'
				/>
			</div>
			<div className='w-1/3'>
				<StatDisplay
					value={`${stats.greenInRegulationFairway}%`}
					label='GIR from Fairway'
				/>
			</div>
			<div className='w-1/3'>
				<StatDisplay
					value={`${stats.greenInRegulationRough}%`}
					label='GIR from Rough'
				/>
			</div>
		</div>
	);
};

export default withStats(ApproachStats, getApproachStats);
