import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '../../utils/utils';
import { Text } from './Text';

const textAreaVariants = cva(
	'border text-gray-700 bg-white w-full placeholder:tracking-wider focus:border-gray-400 transition duration-200 focus:outline-none placeholder:text-gray-400',
	{
		variants: {
			variant: {
				default: 'border-gray-300',
				outline: '',
				error: 'border-red-300',
			},
			size: {
				default: 'h-28 p-3 rounded',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
);

interface TextAreaProps
	extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	variant?: VariantProps<typeof textAreaVariants>['variant'];
	textAreaSize?: VariantProps<typeof textAreaVariants>['size'];
	label?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
	className,
	textAreaSize,
	variant,
	label,
	...props
}) => {
	return (
		<div>
			{label && (
				<label className='block text-gray-700 font-medium pl-1'>
					<Text variant='body2' color='dark' className='font-medium'>
						{label}
					</Text>
				</label>
			)}
			<textarea
				className={cn(
					textAreaVariants({ variant, size: textAreaSize, className })
				)}
				{...props}
			/>
		</div>
	);
};

export { TextArea, textAreaVariants };
