/* eslint-disable react-refresh/only-export-components */
import React from 'react';
import { useEffect, useState } from 'react';
import LineChart from '../ui/charts/LineChart';
import HorizontalBarChart from '../ui/charts/HorizontalBarChart';
import PieChart from '../ui/charts/PieChart';
import { getOverview } from '../../Services/statsService';
import { getAllRounds } from '../../Services/roundService';
import withStats from './withStats';

const StatsOverview = ({ stats }) => {
	const [rounds, setRounds] = useState([]);

	useEffect(() => {
		getAllRounds().then((res) => {
			setRounds(res);
		});
	}, []);

	if (rounds.length === 0) {
		return <div>Loading...</div>;
	}

	const drivingLabels = [
		`Hit ${stats.drivingAccuracy}%`,
		`Missed ${100 - stats.drivingAccuracy}%`,
	];

	const drivingData = [stats.drivingAccuracy, 100 - stats.drivingAccuracy];

	const approachLabels = [
		`Hit ${stats.greenInRegulation}%`,
		`Missed ${100 - stats.greenInRegulation}%`,
	];

	const approachData = [
		stats.greenInRegulation,
		100 - stats.greenInRegulation,
	];

	const backgroundColors = [
		'rgba(75, 192, 192, 0.2)',
		'rgba(255, 99, 132, 0.2)',
	];

	const borderColors = ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'];

	return (
		<div className='flex flex-col w-full space-y-6'>
			<div className='flex flex-row space-x-6'>
				<div className='w-1/2'>
					<div className='w-full h-full space-y-4 border border-gray-300 rounded p-8'>
						<h3 className='text-xl text-gray-600 font-medium tracking-wider'>
							Scores
						</h3>
						<LineChart rounds={rounds} />
					</div>
				</div>
				<div className='w-1/2'>
					<div className='w-full h-full space-y-4 p-8 border border-gray-300 rounded'>
						<h3 className='text-xl text-gray-600 font-medium tracking-wider'>
							Putting
						</h3>
						<HorizontalBarChart
							averages={{
								averagePuttsPerHole: stats.puttingAverage,
								averagePuttsPerGIR: stats.puttingAverageGir,
							}}
						/>
					</div>
				</div>
			</div>
			<div className='flex flex-row space-x-6'>
				<div className='w-1/3'>
					<div className='flex flex-col justify-center items-center w-full p-8 border border-gray-300 rounded space-y-4'>
						<span className='text-6xl text-teal-600 tracking-wide'>
							{stats.upAndDown}%
						</span>
						<h3 className='text-lg text-gray-600 font-medium tracking-wider'>
							Up And Down
						</h3>
					</div>
				</div>
				<div className='w-1/3'>
					<div className='flex flex-col justify-center items-center w-full p-8 border border-gray-300 rounded space-y-4'>
						<span className='text-6xl text-teal-600 tracking-wide'>
							{stats.sandSave}%
						</span>
						<h3 className='text-lg text-gray-600 font-medium tracking-wider'>
							Sand Saves
						</h3>
					</div>
				</div>
				<div className='w-1/3'>
					<div className='flex flex-col justify-center items-center w-full p-8 border border-gray-300 rounded space-y-4'>
						<span className='text-6xl text-teal-600 tracking-wide'>
							{stats.scrambling}%
						</span>
						<h3 className='text-lg text-gray-600 font-medium tracking-wider'>
							Scrambling
						</h3>
					</div>
				</div>
			</div>
			<div className='flex flex-row space-x-6'>
				<div className='w-1/2'>
					<div className='w-full space-y-4 p-8 border border-gray-300 rounded'>
						<h3 className='text-xl text-gray-600 font-medium tracking-wider'>
							Driving Accuracy
						</h3>
						<PieChart
							labels={drivingLabels}
							data={drivingData}
							backgroundColors={backgroundColors}
							borderColors={borderColors}
						/>
					</div>
				</div>
				<div className='w-1/2'>
					<div className='w-full space-y-4 p-8 border border-gray-300 rounded'>
						<h3 className='text-xl text-gray-600 font-medium tracking-wider'>
							Approach Accuracy
						</h3>
						<PieChart
							labels={approachLabels}
							data={approachData}
							backgroundColors={backgroundColors}
							borderColors={borderColors}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withStats(StatsOverview, getOverview);
