import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../../../../Components/ui/Text';
import Player from '../../../../Shared/types/player.type';
import { getRandomColor } from '../../../../utils/avatarUtils';

type AddPlayerProps = {
	player?: Player;
	onClick: () => void;
	onRemovePlayer: (playerId: string) => void;
};

const AddPlayerButton = ({
	player,
	onClick,
	onRemovePlayer,
}: AddPlayerProps) => {
	return (
		<div className='flex items-center justify-between'>
			<div className='flex items-center space-x-4'>
				<div
					className='flex items-center justify-center bg-white w-14 h-14 border border-gray-100 shadow-sm rounded-md'
					onClick={onClick}
				>
					{player ? (
						player.profileImageUrl ? (
							// Display player's profile image
							<img
								src={player.profileImageUrl}
								alt={`${player.givenName} ${player.surname}`}
								className='w-full h-full object-cover rounded-md'
							/>
						) : (
							// Display initials with a background color
							<div
								className='w-full h-full flex items-center justify-center rounded-md'
								style={{ backgroundColor: getRandomColor() }}
							>
								<Text variant='body2' className='text-white'>
									{player.initials}
								</Text>
							</div>
						)
					) : (
						// Default add icon when no player is selected
						<FontAwesomeIcon
							icon={faPlus}
							className='text-2xl text-gray-500'
						/>
					)}
				</div>
				<Text variant='h6'>
					{player
						? `${player.givenName} ${player.surname}`
						: 'Add player'}
				</Text>
			</div>
			{player && (
				<span
					className='border p-2 rounded-3xl bg-primary-blue-darker'
					onClick={() => onRemovePlayer(player!.userId)}
				>
					<Text variant='body2' color='white'>
						Remove
					</Text>
				</span>
			)}
		</div>
	);
};

export default AddPlayerButton;
