import { useState } from 'react';
import SearchBar from '../../../Components/ui/SearchBar';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Text } from '../../../Components/ui/Text';
import { GameModeEnum } from '../../../models/Enums/gameModeEnum';
import { TournamentRound } from '../../../models/Tournament/TornamentRound.type';
import SelectBox, { DropdownOptions } from '../../../Components/ui/SelectBox';
import { Button } from '../../../Components/ui/Button';
import { Course } from '../../../models/courseDetails.type';
import DateInput from '../../../Components/ui/DateInput';

type RoundDetailsProps = {
	round: TournamentRound;
	onSaveChanges: (round: TournamentRound) => void;
};

const gameModeOptions = Object.entries(GameModeEnum)
	.filter(([key, value]) => typeof value === 'number')
	.map(([key, value]) => ({
		label: key,
		value: value as string,
	}));

const RoundDetails = ({ round, onSaveChanges }: RoundDetailsProps) => {
	const saveChanges = (values: {
		date: Date | null;
		tee: string | null;
		format: GameModeEnum | null;
		course: Course;
	}) => {
		const { date, tee, format, course } = values;

		// If the form is valid, pass updated round data to parent
		const updatedRound: TournamentRound = {
			...round,
			format: format as GameModeEnum,
			date: date || round.date,
			tee: tee,
			course: course,
		};
		onSaveChanges(updatedRound);
	};

	const handleFormatChange = (value: string) => {
		const modeNumber = Number(value);
		if (modeNumber in GameModeEnum) {
			formik.setFieldValue('format', modeNumber);
		} else {
			console.error('Invalid game mode number:', modeNumber);
			formik.setFieldValue('format', null);
		}
	};

	const handleCourseChange = (newCourse: Course) => {
		formik.setFieldValue('course', newCourse);
		formik.setFieldValue('tee', null);
	};

	const formik = useFormik({
		initialValues: {
			date: round.date,
			tee: round.tee,
			format: round.format,
			course: round.course,
		},
		validationSchema: Yup.object().shape({
			date: Yup.date()
				.nullable()
				.required('Please select the round date'),
			tee: Yup.string().nullable().required('Please select a tee'),
			format: Yup.number().nullable().required('Please select a format'),
			course: Yup.object()
				.shape({
					courseId: Yup.string().required('Course is required'),
				})
				.nullable(),
		}),
		onSubmit: async (values) => {
			//saveChanges(values);
			const { date, tee, format, course } = values;
			const updatedRound: TournamentRound = {
				...round,
				format: format,
				date: date,
				tee: tee,
				course: course
			}
			onSaveChanges(updatedRound);
			console.log(values);
		},
	});

	const teeOptions: DropdownOptions[] = formik.values.course?.tees
		? Object.entries(formik.values.course.tees).map(([key, value]) => ({
				label: value,
				value: key,
		  }))
		: [];

	return (
		<form
			className='flex flex-col justify-between w-full'
			onSubmit={formik.handleSubmit}
		>
			<div className='space-y-4'>
				<div>
					<Text variant='body1'>Course</Text>
					<SearchBar
						selectedCourse={formik.values.course}
						onSelect={handleCourseChange}
					/>
				</div>

				<div>
					<Text variant='body1'>Tee</Text>
					<SelectBox
						placeholder='Tee'
						options={teeOptions}
						selectedValue={formik.values.tee}
						error={
							formik.touched.tee && formik.errors.tee
								? formik.errors.tee
								: undefined
						}
						onSelect={(tee) => formik.setFieldValue('tee', tee)}
					/>
				</div>

				<div>
					<Text variant='body1'>Format</Text>
					<SelectBox
						placeholder='Format'
						options={gameModeOptions}
						selectedValue={formik.values.format}
						onSelect={handleFormatChange}
					/>
				</div>

				<div className='flex flex-col w-full'>
					<Text variant='body1'>Time</Text>
					<div id='root-portal'>
						<DateInput
							variant={
								formik.touched.date && formik.errors.date
									? 'error'
									: 'default'
							}
							selected={formik.values.date}
							onChange={(date) =>
								formik.setFieldValue('date', date)
							}
							placeholder='Round date'
						/>
					</div>
				</div>
			</div>

			<Button className='w-full' type='submit'>
				Save changes
			</Button>
		</form>
	);
};

export default RoundDetails;
