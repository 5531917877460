import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../Components/ui/Button';
import { Text } from '../../Components/ui/Text';
import { useState } from 'react';
import { getRandomColor } from '../../utils/avatarUtils';

type PlayerResult = {
	userId: string;
	givenName: string;
	surname: string;
	profileImageUrl?: string | null;
	grossScore: number;
	scoreToPar: number;
	initials: string;
};

const ReviewRound = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const { roundId } = useParams<{
		roundId: string;
	}>();

	const results = (location.state?.results || []) as PlayerResult[];

	const handleBackClick = () => {
		navigate(-1);
	};

	return (
		<div className='min-h-full'>
			{/* Header */}
			<div className='fixed w-full top-0 left-0 z-10 h-16 flex items-center border-b p-4 shadow-sm space-x-5 bg-white'>
				<FontAwesomeIcon
					icon={faChevronLeft}
					className='text-2xl text-gray-700 cursor-pointer'
					onClick={handleBackClick}
				/>
				<Text variant='h6' className='font-medium'>
					Round Summary
				</Text>
			</div>

			{/* Table */}
			<div className='pt-20 pb-20 w-full flex'>
				<table className='divide-y divide-gray-200'>
					<thead>
						<tr>
							<th
								scope='col'
								className='px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
							>
								Player
							</th>
							<th
								scope='col'
								className='px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'
							>
								Gross
							</th>
							<th
								scope='col'
								className='px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'
							>
								To Par
							</th>
						</tr>
					</thead>
					<tbody className='bg-white divide-y divide-gray-200'>
						{results.map((result) => {
							// Compute initials if not provided
							const initials =
								result.initials ||
								`${result.givenName?.charAt(0) || ''}${
									result.surname?.charAt(0) || ''
								}`;
							return (
								<tr key={result.userId}>
									{/* Player Column */}
									<td className='px-6 py-4 whitespace-nowrap'>
										<div className='flex items-center'>
											<div
												className='w-10 h-10 rounded-full flex-shrink-0 flex items-center justify-center overflow-hidden'
												style={{
													backgroundColor:
														result.profileImageUrl
															? 'transparent'
															: getRandomColor(),
												}}
											>
												{result.profileImageUrl ? (
													<img
														src={
															result.profileImageUrl
														}
														alt={initials}
														className='w-full h-full rounded-full object-cover'
													/>
												) : (
													<Text
														variant='body2'
														className='text-white'
													>
														{initials}
													</Text>
												)}
											</div>
											<div className='ml-4'>
												<Text
													variant='body2'
													className='text-gray-900'
												>
													{result.givenName}{' '}
													{result.surname}
												</Text>
											</div>
										</div>
									</td>

									{/* Gross Score Column */}
									<td className='px-6 py-4 whitespace-nowrap text-right'>
										<Text
											variant='body2'
											className='text-gray-900'
										>
											{result.grossScore}
										</Text>
									</td>

									{/* To Par Column */}
									<td className='px-6 py-4 whitespace-nowrap text-right'>
										<Text
											variant='body2'
											className='text-gray-900'
										>
											{result.scoreToPar >= 0 ? '+' : ''}
											{result.scoreToPar}
										</Text>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			{/* Submit Button */}
			<div className='fixed flex left-0 bottom-0 w-full p-4 bg-white'>
				<Button className='w-full'>Submit</Button>
			</div>
		</div>
	);
};

export default ReviewRound;
