import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../Components/ui/Button';
import { Text } from '../../../Components/ui/Text';
import { useMultistepform } from '../../../Hooks/useMultistepForm';
import ChooseNameStep from './Steps/ChooseNameStep';
import { FaArrowLeft } from 'react-icons/fa';
import { AnimatePresence } from 'framer-motion';
import AnimatedDiv from './AnimatedDiv';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import EditRoundsStep from './Steps/EditRoundsStep';
import { useModal } from '../../../Hooks/useModal';
import PopupModal from '../../../Components/ui/modals/PopupModal';
import ConfirmationBox from '../../../Components/ui/ConfirmationBox';
import { useCreateTournamentMutation } from '../../../features/tournament/tournamentApiSlice';
import UploadMediaStep from './Steps/UploadMediaStep';
import { toast } from 'react-toastify';
import ContactInfoStep from './Steps/ContactInfoStep';
import { useGetUserDetailsQuery } from '../../../features/user/userApiSlice';
import { TournamentFormData } from '../types/TournamentFormData';

const INITIAL_DATA: TournamentFormData = {
	tournamentName: '',
	rounds: null,
	thumbnail: null,
	attachments: null,
	contactGivenName: '',
	contactSurname: '',
	contactEmail: '',
	contactPhone: '',
};

const TournamentSetup = () => {
	const navigate = useNavigate();
	const [direction, setDirection] = useState<number>(1);
	//const [formData, setFormData] = useState<TournamentFormData>(INITIAL_DATA);

	const { data: userDetails, isLoading: isUserDetailsLoading } =
		useGetUserDetailsQuery();

	const [createTournament, { isLoading, isError, data }] =
		useCreateTournamentMutation();

		const [initialValues, setInitialValues] = useState<TournamentFormData>({
			...INITIAL_DATA,
			rounds: [],
		});

		useEffect(() => {
			if (userDetails) {
				setInitialValues((prev) => ({
					...prev,
					contactGivenName: userDetails.givenName,
					contactSurname: userDetails.surname,
					contactEmail: userDetails.email,
				}));
			}
		}, [userDetails]);

	const {
		isOpen: isModalOpen,
		openModal: openModal,
		closeModal: closeModal,
	} = useModal();

	// const updateFields = (fields: Partial<TournamentFormData>) => {
	// 	setFormData((prev) => {
	// 		return { ...prev, ...fields };
	// 	});
	// };

	const { currentStepIndex, step, isFirstStep, isLastStep, back, next } =
		useMultistepform([
			<ChooseNameStep />,
			<ContactInfoStep />,
			// <SelectCourseStep {...formData} updateFields={updateFields} />,
			<UploadMediaStep />,
			<EditRoundsStep />,
		]);

	const nextStepHandler = async (values: TournamentFormData) => {
		if (!isLastStep) {
			setDirection(1);
			next();
		} else {
			if (values.rounds && values.rounds.length > 0) {
				const formDataToSend = new FormData();

				formDataToSend.append(
					'tournamentName',
					values.tournamentName
				);

				formDataToSend.append(
					'contactGivenName',
					values.contactGivenName
				);

				formDataToSend.append(
					'contactSurname',
					values.contactSurname
				);

				formDataToSend.append('contactEmail', values.contactEmail);
				formDataToSend.append('contactPhone', values.contactPhone);

				// Only append thumbnail if it exists
				if (values.thumbnail) {
					formDataToSend.append('thumbnail', values.thumbnail); // Thumbnail as File
				}

				values.rounds.forEach((round, index) => {
					formDataToSend.append(
						`rounds[${index}].roundNumber`,
						round.roundNumber.toString()
					);
					formDataToSend.append(
						`rounds[${index}].roundDate`,
						round.date.toISOString()
					);
					formDataToSend.append(
						`rounds[${index}].courseId`,
						round.course?.courseId || ''
					);
					formDataToSend.append(
						`rounds[${index}].layoutId`,
						round.tee || ''
					);
					formDataToSend.append(
						`rounds[${index}].format`,
						round.format.toString()
					);
				});
				try {
					for (const [key, value] of formDataToSend.entries()) {
						console.log(key, value);
					}
					const response = await createTournament(
						formDataToSend
					).unwrap();
					toast.success(
						'Tournament created successfully'
					);
					navigate(`/tournament/${response}`);
				} catch (error: any) {
					if (error?.data?.errors) {
						error.data.errors.forEach(
							(err: { code: string; description: string }) => {
								toast.error(`Error: ${err.description}`);
							}
						);
					} else {
						console.error(
							'Failed to create tournament:',
							error?.data?.message || error
						);
					}
				}
			}
		}
	};

	const prevStepHandler = async () => {
		if (!isFirstStep) {
			setDirection(-1);
			back();
		}
	};

	const exit = () => {
		navigate('/');
	};

	const validationSchemas = [
		Yup.object().shape({
			tournamentName: Yup.string()
				.required('Tournament Name is required')
				.max(300, 'Max length is 300 characters'),
		}),
		Yup.object().shape({
			contactGivenName: Yup.string().required('First name is required'),
			contactSurname: Yup.string().required('Last name is required'),
			contactEmail: Yup.string()
				.email('Invalid email')
				.required('Email is required'),
			contactPhone: Yup.string().required('Phone number is required'),
		}),
		Yup.object().shape({
			thumbnail: Yup.string().nullable(),
			attachments: Yup.string().nullable(),
		}),
		Yup.object().shape({
			rounds: Yup.array()
				.of(
					Yup.object().shape({
						course: Yup.object()
							.nullable()
							.required('You must select a course'),
						roundNumber: Yup.number()
							.required('Round number is required')
							.integer('Round number must be an integer')
							.min(1, 'Round number must be at least 1'),
						date: Yup.date()
							.nullable()
							.required('Round date is required'),
						tee: Yup.string()
							.nullable()
							.required('Tee is required'),
						format: Yup.number()
							.nullable()
							.required('Format is required'),
					})
				)
				.min(1, 'At least one round is required')
				.required('Rounds are required'),
		}),
	];

	if (isUserDetailsLoading) {
		return <div>Loading...</div>;
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchemas[currentStepIndex]}
			onSubmit={nextStepHandler}
			validateOnMount={true}
			enableReinitialize={true}
		>
			{({ isValid, validateForm }) => {
				useEffect(() => {
					validateForm();
				}, [currentStepIndex, validateForm]);

				return (
					<Form className='flex flex-col justify-between h-full w-full bg-white overflow-x-hidden'>
						<div className='flex items-center h-14 w-full border-b border-gray-200'>
							{!isFirstStep && (
								<FaArrowLeft
									className='absolute text-base text-gray-700 left-4 top-4 cursor-pointer'
									onClick={prevStepHandler}
								/>
							)}
							<Text
								className='absolute right-4 font-bold text-primary-blue-darker cursor-pointer z-40'
								onClick={openModal}
							>
								Exit
							</Text>
							<PopupModal open={isModalOpen} onClose={closeModal}>
								<ConfirmationBox
									onClose={closeModal}
									onConfirm={exit}
									title='Quit Setup'
									description='Are you sure you want to Exit. All changes will be lost?'
								/>
							</PopupModal>
						</div>
						<AnimatePresence mode='wait' custom={direction}>
							<AnimatedDiv
								className='flex flex-grow h-full p-4 overflow-y-scroll'
								key={currentStepIndex}
								direction={direction}
							>
								{step}
							</AnimatedDiv>
						</AnimatePresence>

						<div className='w-full p-4'>
							<Button
								type='submit'
								variant={
									!isValid || isLoading
										? 'disabled'
										: 'default'
								}
								className='w-full'
								disabled={!isValid}
								loading={isLoading}
							>
								{isLastStep ? 'Complete' : 'Next'}
							</Button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export default TournamentSetup;
