import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '../../utils/utils';
import React, { Fragment } from 'react';
import { Text } from './Text';
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

const dateInputVariants = cva(
	'border text-gray-700 bg-white w-full placeholder:tracking-wider focus:border-gray-400 transition duration-200 focus:outline-none placeholder:text-gray-400',
	{
		variants: {
			variant: {
				default: 'border-gray-300',
				outline: '',
				error: 'border-red-300',
			},
			size: {
				default: 'h-10 px-3 py-4 rounded',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
);

interface DateInputProps {
	variant?: VariantProps<typeof dateInputVariants>['variant'];
	inputSize?: VariantProps<typeof dateInputVariants>['size'];
	label?: string;
	placeholder: string;
	selected: Date | null | undefined;
	onChange: (date: Date | null) => void;
	className?: string;
}

const DateInput: React.FC<DateInputProps> = ({
	className,
	inputSize,
	variant,
	label,
	placeholder,
	selected,
	onChange,
	...props
}) => {
	return (
		<div>
			{label && (
				<label className='block text-gray-700 font-medium pl-1'>
					<Text variant='body2' color='dark' className='font-medium'>
						{label}
					</Text>
				</label>
			)}
			<DatePicker
				className={cn(
					dateInputVariants({ variant, size: inputSize, className })
				)}
				placeholderText={placeholder}
				selected={selected}
				onChange={onChange}
				showYearDropdown
				showMonthDropdown
				dropdownMode='select'
				portalId='root-portal'
				{...props} // Spread other DatePicker props here
			/>
		</div>
	);
};

export default DateInput;
