import { GameModeEnum } from '../Enums/gameModeEnum';
import * as Yup from 'yup';

export const roundSchema = Yup.object().shape({
	layoutId: Yup.string().nullable().required('Layout is required'),
	roundDate: Yup.date().required('Round date is required'),
});

export interface NewRoundRequest {
	courseId: string | null;
	layoutId: string | null;
	userId: string | null;
	roundDate: Date | null;
	courseRatingSnapshot: number | null;
	slopeRatingSnapshot: number | null;
	scoreDifferential: number | null;
	gameMode: GameModeEnum;
	logShots: boolean;
}
