import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../Shared/axiosBaseQuery';
import GetTournamentByIdResponse from './models/GetTournamentByIdResponse';
import SearchTournamentsResponse from './models/SearchTournamentsResponse';
import TournamentRegistrationRequest from './models/TournamentRegistrationRequest';
import { DELETE, GET, POST, PUT } from '../shared/httpMethods';
import TournamentSummary from './models/TournamentSummary';
import Player from '../../Shared/types/player.type';
import Group from './models/Group';
import TournamentRound from './models/TournamentRound';
import NewGroupRequest from './models/NewGroupRequest';
import GetLeaderboardResponse from './models/GetLeaderboardResponse';

export const tournamentApiSlice = createApi({
	reducerPath: 'tournamentApi',
	baseQuery: axiosBaseQuery(),
	tagTypes: ['Groups'],
	endpoints: (builder) => ({
		getTournamentsForUser: builder.query<TournamentSummary[], void>({
			query: () => ({
				url: 'tournaments',
				method: GET,
			}),
		}),
		getTournamentById: builder.query<GetTournamentByIdResponse, string>({
			query: (id: string) => ({
				url: `tournaments/${id}`,
				method: GET,
			}),
		}),
		searchTournaments: builder.query<SearchTournamentsResponse[], void>({
			query: () => ({
				url: 'tournaments/explore',
				method: GET,
			}),
		}),
		getGroups: builder.query<Group[], string>({
			query: (tournamentId: string) => ({
				url: `tournaments/${tournamentId}/groups`,
				method: GET,
			}),
			providesTags: ['Groups'],
		}),
		getPlayers: builder.query<Player[], string>({
			query: (tournamentId: string) => ({
				url: `tournaments/${tournamentId}/players`,
				method: GET,
			}),
		}),
		getRounds: builder.query<TournamentRound[], string>({
			query: (tournamentId: string) => ({
				url: `tournaments/${tournamentId}/rounds`,
				method: GET,
			}),
		}),
		getLeaderboard: builder.query<GetLeaderboardResponse, string>({
			query: (tournamentId: string) => ({
				url: `tournaments/leaderboard?tournamentId=${tournamentId}&roundNumber=1`,
				method: GET,
			}),
			keepUnusedDataFor: 0,
		}),
		createTournament: builder.mutation<string, FormData>({
			query: (formData) => ({
				url: 'tournaments',
				method: POST,
				data: formData,
			}),
		}),
		createGroup: builder.mutation<string, NewGroupRequest>({
			query: (newGroupData) => ({
				url: 'tournaments/group',
				method: POST,
				data: newGroupData,
			}),
			invalidatesTags: ['Groups'],
		}),
		registerForTournament: builder.mutation<
			void,
			TournamentRegistrationRequest
		>({
			query: (request) => ({
				url: 'tournaments/register',
				method: POST,
				data: request,
			}),
		}),
		updateGroup: builder.mutation<void, NewGroupRequest>({
			query: (updatedGroup) => ({
				url: 'tournaments/group',
				method: PUT,
				data: updatedGroup,
			}),
			invalidatesTags: ['Groups'],
		}),
		deleteTournament: builder.mutation<void, string>({
			query: (tournamentId: string) => ({
				url: `tournaments/${tournamentId}`,
				method: DELETE,
			}),
		}),
		deleteGroup: builder.mutation<void, string>({
			query: (groupId: string) => ({
				url: `tournaments/groups/${groupId}`,
				method: DELETE,
			}),
			invalidatesTags: ['Groups'],
		}),
	}),
});

export const {
	useGetTournamentsForUserQuery,
	useCreateTournamentMutation,
	useCreateGroupMutation,
	useGetTournamentByIdQuery,
	useGetGroupsQuery,
	useGetPlayersQuery,
	useGetRoundsQuery,
	useGetLeaderboardQuery,
	useSearchTournamentsQuery,
	useLazySearchTournamentsQuery,
	useRegisterForTournamentMutation,
	useUpdateGroupMutation,
	useDeleteTournamentMutation,
	useDeleteGroupMutation,
} = tournamentApiSlice;
