import { VariantProps, cva } from 'class-variance-authority';
import React, { ReactNode } from 'react';
import { cn } from '../../utils/utils';

const textVariants = cva('tracking-wider', {
	variants: {
		variant: {
			h1: 'text-4xl md:text-5xl lg:text-6xl font-bold',
			h2: 'text-3xl md:text-4xl lg:text-5xl font-medium',
			h3: 'text-2xl md:text-3xl lg:text-4xl font-medium',
			h4: 'text-xl md:text-2xl lg:text-3xl font-medium',
			h5: 'text-lg md:text-xl lg:text-2xl font-medium text-gray-600',
			h6: 'text-base text-gray-700',
			subtitle1: 'text-lg md:text-xl lg:text-2xl font-normal',
			subtitle2: 'text-base md:text-lg lg:text-xl font-normal',
			body1: 'text-base md:text-lg lg:text-xl font-normal',
			body2: 'text-sm md:text-base lg:text-lg font-normal',
			fine1: 'text-xs md:text-sm lg:text-base font-light',
			fine2: 'text-xs md:text-xs lg:text-sm font-light',
		},
		color: {
			darker: 'text-gray-900',
			dark: 'text-gray-700',
			default: 'text-gray-500',
			light: 'text-gray-400',
			lighter: 'text-gray-300',
			white: 'text-white',
			error: 'text-red-400'
		},
	},
	
});

type Variant =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'subtitle1'
	| 'subtitle2'
	| 'body1'
	| 'body2'
	| 'fine1'
	| 'fine2';
type Color = 'darker' | 'dark' | 'default' | 'light' | 'lighter' | 'white' | 'error';

interface TextProps extends React.HTMLAttributes<HTMLSpanElement>, VariantProps<typeof textVariants> {
	className?: string;
	variant?: Variant;
	color?: Color;
	children: ReactNode;
}

const Text: React.FC<TextProps> = ({
	className,
	variant = 'h6',
	color = 'dark',
	children,
	...props
}) => {
  const Comp = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    subtitle1: 'h6',
    subtitle2: 'h6',
    body1: 'p',
    body2: 'p',
    fine1: 'p',
    fine2: 'p',
  }[variant] as keyof React.JSX.IntrinsicElements;

	return (
		<Comp
			className={cn(textVariants({ variant, color, className }))}
			{...props}
		>
			{children}
		</Comp>
	);
};

export { Text, textVariants };
