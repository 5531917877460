import { createListenerMiddleware } from '@reduxjs/toolkit';
import { loginUser, signUpUser } from '../features/auth/authActions';
import { userApiSlice } from '../../features/user/userApiSlice';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: loginUser.fulfilled,
  effect: async (action, listenerApi) => {
    await listenerApi.dispatch(userApiSlice.endpoints.getUserDetails.initiate());
  },
});

// listenerMiddleware.startListening({
//   actionCreator: signUpUser.fulfilled,
//   effect: async (action, listenerApi) => {
//     // The state has been updated; token is now available
//     await listenerApi.dispatch(userApiSlice.endpoints.getUserDetails.initiate());
//   },
// });

export default listenerMiddleware;
