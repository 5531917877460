import { getRandomColor } from '../../utils/avatarUtils';
import { Text } from './Text';

type UserAvatarProps = {
	url: null | string;
	givenName: string;
	surname: string;
	initials: string;
	playerId: string;
	onClick: (playerId: string) => void;
};

const UserAvatar = ({
	url,
	givenName,
	surname,
	initials,
	playerId,
	onClick,
}: UserAvatarProps) => {
	return (
		<div className='w-full flex flex-row justify-between'>
			<div
				className='flex flex-row items-center space-x-2'
				onClick={() => onClick(playerId)}
			>
				{url ? (
					<img
						src={url}
						alt={initials}
						className='w-10 h-10 rounded-full'
					/>
				) : (
					<div
						className='w-10 h-10 rounded-full flex flex-col items-center justify-center'
						style={{
							backgroundColor: getRandomColor(),
						}}
					>
						<div>
							<Text variant='body2'>{initials}</Text>
						</div>
					</div>
				)}
				<Text variant='h6' className='font-normal'>
					{givenName} {surname}
				</Text>
			</div>
		</div>
	);
};

export default UserAvatar;
