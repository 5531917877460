import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../../../../Components/ui/Text';
import Group from '../../../../features/tournament/models/Group';
import { getRandomColor } from '../../../../utils/avatarUtils';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';

type PairingCardProps = {
	group: Group;
	roundId: string | null;
	groupedUserIds: string[];
};

const PairingCard = ({ group, roundId, groupedUserIds }: PairingCardProps) => {
	const navigate = useNavigate();
	const { tournamentId } = useParams();

	const handleEditClick = () => {
		if (roundId !== null) {
			navigate(`/tournament/${tournamentId}/pairings/edit`, {
				state: {
					roundId: roundId,
					groupedUserIds: groupedUserIds,
					group,
				},
			});
		}
	};
	console.log(group);
	return (
		<div className='flex flex-col justify-between w-full aspect-video bg-white p-4 rounded-md shandow-xs border border-gray-100 space-y-4'>
			<div className='flex justify-between'>
				<div>
					<Text variant='body1' className='mb-1'>
						Group {group.groupNumber} (Tee 1)
					</Text>
					{group.teeTime && (
						<Text variant='body2' color='light'>
							{group.teeTime}
						</Text>
					)}
					{group.startingHole && (
						<Text variant='body2' color='light'>
							{group.startingHole}
							{/* // also put tee i.e Blue tees */}
						</Text>
					)}
				</div>
				<FontAwesomeIcon
					icon={faEdit}
					className='text-xl text-gray-600'
					onClick={handleEditClick}
				/>
			</div>
			<div className='flex h-auto space-x-4'>
				{group.players.map((player) => (
					<div className='flex flex-col items-center space-y-2' key={player.userId}>
						<div
							className='w-12 h-12 rounded-full flex flex-col items-center justify-center'
							style={{
								backgroundColor: getRandomColor(),
							}}
						>
							<div>
								<Text variant='body2'>{player.initials}</Text>
							</div>
						</div>
						<Text variant='fine2'>{player.givenName}</Text>
					</div>
				))}
			</div>
		</div>
	);
};

export default PairingCard;
