import { Course } from '../models/courseDetails.type';
import axiosClient from '../Shared/ApiClient';

export const courseApi = {
	get(courseId: string) {
		const url = `Course?courseId=${courseId}`;
		return axiosClient.get<Course>(url);
	},
	autoCompleteCourseSearch(searchQ: string) {
		const url = `Course/Search?searchQ=${searchQ}`;
		return axiosClient.get<Course[]>(url);
	},
};
