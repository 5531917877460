import { Club, Lie } from '../Shared/enums';

export const primeNewRoundData = (courseData) => {
	courseData.holes = courseData.holes.map((hole) => ({
		...hole,
		fairwayHit: hole.par === 3 ? null : false,
		greenInRegulation: false,
		putts: null,
		upAndDown: null,
		sandSave: null,
		strokes: null,
		shots: [
			{
				lie: Lie.TEE,
				club: '',
				distance: hole.length,
			},
		],
	}));
	return courseData;
};
