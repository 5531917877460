import { format, isSameDay, parseISO } from 'date-fns';

export const formatDate = (dateString: string): string => {
	const date = new Date(dateString);
	const day = date.getDate();
	const month = date.getMonth() + 1; // Month indexes start from 0
	const year = date.getFullYear();

	return `${day}/${month}/${year}`;
};

export const formatTournamentDate = (tournamentRounds: Array<{roundDate: string}>) => {
	if (tournamentRounds.length === 0) return '';

  // Convert roundDate strings to Date objects
  const roundDates = tournamentRounds.map(round => parseISO(round.roundDate));

  // If there's only one round, or all rounds are on the same day
  if (roundDates.length === 1 || roundDates.every(date => isSameDay(date, roundDates[0]))) {
    const singleRoundDate = roundDates[0];
    const formattedDate = format(singleRoundDate, 'EEE do MMMM yyyy'); // e.g., "Wed 11th September 2024"
    const day = format(singleRoundDate, 'd');
    return `${format(singleRoundDate, 'EEE')} ${day}${getDaySuffix(parseInt(day))} ${format(singleRoundDate, 'MMMM yyyy')}`;
  }

  // If there are multiple rounds and they're on different days
  const firstRoundDate = roundDates[0];
  const lastRoundDate = roundDates[roundDates.length - 1];
  const firstDay = format(firstRoundDate, 'd');
  const lastDay = format(lastRoundDate, 'd');

  return `${format(firstRoundDate, 'EEE')} ${firstDay}${getDaySuffix(parseInt(firstDay))} - ${format(lastRoundDate, 'EEE')} ${lastDay}${getDaySuffix(parseInt(lastDay))} ${format(lastRoundDate, 'MMMM yyyy')}`;
}

const getDaySuffix = (day: number) => {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};