import {
	FontAwesomeIcon,
	FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { FaChevronRight } from 'react-icons/fa';
import { ReactNode, useState } from 'react';
import { Text } from '../ui/Text';
import { motion, AnimatePresence } from 'framer-motion';

type SubMenuProps = {
	label: string;
	icon?: FontAwesomeIconProps['icon'];
	children: ReactNode;
};

const NavSubMenu = ({ label, icon, children }: SubMenuProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleSubMenu = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className='space-y-2 lg:space-y-3'>
			<li
				className='flex flex-row items-center justify-between text-base tracking-widest font-normal text-gray-700 hover:text-black cursor-pointer'
				onClick={toggleSubMenu}
			>
				<div className='flex flex-row items-center'>
					{icon && (
						<FontAwesomeIcon
							icon={icon}
							className='text-gray-600 text-base mr-3'
						/>
					)}
					<Text>{label}</Text>
				</div>
				<div className='hidden lg:block'>
					<motion.div
						animate={{ rotate: isOpen ? 90 : 0 }}
						transition={{ duration: 0.2 }}
					>
						<FaChevronRight className='text-base text-gray-600' />
					</motion.div>
				</div>
			</li>
			<AnimatePresence initial={false}>
				{isOpen && (
					<motion.ul
						initial={{ height: 0 }}
						animate={{ height: 'auto' }}
						exit={{ height: 0 }}
						transition={{ duration: 0.2 }}
						className='pl-8 space-y-2 lg:space-y-4 overflow-hidden'
					>
						{children}
					</motion.ul>
				)}
			</AnimatePresence>
		</div>
	);
};

export default NavSubMenu;
