import React from 'react';
import { GameModeEnum } from '../../../models/Enums/gameModeEnum';
import { useModal } from '../../../Hooks/useModal';
import Modal from '../../../Components/ui/Modal';
import { Typography } from '@mui/material';
import { Text } from '../../../Components/ui/Text';

interface GameModeSelectProps {
	onSelect: (gameMode: GameModeEnum) => void;
	selected: GameModeEnum;
}

const GameModeModal: React.FC<GameModeSelectProps> = ({
	onSelect,
	selected,
}) => {
	console.log(selected);
	const {
		isOpen: isModalOpen,
		openModal: openModal,
		closeModal: closeModal,
	} = useModal();

	const handleSelect = (gameMode: GameModeEnum) => {
		onSelect(gameMode);
		closeModal();
	};

	const gameModes = Object.values(GameModeEnum).filter(
		(value) => typeof value === 'string'
	) as string[];

	return (
		<>
			<div>
				<Text variant='h6' color='default' className='pl-2'>Gamemode</Text>
				<div
					className='bg-app-background mt-2 p-2 rounded-md cursor-pointer'
					onClick={openModal}
				>
					<Text variant='h6'>{GameModeEnum[selected]}</Text>
				</div>
			</div>
			<Modal
				open={isModalOpen}
				onClose={closeModal}
				className='flex items-center justify-center bg-white'
			>
				<div className='flex flex-col w-full space-y-8 p-4'>
					{gameModes.map((mode, index) => (
						<Text
							key={index}
							variant='h6'
							onClick={() => handleSelect(index as GameModeEnum)}
						>
							{mode}
						</Text>
					))}
				</div>
			</Modal>
		</>
	);
};

export default GameModeModal;
