import { Typography } from '@mui/material';
import React from 'react';

const PlayersList = () => {
	return (
		<div className='flex w-full space-x-5'>
			<span className='flex w-1/4 h-1/4 aspect-square items-center justify-center rounded-md bg-app-background'>
				<Typography variant='h5'>CS</Typography>
			</span>
      <span className='flex w-1/4 h-1/4 aspect-square items-center justify-center rounded-md bg-app-background'>
				<Typography variant='h6'>+</Typography>
			</span>
      <span className='flex w-1/4 h-1/4 aspect-square items-center justify-center rounded-md bg-app-background'>
				<Typography variant='h6'>+</Typography>
			</span>
      <span className='flex w-1/4 h-1/4 aspect-square items-center justify-center rounded-md bg-app-background'>
				<Typography variant='h6'>+</Typography>
			</span>
		</div>
	);
};

export default PlayersList;
