import React from 'react';

const HoleView = ({ holes, currentHole, onHoleClick }) => {

	return (
		<div className='flex flex-row space-x-2'>
			{holes.map((hole) => (
				<span
					key={hole.holeId}
					className={`flex justify-center items-center h-8 w-8 text-sm rounded-full cursor-pointer border border-gray-300 
                      ${
							hole.holeId === currentHole.holeId
								? 'bg-primary-blue text-white'
								: 'text-gray-600 hover:bg-primary-blue hover:text-white'
						}`}
					onClick={() => onHoleClick(hole)}
				>
					{hole.holeNumber}
				</span>
			))}
		</div>
	);
};

export default HoleView;
