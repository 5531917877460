import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GameModeEnum } from '../../models/Enums/gameModeEnum';
import { NewRoundRequest } from '../../models/Round/newRoundRequest';

interface RoundState extends NewRoundRequest {}

const initialState: RoundState = {
	courseId: null,
	layoutId: null,
	userId: null,
	roundDate: new Date(),
	courseRatingSnapshot: null,
	slopeRatingSnapshot: null,
	scoreDifferential: null,
	gameMode: GameModeEnum.StrokePlay,
	logShots: false,
};

const roundSlice = createSlice({
	name: 'round',
	initialState,
	reducers: {
		updateRoundRequest(
			state,
			action: PayloadAction<Partial<NewRoundRequest>>
		) {
			return {
				...state,
				...action.payload,
			};
		},
		deleteRoundRequest: (state) => {
			console.log('called', state);
			state.courseId = null;
			state.layoutId = null;
			state.userId = null;
			state.roundDate = null;
			state.courseRatingSnapshot = null;
			state.slopeRatingSnapshot = null;
			state.scoreDifferential = null;
			state.gameMode = GameModeEnum.StrokePlay;
			state.logShots = false;
		},
	},
});

export const { updateRoundRequest, deleteRoundRequest } = roundSlice.actions;
export default roundSlice.reducer;
