import React from 'react';
import { Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ChartOptions,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import RoundDetailsDto from '../../../models/Round/RoundDetailsDto';

ChartJS.register(
	ChartDataLabels,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

interface LineChartProps {
	rounds?: RoundDetailsDto[];
}

const LineChart: React.FC<LineChartProps> = ({ rounds }) => {
	const reversedRounds = rounds ? [...rounds].reverse() : [];
	const lowestRoundToPar =
		rounds && rounds.length > 0
			? Math.min(...rounds.map((round) => round.toPar))
			: 0;

	const minValue =
		rounds && rounds.length > 0
			? Math.min(
					...reversedRounds.map((round) =>
						Math.min(
							round.scoreDifferential,
							round.handicapIndex ?? Infinity
						)
					)
			  )
			: 0;

	const adjustedMinValue =
		rounds && rounds.length > 0 ? Math.floor(minValue) - 1 : 0;

	const data = {
		labels: reversedRounds.map((_, index) => `${index + 1}`),
		datasets: [
			{
				label: 'Score to Par',
				data: reversedRounds.map((round) => round.scoreDifferential),
				borderColor: 'rgb(107 114 128)',
				backgroundColor: 'rgba(75, 192, 192, 0.2)',
				fill: false,
				tension: 0.2,
			},
			{
				label: 'Handicap Index',
				data: reversedRounds.map((round) => round.handicapIndex),
				borderColor: 'rgb(68, 171, 246)',
				backgroundColor: 'rgba(68, 171, 246, 0.2)',
				fill: false,
				tension: 0.2,
			},
		],
	};

	const options: ChartOptions<'line'> = {
		responsive: true,
		plugins: {
			datalabels: {
				display: false,
			},
			legend: {
				display: false,
				position: 'top',
			},
			title: {
				display: false,
				text: 'Scores',
			},
			tooltip: {
				callbacks: {
					title: function () {
						return '';
					},
					label: function () {
						return '';
					},
					beforeLabel: function (context) {
						const round = reversedRounds[context.dataIndex];
						return `${round.courseName}`;
					},
					afterLabel: function (context) {
						const round = reversedRounds[context.dataIndex];
						return `Gross: ${round.gross}`;
					},
				},
				labelColor: function () {
					return {
						borderColor: 'rgba(0, 0, 0, 0)',
						backgroundColor: 'rgba(0, 0, 0, 0)',
					};
				},
				labelTextColor: function () {
					return 'red';
				},
			},
		},
		scales: {
			x: {
				title: {
					display: true,
					text: 'Rounds',
				},
			},
			y: {
				title: {
					display: false,
					text: 'Gross',
				},
				beginAtZero: false,
				min: adjustedMinValue,
				max: rounds && rounds.length > 0 ? undefined : 10,
			},
		},
		elements: {
			point: {
				radius: 3,
			},
		},
	};

	return <Line data={data} options={options} />;
};

export default LineChart;
