import { Lie, Club, Direction } from './enums';

export const lieOptions = [
	{ label: 'Tee', value: Lie.TEE },
	{ label: 'Fairway', value: Lie.FAIRWAY },
	{ label: 'Rough', value: Lie.ROUGH },
	{ label: 'Deep Rough', value: Lie.DEEP_ROUGH },
	{ label: 'Green', value: Lie.GREEN },
	{ label: 'Bunker', value: Lie.BUNKER },
	{ label: 'Fringe', value: Lie.FRINGE },
];

export const clubOptions = [
	//{ label: 'None', value: Club.NONE },
	{ label: 'Driver', value: Club.DRIVER },
	{ label: '3 Wood', value: Club.THREE_WOOD },
	{ label: '5 Wood', value: Club.FIVE_WOOD },
	{ label: '7 Wood', value: Club.SEVEN_WOOD },
	{ label: 'Hybrid', value: Club.HYBRID },
	{ label: '2 Iron', value: Club.TWO_IRON },
	{ label: '3 Iron', value: Club.THREE_IRON },
	{ label: '4 Iron', value: Club.FOUR_IRON },
	{ label: '5 Iron', value: Club.FIVE_IRON },
	{ label: '6 Iron', value: Club.SIX_IRON },
	{ label: '7 Iron', value: Club.SEVEN_IRON },
	{ label: '8 Iron', value: Club.EIGHT_IRON },
	{ label: '9 Iron', value: Club.NINE_IRON },
	{ label: 'Pitching Wedge', value: Club.PITCHING_WEDGE },
	{ label: 'Gap Wedge', value: Club.GAP_WEDGE },
	{ label: 'Sand Wedge', value: Club.SAND_WEDGE },
	{ label: 'Lob Wedge', value: Club.LOB_WEDGE },
	{ label: '60 Degree Wedge', value: Club.SIXTY_DEGREE_WEDGE },
	{ label: 'Putterer', value: Club.PUTTER },
];

export const directionOptions = [
	{ label: 'Hit', value: Direction.HIT },
	{ label: 'Left', value: Direction.LEFT },
	{ label: 'Right', value: Direction.RIGHT },
	{ label: 'Long', value: Direction.LONG },
	{ label: 'Short', value: Direction.SHORT },
];
