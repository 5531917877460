import React from 'react';
import StatsNavbar from '../Components/stats/StatsNavbar';
import { Outlet } from 'react-router-dom';

const StatsLayout = () => {
	return (
		<div className='min-h-full w-full flex flex-col'>
			<StatsNavbar />
			<div className='py-8 flex flex-1'>
				<Outlet />
			</div>
		</div>
	);
};

export default StatsLayout;
