import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export const combineClasses = (...classes) => {
	return classes.filter(Boolean).join(' ');
};

export const formatRoundToPar = (score) => {
	if (score > 0) {
		return `+${score}`;
	} else if (score < 0) {
		return `-${score}`;
	} else {
		return 'E';
	}
};

export const isNullOrWhitespace = (str) => {
	return !str || str.trim().length === 0;
};

export const getRandomImage = () => {
	const images = [
		'golf-course-1.jpg',
		'golf-course-2.jpg',
		'golf-course-3.jpg',
		'golf-course-4.jpg',
		'golf-course-5.jpg',
		'golf-course-6.jpg',
		'golf-course-7.jpg',
		'golf-course-8.jpg',
	];
	const randomIndex = Math.floor(Math.random() * images.length);
	return images[randomIndex];
};

export const cn = (...inputs) => {
	return twMerge(clsx(inputs));
};
