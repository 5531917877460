import React from 'react';
import { useSpring, animated } from 'react-spring';

const AnimatedDiv = ({
	children,
	className = '',
	style = {},
	animationProps = {},
}) => {
	const animation = useSpring({
		to: { opacity: 1 },
		from: { opacity: 0 },
		reset: false,
		delay: 200,
		...animationProps,
	});
	return (
		<animated.div style={{ ...animation, ...style }} className={className}>
			{children}
		</animated.div>
	);
};

export default AnimatedDiv;
