import axiosClient from '../Shared/ApiClient';

const handleRequest = async (url) => {
	try {
		const response = await axiosClient.get(url);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 404) {
			const errors = error.response.data.errors;
			const roundNotFoundError = errors.find(
				(err) => err.code === 'Round.NotFound'
			);

			if (roundNotFoundError) {
				console.log(roundNotFoundError);
				throw new Error(roundNotFoundError.code);
			}
		}
		console.error(error);
		throw error;
	}
};

export const getOverview = () => handleRequest('/Stats/Overview');

export const getDrivingStats = () => handleRequest('/Stats/Driving');

export const getApproachStats = () => handleRequest('/Stats/Approach');

export const getAroundTheGreenStats = () =>
	handleRequest('/Stats/AroundTheGreen');

export const getPuttingStats = () => handleRequest('/Stats/Putting');

export const getScoringStats = () => handleRequest('/Stats/Scoring');
