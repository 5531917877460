import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../Shared/axiosBaseQuery';
import { NewRoundRequest } from '../../models/Round/newRoundRequest';
import { roundApi } from '../../api/round.api';
import RoundDetailsDto from '../../models/Round/RoundDetailsDto';
import { GET, PUT } from '../shared/httpMethods';
import RoundSummary from './types/RoundSummary';
import GetRoundResponse from './types/GetRoundResponse';
import UpdateScoreRequest from './types/UpdateScoreRequest';
import GetScoresForGroup from './types/GetScoresForGroupResponse';
import UpdateRoundStatusRequest from './types/UpdateRoundStatusRequest';

export const roundApiSlice = createApi({
	reducerPath: 'roundApi',
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		fetchRounds: builder.query<RoundDetailsDto[], void>({
			queryFn: () => roundApi.fetchAll(),
		}),
		createRound: builder.mutation<string, NewRoundRequest>({
			queryFn: (data) => roundApi.create(data),
			// providesTags(result, error, courseId) {
			// 	return [{ type: 'Course', courseId }];
			// },
		}),
		getRoundById: builder.query<GetRoundResponse, string>({
			query: (id: string) => ({
				url: `rounds/${id}`,
				method: GET,
			}),
		}),
		getRoundsForGroup: builder.query<GetScoresForGroup[], string>({
			query: (groupId: string) => ({
				url: `rounds/group/${groupId}`,
				method: GET,
			}),
			keepUnusedDataFor: 0,
		}),
		getUpcomingRounds: builder.query<RoundSummary[], void>({
			query: () => ({
				url: 'rounds/upcomingrounds',
				method: GET,
			}),
		}),
		updateScoreForHole: builder.mutation<void, UpdateScoreRequest>({
			query: (scoreUpdates) => ({
				url: 'rounds',
				method: PUT,
				data: scoreUpdates,
			}),
		}),
		updateRoundStatus: builder.mutation<void, UpdateRoundStatusRequest>({
			query: (updateRequest) => ({
				url: 'rounds/updatestatus',
				method: PUT,
				data: updateRequest,
			}),
		}),
	}),
});

export const {
	useCreateRoundMutation,
	useFetchRoundsQuery,
	useGetRoundByIdQuery,
	useGetRoundsForGroupQuery,
	useGetUpcomingRoundsQuery,
	useUpdateScoreForHoleMutation,
	useUpdateRoundStatusMutation,
} = roundApiSlice;
