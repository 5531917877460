import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../Shared/axiosBaseQuery';
import NewScorecardResponse from './models/NewScorecardResponse';
import { GET } from '../shared/httpMethods';

export const scorecardApiSlice = createApi({
	reducerPath: 'scorecardApi',
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		getNewScorecard: builder.query<NewScorecardResponse, string>({
			query: (layoutId: string) => ({
				url: `scorecard/${layoutId}`,
				method: GET,
			}),
		}),
	}),
});

export const { useGetNewScorecardQuery } = scorecardApiSlice;
