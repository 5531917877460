import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const BarChart = ({
	parThreeScoringAverage,
	parFourScoringAverage,
	parFiveScoringAverage,
}) => {
	const data = {
		labels: ['Par 3', 'Par 4', 'Par 5'],
		datasets: [
			{
				data: [
					parThreeScoringAverage,
					parFourScoringAverage,
					parFiveScoringAverage,
				],
				backgroundColor: [
					'rgba(75, 192, 192, 0.2)',
					'rgba(153, 102, 255, 0.2)',
					'rgba(255, 159, 64, 0.2)',
				],
				borderColor: [
					'rgba(75, 192, 192, 1)',
					'rgba(153, 102, 255, 1)',
					'rgba(255, 159, 64, 1)',
				],
				borderWidth: 1,
			},
		],
	};

	const options = {
		indexAxis: 'y',
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
				text: 'Recovery Stats',
			},
		},
		scales: {
			x: {
				beginAtZero: true,
				//max: 100, // Percentages are out of 100
			},
		},
	};
	return <Bar data={data} options={options} />;
};

export default BarChart;
