import { motion, Variants } from 'framer-motion';
import { ComponentProps } from 'react';

type PropsT = {
	children: React.ReactNode;
	direction: number;
} & ComponentProps<'div'>;

const AnimatedDiv = ({ children, className, direction }: PropsT) => {
	const anim = (variants: Variants, custom: number) => {
		return {
			initial: 'initial',
			animate: 'enter',
			exit: 'exit',
			variants,
			custom,
		};
	};

	const nextStep: Variants = {
		initial: (direction) => ({
			// scale: 0.8,
			// opacity: 0,
			x: `${100 * direction}%`,
		}),

		enter: {
			scale: 1,
			// opacity: 1,
			x: '0%',
			transition: {
				// ease: [0.76, 0, 0.24, 1],
				duration: 0.3,
			},
		},

		exit: (direction) => ({
			// opacity: 0,
			// scale: 0.8,
			x: `${-100 * direction}%`,
			transition: {
				// ease: [0.76, 0, 0.24, 1],
				duration: 0.3,
			},
		}),
	};

	return <motion.div {...anim(nextStep, direction)} className={className}>{children}</motion.div>;
};

export default AnimatedDiv;
