import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Text } from '../ui/Text';
import NavLinks from './NavLinks';
import { FaArrowLeft } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { IoIosChatbubbles, IoIosNotifications } from 'react-icons/io';

interface MobileDrawerProps {
	open: boolean;
	toggleDrawer: () => void;
}

const MobileDrawer: React.FC<MobileDrawerProps> = ({ open, toggleDrawer }) => {
	return (
		<AnimatePresence>
			{open && (
				<>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 0.5 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.3 }}
						className='fixed top-0 left-0 w-full h-full bg-black z-10'
						onClick={toggleDrawer}
					/>
					<motion.div
						initial={{ x: '-100%' }}
						animate={{ x: 0 }}
						exit={{ x: '-100%' }}
						transition={{ duration: 0.3 }}
						className='flex flex-col fixed top-0 left-0 w-5/6 bg-white h-full z-50 drawer-content rounded-r-lg'
					>
						<FaArrowLeft
							className=' absolute text-lg text-gray-700 left-4 top-4 cursor-pointer'
							onClick={toggleDrawer}
						/>
						<div className='flex flex-col items-center justify-center space-y-4 border-b border-gray-200 bg-app-background px-4 py-8 rounded-tr-lg'>
							{/* <span className='bg-gray-300 outline outline-2 outline-offset-2 outline-primary-blue rounded-full p-5 text-gray-600 font-medium text-3xl'>
							CS
						</span> */}
							<img
								src='/src/assets/images/profile.jpg'
								alt=''
								className='w-2/5 rounded-full object-cover outline outline-2 outline-offset-2 outline-primary-blue'
							/>
							<div className='flex flex-col items-center'>
								<Text variant='h6'>Ciaran Selwyn</Text>
								<Text variant='fine1'>
									ciaranselwyn@yahoo.co.nz
								</Text>
							</div>
						</div>
						<div className='flex flex-col items-start justify-between flex-grow'>
							<NavLinks />
							<div className='flex items-center p-4'>
								<FontAwesomeIcon
									icon={faArrowRightFromBracket}
									className='text-gray-600 text-base mr-3'
								/>
								<Text>Sign out</Text>
							</div>
						</div>
					</motion.div>
				</>
			)}
		</AnimatePresence>
	);
};

export default MobileDrawer;
