import { Text } from '../../../Components/ui/Text';
import { useGetFriendsListQuery } from '../../../features/friend/friendApiSlice';

const FriendsListComponent = () => {
	const { data: friendsList, isLoading } = useGetFriendsListQuery();

	return (
		<div className='flex flex-col space-y-8 py-4'>
			{friendsList?.map((friend, index) => (
				<div
					key={index}
					className='w-full flex flex-row justify-between items-center'
				>
					<div>
						{friend.friendProfileImageUrl && (
							<img
								src={friend.friendProfileImageUrl}
								alt={friend.friendFullName}
								className='w-10 h-10 rounded-full'
							/>
						)}
						<Text variant='body1' className='font-medium'>
							{friend.friendFullName}
						</Text>
					</div>
				</div>
			))}
		</div>
	);
};

export default FriendsListComponent;
