import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetLeaderboardQuery } from '../../features/tournament/tournamentApiSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import { Text } from '../../Components/ui/Text';

const ViewLeaderboardPage = () => {
	const navigate = useNavigate();

	const { tournamentId } = useParams<{ tournamentId: string }>();

	const {
		data: leaderboardData,
		isLoading: isleaderboardLoading,
		isError: isLeaderboardError,
	} = useGetLeaderboardQuery(tournamentId ?? skipToken);

	if (isleaderboardLoading) {
		return <div>Loading...</div>;
	}

	if (isLeaderboardError || !leaderboardData) {
		return <div>Error loading leaderboard.</div>;
	}

	console.log(leaderboardData);

	const formatScoreToPar = (score: number): string | number => {
		if (score > 0) return `+${score}`;
		if (score === 0) return 'E';
		return score;
	};

	return (
		<div className='min-h-full'>
			<div className='fixed w-full top-0 left-0 z-10 h-16 flex items-center border-b p-4 shadow-sm bg-white space-x-5'>
				<FontAwesomeIcon
					icon={faChevronLeft}
					className='text-2xl text-gray-700'
					onClick={() => navigate(-1)}
				/>
				<Text variant='h6' className='font-medium'>
					Leaderboard
				</Text>
			</div>
			<div className='pt-20 px-4'>
				<table className='w-full divide-y divide-gray-200'>
					<thead>
						<tr>
							<th className='py-4 text-left text-sm font-semibold text-gray-700'>
								Pos
							</th>
							<th className='py-4 text-left text-sm font-semibold text-gray-700'>
								Player
							</th>
							<th className='py-4 text-center text-sm font-semibold text-gray-700'>
								R1
							</th>
							<th className='py-4 text-center text-sm font-semibold text-gray-700'>
								Thru
							</th>
							<th className='py-4 text-center text-sm font-semibold text-gray-700'>
								Total
							</th>
						</tr>
					</thead>
					<tbody>
						{leaderboardData.leaderboard.map((player, index) => (
							<tr
								key={player.userId}
								className={
									index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
								}
							>
								<td className='py-4 text-sm text-gray-700'>
									{player.position}
								</td>
								<td className='py-4 text-sm text-gray-700'>
									{player.playerName}
								</td>
								<td className='py-4 text-sm text-center text-gray-700'>
									{formatScoreToPar(player.scoreToPar)}
								</td>
								<td className='py-4 text-sm text-center text-gray-700'>
									{player.holesPlayed === 18
										? 'F'
										: player.holesPlayed}
								</td>
								<td className='py-4 text-sm text-center text-gray-700'>
									{player.totalScore}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default ViewLeaderboardPage;
