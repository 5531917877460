import axios from 'axios';

const BASE_URL = 'http://170.64.132.4:8080/api';
//const BASE_URL = 'http://localhost:5153/api';

const axiosClient = axios.create({
	baseURL: BASE_URL,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});

export default axiosClient;
