/* eslint-disable react-refresh/only-export-components */
import React from 'react';
import { getPuttingStats } from '../../Services/statsService';
import HorizontalBarChart from '../ui/charts/HorizontalBarChart';
import StatDisplay from '../ui/displays/StatDisplay';
import PieChart from '../ui/charts/PieChart';
import withStats from './withStats';

const PuttingStats = ({ stats }) => {
	const labels = [
		`HoleOuts ${stats.holeOutsPerRound}%`,
		`1 Putts ${stats.onePuttsPerRound}%`,
		`2 Putts ${stats.twoPuttsPerRound}%`,
		`3+ Putts ${stats.threePuttsPerRound}%`,
	];

	const puttingData = [
		stats.holeOutsPerRound,
		stats.onePuttsPerRound,
		stats.twoPuttsPerRound,
		stats.threePuttsPerRound,
	];

	const backgroundColors = [
		'rgba(255, 206, 86, 0.2)',
		'rgba(54, 162, 235, 0.2)',
		'rgba(75, 192, 192, 0.2)',
		'rgba(255, 99, 132, 0.2)',
	];

	const borderColors = [
		'rgba(255, 206, 86, 1)',
		'rgba(54, 162, 235, 1)',
		'rgba(75, 192, 192, 1)',
		'rgba(255, 99, 132, 1)',
	];

	return (
		<div className='flex w-full flex-col space-y-6'>
			<div className='w-full flex flex-row space-x-6'>
				<div className='w-1/2'>
					<div className='w-full h-full space-y-4 p-8 border border-gray-300 rounded'>
						<h3 className='text-xl text-gray-600 font-medium tracking-wider'>
							Putting
						</h3>
						<HorizontalBarChart
							averages={{
								averagePuttsPerHole: stats.puttingAverage,
								averagePuttsPerGIR: stats.puttingAverageGir,
							}}
						/>
					</div>
				</div>
				<div className='w-1/2'>
					<div className='w-full space-y-4 p-8 border border-gray-300 rounded'>
						<h3 className='text-xl text-gray-600 font-medium tracking-wider'>
							Breakdown
						</h3>
						<PieChart
							labels={labels}
							data={puttingData}
							backgroundColors={backgroundColors}
							borderColors={borderColors}
						/>
					</div>
				</div>
			</div>
			<div className='w-full flex flex-row space-x-6'>
				<div className='w-1/2'>
					<StatDisplay
						value={stats.puttsPerRound}
						label='Putts per Round'
					/>
				</div>
				<div className='w-1/2'>
					<StatDisplay
						value={`${stats.birdieConversion}%`}
						label='Birdie Conversion'
					/>
				</div>
			</div>
		</div>
	);
};

export default withStats(PuttingStats, getPuttingStats);
