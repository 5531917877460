import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text } from '../../../Components/ui/Text';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import TournamentsList from './components/TournamentsList';
import { useGetTournamentsForUserQuery } from '../../../features/tournament/tournamentApiSlice';
import TournamentSummary from '../../../features/tournament/models/TournamentSummary';

const TABS = {
	PLAYING: 'playing',
	HOSTING: 'hosting',
};

const TournamentsOverviewPage = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const currentPath = location.pathname;
	const activeTab = currentPath.includes('/hosting')
		? TABS.HOSTING
		: TABS.PLAYING;

	const { data: tournaments } = useGetTournamentsForUserQuery();
	console.log(tournaments);

	const handleTabClick = (tab: string) => {
		if (tab === TABS.PLAYING) {
			navigate('/my-tournaments/playing');
		} else if (tab === TABS.HOSTING) {
			navigate('/my-tournaments/hosting');
		}
	};

	const filteredTournaments = tournaments?.filter((t) =>
		activeTab === TABS.PLAYING ? !t.myTournament : t.myTournament
	);

	const now = new Date();
	const upcomingTournaments = filteredTournaments?.filter(
		(t) => new Date(t.startDate) >= now
	);
	const pastTournaments = filteredTournaments?.filter(
		(t) => new Date(t.startDate) < now
	);

	return (
		<div className='p-4'>
			<div className='flex justify-between mb-4'>
				<Text variant='h2'>My Tournaments</Text>
				<span className='flex items-center justify-center w-10 h-10 rounded-full bg-muted-gray'>
					<FontAwesomeIcon
						icon={faBell}
						className='h-4 w-4 text-gray-700'
					/>
				</span>
			</div>
			<div className='flex flex-row space-x-4'>
				<span
					className={`border px-4 py-2 rounded-3xl cursor-pointer ${
						activeTab === TABS.PLAYING
							? 'bg-primary-blue-darker'
							: 'bg-muted-gray'
					}`}
					onClick={() => handleTabClick(TABS.PLAYING)}
				>
					<Text
						variant='body2'
						className='font-medium'
						color={activeTab === TABS.PLAYING ? 'white' : 'dark'}
					>
						Playing
					</Text>
				</span>
				<span
					className={`border px-4 py-2 rounded-3xl cursor-pointer ${
						activeTab === TABS.HOSTING
							? 'bg-primary-blue-darker'
							: 'bg-muted-gray'
					}`}
					onClick={() => handleTabClick(TABS.HOSTING)}
				>
					<Text
						variant='body2'
						className='font-medium'
						color={activeTab === TABS.HOSTING ? 'white' : 'dark'}
					>
						Hosting
					</Text>
				</span>
			</div>
			<div className='mt-4'>
				<Text variant='body2' className='font-semibold'>
					Upcoming ({upcomingTournaments?.length})
				</Text>
				{upcomingTournaments && upcomingTournaments.length > 0 ? (
					<TournamentsList
						tournaments={upcomingTournaments as TournamentSummary[]}
					/>
				) : (
					<Text variant='body2'>No upcoming tournaments.</Text>
				)}
			</div>
			<div className='mt-4'>
				<Text variant='body2' className='font-semibold'>Completed ({pastTournaments?.length})</Text>
				{pastTournaments && pastTournaments.length > 0 ? (
					<TournamentsList
						tournaments={pastTournaments as TournamentSummary[]}
					/>
				) : (
					<Text variant='body2'>No past tournaments.</Text>
				)}
			</div>
		</div>
	);
};

export default TournamentsOverviewPage;
