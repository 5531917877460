import React from 'react';
import Select from 'react-select';

const Dropdown = ({ placeholder, options, onChange, value, styles, className }) => {
	console.log(options);
	console.log(value);
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			borderRadius: '0.25rem',
			borderColor: state.isFocused ? '#44ABF6' : 'rgb(209 213 219)', // Change border color based on focus
			boxShadow: state.isFocused ? 'none' : 'none', // Add box shadow for focus
			'&:hover': {
				borderColor: 'none', // Change border color on hover
			},
			...styles,
		}),
		indicatorSeparator: (provided) => ({
			display: 'none', // Remove the separator line
		}),
	};

	const handleChange = (selectedOption) => {
		onChange(selectedOption);
	};

	const tt = {
		value: 'custom',
		label: 'Select it',
	};

	return (
		<Select
			styles={customStyles}
			value={options.find((o) => o.value === value)}
			className={className}
			options={options}
			placeholder={placeholder}
			onChange={handleChange}
		/>
	);
};

export default Dropdown;
