import { format } from 'date-fns';
import { Text } from '../../../../Components/ui/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCalendar,
	faGolfBallTee,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import TournamentSummary from '../../../../features/tournament/models/TournamentSummary';

type CardProps = {
	tournament: TournamentSummary;
	onClick: (tournamentId: string) => void;
};

const TournamentListItem = ({ tournament, onClick }: CardProps) => {
	return (
		<div
			className='border rounded-md p-2 flex flex-col justify-between'
			onClick={() => onClick(tournament.tournamentId)}
		>
			<div className='space-y-3'>
				<div className='space-y-2'>
					<div>
						<Text variant='h5' color='default'>
							{tournament.tournamentName}
						</Text>
					</div>
					<div className='flex flex-col space-y-1'>
						<div className='flex flex-row space-x-2 items-baseline'>
							<FontAwesomeIcon
								icon={faCalendar}
								className='text-xs text-gray-500'
							/>
							<Text variant='fine1' color='light'>
								{format(
									tournament.startDate,
									'EEE do MMMM yyyy'
								)}
							</Text>
						</div>
						<div className='flex flex-row space-x-2 items-baseline'>
							<FontAwesomeIcon
								icon={faGolfBallTee}
								className='text-xs text-gray-500'
							/>
							<Text variant='fine1' color='light'>
								{tournament.numberOfRounds} Rounds
							</Text>
						</div>
						<div className='flex flex-row space-x-2 items-baseline'>
							<FontAwesomeIcon
								icon={faUser}
								className='text-xs text-gray-500'
							/>
							<Text variant='fine1' color='light'>
								{tournament.playersEntered} Players entered
							</Text>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TournamentListItem;
