import { ChangeEvent, forwardRef } from 'react'

type FileUploadProps = {
  accept: string;
  onFileSelect: (file: File | null) => void;
}

const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(({accept, onFileSelect}, ref) => {
  
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    onFileSelect(file);
  };
  
  return (
    <>
      <input
        type="file"
        accept={accept}
        onChange={handleFileChange}
        className='hidden'
        id="file-upload"
        ref={ref}
      />
    </>
  );
});

export default FileUpload