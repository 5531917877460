import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../../Components/ui/Text';
import {
	faChevronRight,
	faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../Components/ui/Button';
import { motion } from 'framer-motion';

const SetupModal = () => {
	const [currentPage, setCurrentPage] = useState(0);
	const navigate = useNavigate();

	const pages = [
		{
			title: 'Play Now',
			description: 'Quickly setup a game for you and your friends',
			route: 'new-round',
		},
		{
			title: 'Tournament',
			description:
				'Setup a tournament and invite friends and other players to join',
			route: '/new-tournament',
		},
	];

	const handleNext = () => {
		setCurrentPage((prevPage) => (prevPage + 1) % pages.length);
	};

	const handlePrevious = () => {
		setCurrentPage(
			(prevPage) => (prevPage - 1 + pages.length) % pages.length
		);
	};

	const handleStart = () => {
		navigate(pages[currentPage].route);
	};

	return (
		<div className='flex flex-row items-center justify-between w-full'>
			<FontAwesomeIcon
				icon={faChevronLeft}
				className='text-gray-500 text-3xl cursor-pointer'
				onClick={handlePrevious}
			/>
			<motion.div
				key={currentPage}
				initial={{ opacity: 0, x: -100 }}
				animate={{ opacity: 1, x: 0 }}
				exit={{ opacity: 0, x: 100 }}
				transition={{ type: 'spring', stiffness: 300, damping: 30 }}
				className='flex flex-col text-center items-center'
			>
				<Text variant='h2' className=''>
					{pages[currentPage].title}
				</Text>
				<Text variant='fine1' className='w-40 text-center'>
					{pages[currentPage].description}
				</Text>
			</motion.div>
			<FontAwesomeIcon
				icon={faChevronRight}
				className='text-gray-500 text-3xl cursor-pointer'
				onClick={handleNext}
			/>
			<div className='fixed bottom-0 left-0 w-full p-4'>
				<Button className='w-full h-12' onClick={handleStart}>
					Start
				</Button>
			</div>
		</div>
	);
};

export default SetupModal;
