import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaCircle } from 'react-icons/fa';

const StatsNavbar = () => {
	return (
		<div className='w-full flex justify-between items-center h-16 ease-in-out duration-100 z-50'>
			<NavLink
				to='overview'
				className={({ isActive }) =>
					isActive
						? 'text-gray-700 font-bold tracking-wider border-b border-gray-400'
						: 'hover:text-gray-300 text-gray-700 font-medium tracking-wider'
				}
			>
				<span>Overview</span>
			</NavLink>
			<NavLink
				to='driving'
				className={({ isActive }) =>
					isActive
						? 'text-gray-700 font-bold tracking-wider border-b border-gray-400'
						: 'hover:text-gray-300 text-gray-700 font-medium tracking-wider'
				}
			>
				<span>Driving</span>
			</NavLink>
			<NavLink
				to='approach'
				className={({ isActive }) =>
					isActive
						? 'text-gray-700 font-bold tracking-wider border-b border-gray-400'
						: 'hover:text-gray-300 text-gray-700 font-medium tracking-wider'
				}
			>
				<span>Approach</span>
			</NavLink>
			<NavLink
				to='around-the-green'
				className={({ isActive }) =>
					isActive
						? 'text-gray-700 font-bold tracking-wider border-b border-gray-400'
						: 'hover:text-gray-300 text-gray-700 font-medium tracking-wider'
				}
			>
				<span>Around the Green</span>
			</NavLink>
			<NavLink
				to='putting'
				className={({ isActive }) =>
					isActive
						? 'text-gray-700 font-bold tracking-wider border-b border-gray-400'
						: 'hover:text-gray-300 text-gray-700 font-medium tracking-wider'
				}
			>
				<span>Putting</span>
			</NavLink>
			<NavLink
				to='scoring'
				className={({ isActive }) =>
					isActive
						? 'text-gray-700 font-bold tracking-wider border-b border-gray-400'
						: 'hover:text-gray-300 text-gray-700 font-medium tracking-wider'
				}
			>
				<span>Scoring</span>
			</NavLink>
		</div>
	);
};

export default StatsNavbar;
