import { createSlice } from '@reduxjs/toolkit';
import { signUpUser, loginUser } from '../features/auth/authActions';

const initialState = {
	token: null,
	userId: null,
	username: null,
	initials: null,
	status: 'idle',
	error: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			state.token = null;
			state.userId = null;
			state.username = null;
			state.initials = null;
			state.status = 'idle';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(signUpUser.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(signUpUser.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.token = action.payload;
			})
			.addCase(signUpUser.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(loginUser.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(loginUser.fulfilled, (state, action) => {
				state.status = 'succeeded';
				console.log('saving token to state');
				state.token = action.payload.token;
				state.userId = action.payload.userId;
				state.username = action.payload.username;
				state.initials = action.payload.initials;
			})
			.addCase(loginUser.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			});
	},
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
