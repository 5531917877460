import { faChevronLeft, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../../Components/ui/Text';
import { isNullOrWhitespace } from '../../../utils/utils';
import useMyDebounce from '../../../Hooks/useMyDebounce';
import { useEffect, useState } from 'react';
import {
	useAutoCompleteUserSearchQuery,
	useGetSentFriendRequestsQuery,
	useSendFriendRequestMutation,
} from '../../../features/friend/friendApiSlice';

const FriendsSearchPage = () => {
	const navigate = useNavigate();
	const [searchTerm, setSearchTerm] = useState<string>('');

	const [sendFriendRequest, { isLoading, isError, data }] =
		useSendFriendRequestMutation();

	const debouncedSearchTerm = useMyDebounce<string>(searchTerm, 300);
	const { data: users, isFetching } = useAutoCompleteUserSearchQuery(
		debouncedSearchTerm,
		{
			skip: isNullOrWhitespace(debouncedSearchTerm),
		}
	);

	const [sentRequests, setSentRequests] = useState<string[]>([]);
	const {
		data: sentFriendRequestsData,
		isLoading: sentRequestLoading,
		isError: sentRequestsError,
	} = useGetSentFriendRequestsQuery();

	useEffect(() => {
		if (sentFriendRequestsData) {
			const sentRequestsUserIds = sentFriendRequestsData?.map(
				(req) => req.toUserId
			);
			if (sentRequestsUserIds) {
				setSentRequests(sentRequestsUserIds);
			}
		}
	}, [sentFriendRequestsData]);

	const showNoResultsMessage =
		isNullOrWhitespace(debouncedSearchTerm) || !users?.length;

	const handleSearchSubmit = (
		event: React.KeyboardEvent<HTMLInputElement>
	) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			(event.target as HTMLInputElement).blur();
			console.log('Search initiated with term:', searchTerm);
		}
	};

	const handleSendFriendRequest = async (toUserId: string) => {
		try {
			await sendFriendRequest(toUserId).unwrap();
			setSentRequests((prevSentReq) => [...prevSentReq, toUserId]);
		} catch (error) {
			console.error('Error sending friend request:', error);
		}
	};

	return (
		<div className='flex justify-center h-full'>
			<div className='fixed w-full top-0 left-0 z-10 flex justify-between items-center border-b p-4 bg-white space-x-4'>
				<FontAwesomeIcon
					icon={faChevronLeft}
					className='text-2xl text-gray-700'
					onClick={() => navigate(-1)}
				/>
				<div className='flex items-center px-4 border w-full h-10 rounded-3xl'>
					<input
						type='search'
						placeholder='Search friends'
						className='w-full text-gray-600 tracking-wider outline-none focus:outline-none border-none focus:border-none h-8 placeholder:tracking-widest'
						onChange={(e) => setSearchTerm(e.target.value)}
						onKeyDown={handleSearchSubmit}
					/>
				</div>
			</div>
			<div className='pt-[4.5rem] w-full'>
				{showNoResultsMessage ? (
					<div className='pt-28 flex flex-col items-center space-y-4'>
						<span className='w-28 h-28 rounded-full flex items-center justify-center bg-primary-blue-darker'>
							<FontAwesomeIcon
								icon={faSearch}
								className='text-4xl text-slate-200'
							/>
						</span>
						<div className='flex flex-col items-center w-2/3'>
							<Text
								variant='body1'
								className='text-center font-bold'
							>
								Find friends
							</Text>
							<Text variant='fine1' className='text-center'>
								Search a friend or someone you may know and tee
								up a time with them.
							</Text>
						</div>
					</div>
				) : (
					<div className='p-4 w-full flex flex-col items-start space-y-4'>
						{users?.map((user, index) => (
							<div
								key={index}
								className='w-full flex flex-row justify-between'
							>
								<div>
									{user.profileImageUrl && (
										<img
											src={user.profileImageUrl}
											alt={user.fullName}
											className='w-10 h-10 rounded-full'
										/>
									)}
									<Text
										variant='body1'
										className='font-medium'
									>
										{user.fullName}
									</Text>
								</div>
								<button
									className={`px-3 py-1 rounded-3xl w-20 ${
										sentRequests.includes(user.userId)
											? 'bg-white outline outline-1 outline-primary-blue-darker'
											: 'bg-primary-blue-darker outline-none'
									}`}
									onClick={() =>
										handleSendFriendRequest(user.userId)
									}
									disabled={sentRequests.includes(user.userId)}
								>
									{sentRequests.includes(user.userId) ? (
										<Text variant='body2'>
											Sent
										</Text>
									) : (
										<Text variant='body2' color='white'>
											Add
										</Text>
									)}
								</button>
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default FriendsSearchPage;
