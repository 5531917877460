import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Text } from "../../../../Components/ui/Text";
import RoundSummary from "../../../../features/round/types/RoundSummary"
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { format } from 'date-fns';

type RoundListProps = {
  rounds: RoundSummary[];
  onRoundClick: (roundId: string) => void;
}

const RoundList = ({rounds, onRoundClick}: RoundListProps) => {
  return (
    <div className='space-y-2 mt-1'>
					{rounds?.map((round: RoundSummary, index: number) => (
						<div
							key={index}
							className='border rounded-md p-2 flex flex-col justify-between'
							onClick={() => onRoundClick(round.roundId)}
						>
							<div className='space-y-3'>
								<div className='space-y-2'>
									<div>
										<Text variant='h6' color='dark'>
											{round.courseName}
										</Text>
									</div>
									<div className='flex flex-col space-y-1'>
										<div className='flex flex-row space-x-2 items-baseline'>
											<FontAwesomeIcon
												icon={faCalendar}
												className='text-xs text-gray-500'
											/>
											<Text variant='fine1' color='light'>
												{format(
													round.roundDate,
													'EEE do MMMM yyyy'
												)}
											</Text>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
  )
}

export default RoundList