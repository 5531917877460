import { useNavigate } from 'react-router-dom';
import { useSearchTournamentsQuery } from '../../../features/tournament/tournamentApiSlice';
import TournamentCard from './TournamentCard';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ExploreTournaments = () => {
	const navigate = useNavigate();
	const { data: tournaments, isLoading } = useSearchTournamentsQuery();
	console.log(tournaments);

	const handleTournamentClick = (tournamentId: string) => {
		navigate(`/tournament/${tournamentId}`);
	};

	if (isLoading) {
		// Display skeletons while loading
		return (
			<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
				{Array.from({ length: 8 }).map((_, index) => (
					<div
						key={index}
						className='border rounded-md p-4 aspect-video flex flex-col justify-between'
					>
						<Skeleton height={20} width={`30%`} />
						<Skeleton height={150} />
						<Skeleton height={20} width={`50%`} />
						<Skeleton height={40} width={`80%`} />
					</div>
				))}
			</div>
		);
	}

	return (
		<div className='space-y-4'>
			{tournaments?.map((tournament, index) => (
				<TournamentCard
					key={index}
					tournament={tournament}
					onClick={handleTournamentClick}
				/>
			))}
		</div>
	);
};

export default ExploreTournaments;
