import React from 'react';

function StatDisplay({ value, label }) {
	return (
		<div className='flex flex-col justify-center items-center w-full p-8 border border-gray-300 rounded space-y-4'>
			<span className='text-6xl text-teal-600 tracking-wide'>
				{value}
			</span>
			<h3 className='text-lg text-gray-600 font-medium tracking-wider'>
				{label}
			</h3>
		</div>
	);
}

export default StatDisplay;
//{value !== null ? `${value}%` : 'N/A'}