import { colors, createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#44ABF6',
			dark: '#4496F6',
			contrastText: '#fff',
		},
		background: {
			default: '#F4F6FA',
		},
		text: {
			primary: 'rgb(31 41 55)',
			secondary: 'rgb(55 65 81)',
			light: 'rgb(17 24 39)',
		},
	},
	shape: {
		rounded: '0.25rem',
		roundedMd: '0.375rem',
	},
	typography: {
		allVariants: {
			letterSpacing: '0.08em',
		},
		h5: {
			color: 'rgb(31 41 55)'
		},
		h6: {
			color: 'rgb(55 65 81)'
		},
		subtitle1: {
			color: 'rgb(107 114 128)'
		}
	},
});

export default theme;
