import { NavLink } from 'react-router-dom';
import { Text } from '../ui/Text';
import {
	FontAwesomeIcon,
	FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

type NavLinkProps = {
	to: string;
	label: string;
	icon?: FontAwesomeIconProps['icon'];
	onClick?: () => void;
};

const AppNavLink = ({ to, label, icon, onClick }: NavLinkProps) => {
	return (
		<li>
			<NavLink
				className={({ isActive }) =>
					`flex flex-row items-center justify-between
							${
								isActive
									? 'text-base tracking-widest font-medium text-black cursor-pointer'
									: 'text-base tracking-widest font-normal text-gray-700 hover:text-black hover:font-medium cursor-pointer'
							}`
				}
				to={to}
			>
				<div className='flex flex-row items-center'>
					{icon && (
						<FontAwesomeIcon
							icon={icon}
							className='text-base mr-3 text-gray-600'
						/>
					)}
					<Text>{label}</Text>
				</div>
			</NavLink>
		</li>
	);
};

export default AppNavLink;
