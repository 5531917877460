import axiosClient from '../Shared/ApiClient';

export const createRound = async (courseId, layoutId) => {
	const newRound = {
		courseId: courseId,
		layoutId: layoutId,
		roundDate: new Date().toISOString(),
	};

	try {
		const response = await axiosClient.post('/Rounds', newRound);
		return response.data;
	} catch (error) {
		console.error(error);
	}
};

export const addScoresToRound = async (roundId, roundHoles) => {
	try {
		const request = {
			RoundId: roundId,
			RoundHoles: roundHoles,
		};
		await axiosClient.put('/Rounds', request);
	} catch (error) {
		console.error(error);
	}
};

export const getCourse = async (courseId) => {
	try {
		const url = `Course?courseId=${courseId}`;
		const response = await axiosClient.get(url);

		return response.data;
	} catch (error) {
		console.error(error);
	}
};

export const getRound = async (roundId) => {
	try {
		const url = `Rounds?roundId=${roundId}`;
		const response = await axiosClient.get(url);

		return response.data;
	} catch (error) {
		console.error(error);
	}
};

export const getRoundSummary = async (roundId) => {
	try {
		const response = await axiosClient.get(
			`Rounds/Summary?roundId=${roundId}`
		);
		return response.data;
	} catch (error) {
		console.error(error);
	}
};

export const getAllRounds = async () => {
	try {
		const response = await axiosClient.get('Rounds/All');
		return response.data;
	} catch (error) {
		console.error(error);
	}
};

export const deleteRound = async (roundId) => {
	try {
		const response = await axiosClient.delete(`Rounds?roundId=${roundId}`);
		return response.data;
	} catch (error) {
		console.error(error);
	}
};
