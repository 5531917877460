import { useState } from 'react';
import { motion } from 'framer-motion';

type SwitchProps = {
	options: string[];
	onToggle: (index: number) => void;
	initialIndex?: number;
};

const ToggleTextSwitch = ({
	options,
	onToggle,
	initialIndex = 0,
}: SwitchProps) => {
	const [activeIndex, setActiveIndex] = useState(initialIndex);

	const handleToggle = (index: number) => {
		setActiveIndex(index);
		onToggle(index);
	};

	return (
		<div className='relative flex items-center bg-muted-gray rounded-full justify-between w-full h-12'>
			{/* Animated span that moves between the options */}
			<motion.span
				className='absolute w-1/2 h-10 bg-white rounded-full'
				initial={{ x: 0 }} // Start at the initial position
				animate={{
					x: activeIndex === 0 ? 0 : '100%', // Move fully to the right for the second index
				}}
				transition={{ type: 'spring', stiffness: 300, damping: 30 }}
				style={{
					left: activeIndex === 0 ? '0.25rem' : '-0.25rem', // Adjust left based on the index
				}}
			/>
			{options.map((option, index) => (
				<div
					key={index}
					className='w-1/2 flex items-center justify-center z-10 cursor-pointer'
					onClick={() => handleToggle(index)}
				>
					<span
						className={
							activeIndex === index
								? 'text-primary-blue-darker'
								: ''
						}
					>
						{option}
					</span>
				</div>
			))}
		</div>
	);
};

export default ToggleTextSwitch;
