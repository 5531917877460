import React from 'react';

const NoData = () => {
	return (
		<div className='w-full flex items-center justify-center'>
			<span>You have not entered any rounds yet</span>
		</div>
	);
};

export default NoData;
