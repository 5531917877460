import imageCompression from 'browser-image-compression';

export const MAX_IMAGE_SIZE_BYTES = 250 * 1024; // 250 KB
export const VALID_IMAGE_TYPES = ['image/jpeg', 'image/png'];

interface ProcessImageOptions {
	maxWidth: number;
}

export const processImage = async (
	file: File,
	{ maxWidth }: ProcessImageOptions
): Promise<{ previewUrl: string | null; compressedFile: File | null; error: string | null }> => {
	try {
		// Validate file type
		if (!VALID_IMAGE_TYPES.includes(file.type)) {
			return {
				previewUrl: null,
				compressedFile: null,
				error: 'Only PNG and JPEG images are allowed.',
			};
		}

		const image = await loadImage(file);
		const shouldResize = image.width > maxWidth;

		const options = {
			maxSizeMB: 0.25,
			maxWidth: shouldResize ? maxWidth : undefined,
			useWebWorker: true,
		};

		const compressedImage = await imageCompression(file, options);

		if (compressedImage.size > MAX_IMAGE_SIZE_BYTES) {
			return {
				previewUrl: null,
				compressedFile: null,
				error: 'Compressed image is still larger than 250 KB. Please choose a smaller image.',
			};
		}

		//console.log(compressedImage);
		return {
			previewUrl: URL.createObjectURL(compressedImage),
			compressedFile: compressedImage,
			error: null,
		};
	} catch (err) {
		return { previewUrl: null, compressedFile: null, error: 'Image too large, try again.' };
	}
};

const loadImage = (file: File): Promise<HTMLImageElement> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		const img = new Image();

		reader.onload = (e) => {
			img.src = e.target?.result as string;

			img.onload = () => {
				resolve(img);
			};

			img.onerror = (error) => {
				reject(error);
			};
		};

		reader.onerror = (error) => {
			reject(error);
		};

		reader.readAsDataURL(file);
	});
};
