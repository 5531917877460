import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../Shared/axiosBaseQuery';
import UserDetails from './models/UserDetails';
import { PUT } from '../shared/httpMethods';

export const userApiSlice = createApi({
	reducerPath: 'userApi',
	baseQuery: axiosBaseQuery(),
	tagTypes: ['UserDetails'],
	endpoints: (build) => ({
		getUserDetails: build.query<UserDetails, void>({
			query: () => ({
				url: 'users',
				method: 'GET',
			}),
			providesTags: ['UserDetails'],
			keepUnusedDataFor: 2592000, // Cache data for 30 days (in seconds)
		}),
		updateUserDetails: build.mutation<void, Partial<UserDetails>>({
			query: (updatedDetails) => ({
				url: 'users',
				method: 'PUT',
				data: updatedDetails,
			}),
			invalidatesTags: ['UserDetails'],
		}),
		updateProfileImage: build.mutation<void, FormData>({
			query: (formData: FormData) => ({
				url: 'users/profileimage',
				method: PUT,
				data: formData,
			}),
		}),
	}),
});

export const { useGetUserDetailsQuery, useUpdateProfileImageMutation } =
	userApiSlice;
