import { Text } from '../../../Components/ui/Text';
import {
	useGetFriendRequestsQuery,
	useRespondToFriendRequestMutation,
} from '../../../features/friend/friendApiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

const RequestsComponent = () => {
	const {
		data: friendRequests,
		isLoading,
		isError,
	} = useGetFriendRequestsQuery();

	const [respondToFriendRequest, { isLoading: isMutating }] =
		useRespondToFriendRequestMutation();

	const handleFriendRequest = async (
		requestId: string,
		accepted: boolean
	) => {
		try {
			await respondToFriendRequest({
				friendRequestId: requestId,
				accepted,
			}).unwrap();
		} catch (error) {
			console.error('Error responding to friend request:', error);
		}
	};

	return (
		<div className='flex flex-col space-y-8 py-4'>
			{friendRequests?.map((request, index) => (
				<div
					key={index}
					className='w-full flex flex-row justify-between items-center'
				>
					<div>
						{request.fromUserProfileImageUrl && (
							<img
								src={request.fromUserProfileImageUrl}
								alt={request.fromUserFullName}
								className='w-10 h-10 rounded-full'
							/>
						)}
						<Text variant='body1' className='font-medium'>
							{request.fromUserFullName}
						</Text>
					</div>
					<div className='flex flex-row space-x-3'>
						<button
							className='flex w-10 h-10 items-center justify-center bg-white shadow-sm outline outline-1 outline-gray-200 rounded-full'
							onClick={() =>
								handleFriendRequest(
									request.friendRequestId,
									false
								)
							}
							disabled={isMutating}
						>
							<FontAwesomeIcon
								icon={faXmark}
								className='text-2xl text-gray-400'
							/>
						</button>
						<button
							className='flex w-10 h-10 items-center justify-center bg-white shadow-sm outline outline-1 outline-gray-200 rounded-full'
							onClick={() =>
								handleFriendRequest(
									request.friendRequestId,
									true
								)
							}
							disabled={isMutating}
						>
							<FontAwesomeIcon
								icon={faCheck}
								className='text-lg text-gray-600'
							/>
						</button>
					</div>
				</div>
			))}
		</div>
	);
};

export default RequestsComponent;
