import axiosClient from '../Shared/ApiClient';
import { jwtDecode } from 'jwt-decode';

export const signUp = async (request) => {
	try {
		console.log(request);
		const response = await axiosClient.post('/Auth/SignUp', request);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 400) {
			const errors = error.response.data.errors;
			throw { ...errors };
		} else if (error.response && error.response.status === 409) {
			const errors = error.response.data.errors;
			const emailNotUnique = errors.find(
				(err) => err.code === 'Users.EmailNotUnique'
			);
			if (emailNotUnique) {
				throw { ...emailNotUnique };
			}
		}
		console.error(error);
	}
};

export const login = async (request) => {
	try {
		const response = await axiosClient.post('/Auth/Login', request);
		const decoded = jwtDecode(response.data.token);
		return {
			userId: decoded.nameid,
			username: decoded.username,
			initials: decoded.initials,
			token: response.data.token,
		};
	} catch (error) {
		if (error.response && error.response.status === 401) {
			const errors = error.response.data.errors;
			const incorrectPassword = errors.find(
				(err) => err.code === 'Users.IncorrectPassword'
			);

			if (incorrectPassword) {
				console.log(incorrectPassword);
				throw { ...incorrectPassword };
			}
		}
		console.error(error);
		throw error;
	}
};
