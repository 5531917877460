import { Text } from '../../../Components/ui/Text';
import { getRandomColor } from '../../../utils/avatarUtils';

type PlayerHoleInfoProps = {
	profileImageUrl: string | null;
	playerInitials: string;
	givenName: string;
	surname: string;
	holePar: number;
	playerScore: number | null;
	overallScoreToPar: number;
};

const PlayerHoleInfo = ({
	profileImageUrl,
	playerInitials,
	givenName,
	surname,
	holePar,
	playerScore,
	overallScoreToPar,
}: PlayerHoleInfoProps) => {
	const toParDisplay =
		overallScoreToPar > 0
			? `+${overallScoreToPar}`
			: overallScoreToPar === 0
			? 'E'
			: `${overallScoreToPar}`;

	return (
		<div className='flex flex-row items-center space-x-2'>
			<div
				className='w-12 h-12 rounded-full flex items-center justify-center'
				style={{
					backgroundColor: profileImageUrl
						? 'transparent'
						: getRandomColor(),
				}}
			>
				{profileImageUrl ? (
					<img
						src={profileImageUrl}
						alt={playerInitials}
						className='w-full h-full rounded-full object-cover'
					/>
				) : (
					<div>
						<Text variant='body2'>{playerInitials}</Text>
					</div>
				)}
			</div>
			<div className='flex flex-col'>
				<Text variant='body2'>
					{givenName} {surname}
				</Text>
				<Text
					variant='fine1'
					className='font-medium text-primary-blue-darker'
				>
					To Par {toParDisplay}
				</Text>
			</div>
		</div>
	);
};

export default PlayerHoleInfo;
