import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ConfirmationModal from '../ConfirmationModal';
import { addScoresToRound, getRound } from '../../Services/roundService';
import { deleteRound } from '../../Services/roundService';
import { getCourse } from '../../Services/roundService';
import Scorecard from './Scorecard';
import {
	FaTrashAlt,
	FaAngleRight,
	FaAngleLeft,
	FaArrowRight,
} from 'react-icons/fa';
import useConfirmationModal from '../../Hooks/useConfirmationModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../Toast.css';
import { primeNewRoundData } from '../../utils/dataPriming';
import HoleView from './HoleView';
import { FaArrowLeft } from 'react-icons/fa';
import Select from 'react-select';
import {
	clubOptions,
	directionOptions,
	lieOptions,
} from '../../Shared/DropdownOpions';
import Dropdown from '../ui/Dropdown';

const NewRound = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const roundId = searchParams.get('roundId');

	const navigate = useNavigate();
	const { isOpen, openModal, closeModal } = useConfirmationModal();
	const [courseData, setCourseData] = useState(null);
	const [showFrontNine, setShowFrontNine] = useState(true);
	const [buttonVisible, setButtonVisible] = useState(true);
	const [currentHole, setCurrentHole] = useState(null);

	useEffect(() => {
		getRound(roundId).then((response) => {
			console.log(response);
			const primedCourseData = primeNewRoundData(response.layout);
			setCourseData(primedCourseData);
			setCurrentHole(
				primedCourseData.holes.find((h) => h.holeNumber === 1)
			);
			console.log(primedCourseData);
		});
	}, []);

	const strokesChanged = (hole, strokes) => {
		console.log(courseData.holes);
		console.log(strokes);
		const updatedHoles = courseData.holes.map((h) =>
			h.holeId === hole.holeId
				? { ...hole, strokes: parseInt(strokes, 10) }
				: h
		);
		console.log(updatedHoles);
		setCourseData({ ...courseData, holes: updatedHoles });
	};

	const fairwayChanged = (hole, checked) => {
		const updatedHoles = courseData.holes.map((h) =>
			h.holeId === hole.holeId ? { ...hole, fairwayHit: checked } : h
		);

		setCourseData({ ...courseData, holes: updatedHoles });
	};

	const greenChanged = (hole, checked) => {
		const updatedHoles = courseData.holes.map((h) =>
			h.holeId === hole.holeId
				? { ...hole, greenInRegulation: checked }
				: h
		);

		setCourseData({ ...courseData, holes: updatedHoles });
	};

	const puttsChanged = (hole, putts) => {
		const updatedHoles = courseData.holes.map((h) =>
			h.holeId === hole.holeId
				? { ...hole, putts: parseInt(putts, 10) }
				: h
		);

		setCourseData({ ...courseData, holes: updatedHoles });
	};

	const upAndDownChanged = (hole, checked) => {
		const updatedHoles = courseData.holes.map((h) =>
			h.holeId === hole.holeId ? { ...hole, upAndDown: checked } : h
		);

		setCourseData({ ...courseData, holes: updatedHoles });
	};

	const sandSaveChanged = (hole, checked) => {
		const updatedHoles = courseData.holes.map((h) =>
			h.holeId === hole.holeId ? { ...hole, sandSave: checked } : h
		);

		setCourseData({ ...courseData, holes: updatedHoles });
	};

	const handleSave = () => {
		navigate('/my-rounds');
	};

	const handleClick = () => {
		setButtonVisible(false);
		setTimeout(() => {
			setShowFrontNine(!showFrontNine);
			setTimeout(() => {
				setButtonVisible(true);
			}, 150); // Adjust time to match the CSS transition duration
		}, 100); // Adjust time to match the CSS transition duration
	};

	const handleSubmit = () => {
		// saveRoundData(roundId, courseData.holes).then(() => {
		// 	navigate(`/round-summary/${roundId}`);
		// });
		console.log(courseData.holes);
		addScoresToRound(roundId, courseData.holes).then(() => {
			navigate(`/round-summary/${roundId}`);
			console.log('saved');
		});
		console.log(courseData.holes);
	};

	const handleHoleClick = (hole) => {
		setCurrentHole(hole);
	};

	const handleShotChange = (index, field, option) => {
		console.log(option);
		const updatedShots = currentHole.shots.map((shot, shotIndex) =>
			shotIndex === index ? { ...shot, [field]: option.value } : shot
		);

		const updatedHole = { ...currentHole, shots: updatedShots };

		const updatedHoles = courseData.holes.map((hole) =>
			hole.holeId === currentHole.holeId ? updatedHole : hole
		);

		console.log(updatedHoles);

		setCurrentHole(updatedHole);
		setCourseData({ ...courseData, holes: updatedHoles });
	};

	const handleAddShot = () => {
		const newShot = {
			lie: null,
			club: null,
			distance: null,
		};

		const updatedHole = {
			...currentHole,
			shots: [...currentHole.shots, newShot],
		};

		const updatedHoles = courseData.holes.map((hole) =>
			hole.holeId === currentHole.holeId ? updatedHole : hole
		);

		setCurrentHole(updatedHole);
		setCourseData({ ...courseData, holes: updatedHoles });
	};

	const handleRemoveShot = (index) => {
		const updatedShots = currentHole.shots.filter(
			(_, shotIndex) => shotIndex !== index
		);

		const updatedHole = { ...currentHole, shots: updatedShots };

		const updatedHoles = courseData.holes.map((hole) =>
			hole.holeId === currentHole.holeId ? updatedHole : hole
		);

		setCurrentHole(updatedHole);
		setCourseData({ ...courseData, holes: updatedHoles });
	};

	const handleNextHole = () => {
		const currentIndex = courseData.holes.findIndex(
			(h) => h.holeId === currentHole.holeId
		);
		if (currentIndex < courseData.holes.length - 1) {
			handleHoleClick(courseData.holes[currentIndex + 1]);
		}
	};

	const handlePreviousHole = () => {
		const currentIndex = courseData.holes.findIndex(
			(h) => h.holeId === currentHole.holeId
		);
		if (currentIndex > 0) {
			handleHoleClick(courseData.holes[currentIndex - 1]);
		}
	};

	let isFirstHole = true;
	let isLastHole = false;

	if (courseData?.holes) {
		console.log(
			courseData.holes.findIndex((h) => h.holeNumber === 1) === 0
		);
		isFirstHole =
			courseData.holes.findIndex((h) => h.holeNumber === 1) === 0;
		isLastHole =
			courseData.holes.findIndex((h) => h.holeId === 18) ===
			courseData.holes.length - 1;
	}

	return (
		<div className='fixed w-full h-full left-0 top-0 z-50 bg-gray-500'>
			<ConfirmationModal
				isOpen={isOpen}
				title='Save & Exit'
				message='Your round will be saved and you can return to it later.'
				onClose={closeModal}
				onConfirm={handleSave}
			/>

			{courseData ? (
				<div className='flex flex-col justify-center items-center w-full space-y-10 h-full '>
					<div className='flex flex-col rounded w-full h-full items-center bg-white'>
						<div className='flex justify-between items-center p-4 w-full bg-white border-b border-grey-400'>
							<div className='flex items-center justify-end flex-row space-x-4'>
								<h1 className='flex flex-row text-grey-500 tracking-widest text-xl'>
									SHOT TRACKER
								</h1>
								<HoleView
									holes={courseData.holes}
									currentHole={currentHole}
									onHoleClick={handleHoleClick}
								/>
							</div>
							<button
								onClick={openModal}
								className='
								flex items-center justify-center
								rounded 
								tracking-widest
								w-36
								py-2.5
								ease-in
								duration-100 
								text-center 
								text-base 
								font-medium
								border
								text-gray-500
								shadow-sm 
								bg-white
								hover:bg-gray-200
								hover:text-gray-700
								focus-visible:outline 
								focus-visible:outline-2 
								focus-visible:outline-offset-2 
								focus-visible:outline-blue-700'
							>
								Exit
							</button>
						</div>
						<div
							key={currentHole?.holeId}
							className='flex flex-col flex-grow w-1/3 py-10 space-y-10 overflow-hidden'
						>
							<div className='border-l-4 border-gray-500 pl-2'>
								<div>
									<h1 className='text-lg text-gray-700 font-semibold'>
										Hole {currentHole.holeNumber}
									</h1>
									<div className='flex flex-row space-x-1 items-baseline'>
										<h1 className='text-gray-500 font-medium'>
											Par {currentHole.par}
										</h1>
										<h1 className='text-gray-400 font-medium text-sm'>
											{currentHole.length}m
										</h1>
									</div>
								</div>
							</div>
							<div className='flex flex-col flex-grow overflow-y-scroll custom-scrollbar px-2'>
								<div className='max-h-full'>
									<div className='grid grid-cols-3 gap-2'>
										<span className='text-sm ml-1 tracking-wider font-medium text-gray-400'>
											Lie
										</span>
										<span className='text-sm ml-1 tracking-wider font-medium text-gray-400'>
											Club
										</span>
										<span className='text-sm ml-1 tracking-wider font-medium text-gray-400'>
											Distance
										</span>
									</div>
									<div className='grid grid-cols-12 gap-2'>
										{currentHole.shots.map(
											(shot, index) => (
												<React.Fragment key={index}>
													<Dropdown
														options={lieOptions}
														placeholder='Lie'
														value={shot.lie}
														className='col-span-4'
														onChange={(option) =>
															handleShotChange(
																index,
																'lie',
																option
															)
														}
													/>
													<Dropdown
														options={clubOptions}
														placeholder='Club'
														value={shot.club}
														className='col-span-4'
														onChange={(option) =>
															handleShotChange(
																index,
																'club',
																option
															)
														}
													/>
													<div className='relative col-span-3'>
														<input
															type='number'
															className='no-arrows h-full border rounded border-gray-300 px-2 w-full pr-8'
															value={
																shot.distance
															}
															onChange={(e) =>
																handleShotChange(
																	index,
																	'distance',
																	e.target
																)
															}
														/>
														<span className='absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500'>
															{shot.lie ===
															'green'
																? 'ft'
																: 'm'}
														</span>
													</div>
													<div className='col-span-1 h-full w-full flex items-center justify-center'>
														{index !== 0 && (
															<FaTrashAlt
																className='h-full cursor-pointer'
																style={{
																	color: 'rgb(107 114 128)',
																}}
																onClick={() =>
																	handleRemoveShot(
																		index
																	)
																}
																size={23}
															/>
														)}
													</div>
												</React.Fragment>
											)
										)}
									</div>

									<span
										onClick={handleAddShot}
										className='flex max-w-fit text-sm tracking-wider font-medium text-primary-blue hover:text-primary-blue-darker cursor-pointer py-4'
									>
										Add Shot +
									</span>
								</div>
							</div>
						</div>
						<div className='flex w-full justify-between items-center border-t p-4'>
							<button
								onClick={handlePreviousHole}
								disabled={currentHole.holeNumber === 1}
								className={` tracking-wider flex flex-row items-center font-medium ${
									currentHole.holeNumber === 1
										? 'text-gray-300 cursor-not-allowed'
										: 'hover:text-gray-900 text-gray-600'
								}`}
							>
								<FaArrowLeft className='mr-2' />
								Previous Hole
							</button>
							<button
								onClick={handleNextHole}
								disabled={currentHole.holeNumber === 18}
								className={`flex items-center justify-center w-36 rounded tracking-widest py-2.5 ease-in duration-100 text-center text-base font-medium text-white shadow-sm ${
									currentHole.holeNumber === 18
										? 'bg-gray-400 cursor-not-allowed'
										: 'bg-primary-blue hover:bg-primary-blue-darker focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700'
								}`}
							>
								Next Hole
								<FaArrowRight className='ml-2' />
							</button>
						</div>
					</div>
				</div>
			) : (
				<p>Loading course data...</p>
			)}
		</div>
	);
};

export default NewRound;
