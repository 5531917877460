import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../../Components/ui/Text';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import FriendsListComponent from './components/FriendsListComponent';
import RequestsComponent from './components/RequestsComponent';

const TABS = {
	FRIENDS: 'friends',
	REQUESTS: 'requests',
};

const FriendsPage = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const currentPath = location.pathname;
	const activeTab = currentPath.includes('/requests') ? TABS.REQUESTS : TABS.FRIENDS;

	const handleTabClick = (tab: string) => {
    if (tab === TABS.FRIENDS) {
      navigate('/friends');
    } else if (tab === TABS.REQUESTS) {
      navigate('/friends/requests');
    }
  };

	return (
		<div className='p-4'>
			<div className='flex justify-between mb-4'>
				<Text variant='h2'>Friends</Text>
				<span
					className='flex items-center justify-center w-10 h-10 rounded-full bg-muted-gray'
					onClick={() => navigate('/friends/search')}
				>
					<FontAwesomeIcon
						icon={faSearch}
						className='h-4 w-4 text-gray-700'
					/>
				</span>
			</div>
			<div className='flex flex-row space-x-4'>
				<span
					className={`border px-4 py-2 rounded-3xl cursor-pointer ${
						activeTab === TABS.FRIENDS
							? 'bg-primary-blue-darker'
							: 'bg-muted-gray'
					}`}
					onClick={() => handleTabClick(TABS.FRIENDS)}
				>
					<Text
						variant='body2'
						className='font-medium'
						color={activeTab === TABS.FRIENDS ? 'white' : 'dark'}
					>
						Your Friends
					</Text>
				</span>
				<span
					className={`border px-4 py-2 rounded-3xl cursor-pointer ${
						activeTab === TABS.REQUESTS
							? 'bg-primary-blue-darker'
							: 'bg-muted-gray'
					}`}
					onClick={() => handleTabClick(TABS.REQUESTS)}
				>
					<Text
						variant='body2'
						className='font-medium'
						color={activeTab === TABS.REQUESTS ? 'white' : 'dark'}
					>
						Requests
					</Text>
				</span>
			</div>
			{activeTab === TABS.FRIENDS ? (
        <FriendsListComponent />
      ) : (
        <RequestsComponent />
      )}
		</div>
	);
};

export default FriendsPage;
