import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PieChart = ({ labels, data, backgroundColors, borderColors }) => {
	const pieData = {
		labels: labels,
		datasets: [
			{
				data: data,
				backgroundColor: backgroundColors,
				borderColor: borderColors,
				borderWidth: 1,
			},
		],
	};

	const options = {
		responsive: true,
		aspectRatio: 2,
		plugins: {
			legend: {
				display: true,
				position: 'left',
				labels: {
					usePointStyle: true, // Use circular legend markers
					pointStyle: 'circle',
				},
			},
			title: {
				display: false,
			},
			datalabels: {
				display: false,
			},
		},
	};

	return <Pie data={pieData} options={options} />;
};

export default PieChart;
