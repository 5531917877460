import {
	faChartSimple,
	faComment,
	faBell,
	faBoxArchive,
	faRectangleList,
	faTrophy,
	faUserGroup
} from '@fortawesome/free-solid-svg-icons';
import AppNavLink from './AppNavLink';
import NavSubMenu from './NavSubMenu';
import { useSelector } from 'react-redux';

const NavLinks = () => {
	const username = useSelector((state) => state.auth.username);
	return (
		<nav className='flex flex-col w-full h-max lg:px-4'>
			<ul
				className='
            flex 
            relative 
            flex-col'
			>
				<div className='flex flex-col py-4 lg:p-0 space-y-4 lg:space-y-6'>
					<NavSubMenu label='Stats' icon={faChartSimple}>
						<AppNavLink to='/stats/scoring' label='Scoring' />
						<AppNavLink to='/stats/driving' label='Driving' />
						<AppNavLink to='/stats/approach' label='Approach' />
						<AppNavLink
							to='/stats/around-the-green'
							label='Around the green'
						/>
						<AppNavLink to='/stats/putting' label='Putting' />
					</NavSubMenu>
					<AppNavLink
						to='/courses'
						label='Courses'
						icon={faBoxArchive}
					/>
					<AppNavLink
						to={`/${username}/rounds`}
						label='Rounds'
						icon={faRectangleList}
					/>
					<AppNavLink
						to='/my-tournaments'
						label='My Tournaments'
						icon={faTrophy}
					/>
				</div>
				<span className='w-full h-px bg-gray-200 lg:hidden' />
				<div className='flex flex-col py-4 space-y-4 lg:hidden'>
					<AppNavLink to='/friends' label='Friends' icon={faUserGroup} />
					<AppNavLink to='/' label='Messages' icon={faComment} />
					<AppNavLink to='/' label='Notifications' icon={faBell} />
				</div>
				<span className='w-full h-px bg-gray-200 lg:hidden' />
			</ul>
		</nav>
	);
};

export default NavLinks;
