import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEdit } from '@fortawesome/free-solid-svg-icons';
//import { useGetPairingsQuery } from '../../../../features/tournament/tournamentApiSlice';
import { Text } from '../../../../Components/ui/Text';
import { Button } from '../../../../Components/ui/Button';
import {
	useGetGroupsQuery,
	useGetRoundsQuery,
} from '../../../../features/tournament/tournamentApiSlice';
import { useEffect, useState } from 'react';
import TournamentRound from '../../../../features/tournament/models/TournamentRound';
import PairingCard from '../components/PairingCard';

const ViewPairingsPage = () => {
	const { tournamentId } = useParams();
	const navigate = useNavigate();
	const [selectedRound, setSelectedRound] = useState<TournamentRound | null>(
		null
	);

	const [groupedUserIds, setGroupedUserIds] = useState<string[]>([]);

	const { data: roundsData, isLoading: isRoundsLoading } = useGetRoundsQuery(
		tournamentId!
	);

	console.log(roundsData);

	const { data: groupData, isLoading: isGroupDataLoading } =
		useGetGroupsQuery(tournamentId!);

	useEffect(() => {
		if (roundsData && roundsData.length > 0) {
			setSelectedRound(roundsData[0]);
		}
	}, [roundsData]);

	useEffect(() => {
		if (groupData && selectedRound) {
			const userIds = groupData
				.filter(
					(group) => group.roundNumber === selectedRound.roundNumber
				)
				.flatMap((group) =>
					group.players.map((player) => player.userId)
				);
			setGroupedUserIds(userIds);
		}
	}, [groupData, selectedRound]);

	const handleBackClick = () => {
		navigate(`/tournament/${tournamentId}`);
	};

	const handleNewPairing = () => {
		navigate(`/tournament/${tournamentId}/pairings/create`, {
			state: {
				roundId: selectedRound?.roundId,
				groupedUserIds: groupedUserIds,
			},
		});
	};

	return (
		<div className='bg-muted-gray min-h-full'>
			<div className='fixed w-full top-0 left-0 z-10 h-16 flex justify-between items-center border-b p-4 shadow-sm bg-white'>
				<FontAwesomeIcon
					icon={faChevronLeft}
					className='text-2xl text-gray-700'
					onClick={handleBackClick}
				/>
			</div>
			<div className='flex flex-row space-x-4 py-[4.5rem] px-4 bg-muted-60'>
				{isRoundsLoading ? (
					<div>Loading rounds...</div>
				) : (
					roundsData?.map((round) => (
						<span
							key={round.roundId}
							className={`border px-4 py-2 rounded-3xl cursor-pointer ${
								selectedRound?.roundNumber === round.roundNumber
									? 'bg-primary-blue-darker'
									: 'bg-muted-gray'
							}`}
							onClick={() => setSelectedRound(round)}
						>
							<Text
								variant='body2'
								className='font-medium'
								color={
									selectedRound?.roundNumber ===
									round.roundNumber
										? 'white'
										: 'dark'
								}
							>
								{`Round ${round.roundNumber}`}
							</Text>
						</span>
					))
				)}
			</div>
			<div className='flex flex-col items-center w-full pt-4 pb-4 px-4 space-y-4 bg-muted-60 min-h-full'>
				{/* <div className='flex flex-row justify-between items-center w-full'>
					<FontAwesomeIcon
						icon={faEdit}
						className='text-xl text-gray-600'
						//onClick={handleBackClick}
					/>
				</div> */}
				{groupData
					?.filter(
						(group) =>
							group.roundNumber === selectedRound?.roundNumber
					)
					.map((group, index) => (
						<PairingCard
							key={index}
							group={group}
							roundId={selectedRound!.roundId}
							groupedUserIds={groupedUserIds}
						/>
					))}
				<Button
					className='rounded-3xl w-auto px-4'
					onClick={handleNewPairing}
				>
					Add
				</Button>
			</div>
		</div>
	);
};

export default ViewPairingsPage;
