import React, { Fragment } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '../../utils/utils';
import { Text } from './Text';
//import DatePicker, { DatePickerProps  } from 'react-datepicker';

const inputVariants = cva(
	'border text-gray-700 bg-white w-full placeholder:tracking-wider focus:border-gray-400 transition duration-200 focus:outline-none placeholder:text-gray-400',
	{
		variants: {
			variant: {
				default: 'border-gray-300',
				outline: '',
				error: 'border-red-300',
			},
			size: {
				default: 'h-10 px-3 py-4 rounded',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
);

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	variant?: VariantProps<typeof inputVariants>['variant'];
	inputSize?: VariantProps<typeof inputVariants>['size'];
	label?: string;
	error?: string;
}

const Input: React.FC<InputProps> = ({
	className,
	inputSize,
	variant,
	type,
	label,
	error,
	...props
}) => {
	return (
		<div>
			{label && (
				<label className='block text-gray-700 font-medium pl-1'>
					<Text variant='body2' color='dark' className='font-medium'>
						{label}
					</Text>
				</label>
			)}
			<input
				type={type}
				className={cn(
					inputVariants({ variant, size: inputSize, className })
				)}
				{...props}
			/>
			{error && (
				<Text variant='fine2' color='error' className='mt-1'>
					{error}
				</Text>
			)}
		</div>
	);
};

export { Input, inputVariants };
