import React from 'react';
import Toggle from '../../../Components/ui/Toggle';
import { Typography } from '@mui/material';
import { Text } from '../../../Components/ui/Text';

interface ScoringSelectProps {
	onToggle: (newChecked: boolean) => void;
	checked: boolean;
}

const ScoringSelect: React.FC<ScoringSelectProps> = ({ onToggle, checked }) => {

	const handleChange = (newChecked: boolean) => {
		onToggle(newChecked);
	};

	return (
		<div className='pl-2 space-y-2'>
			<Text variant='h6' color='default'>Log all shots</Text>
			<Toggle checked={checked} onChange={handleChange} size='md' />
		</div>
	);
};

export default ScoringSelect;
