import { createAsyncThunk } from '@reduxjs/toolkit';
import { signUp, login } from '../../../Services/authService';
import { logout } from '../../slices/authSlice';
import { userApiSlice } from '../../../features/user/userApiSlice';

export const signUpUser = createAsyncThunk(
	'auth/signUpUser',
	async (values, thunkAPI) => {
		try {
			const response = await signUp(values);
			return response.token;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const loginUser = createAsyncThunk(
	'auth/loginUser',
	async (values, thunkAPI) => {
		try {
			const data = await login(values);
			//thunkAPI.dispatch(userApiSlice.endpoints.getUserDetails.initiate());
			return data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const logoutUser = createAsyncThunk(
	'auth/logoutUser',
	async (_, { dispatch }) => {
		dispatch(logout());
		dispatch(userApiSlice.util.resetApiState());
	}
);
