import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/slices/authSlice';

const ProfileDropdown = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleLogout = () => {
		dispatch(logout());
		navigate('/login');
	};

	return (
		<div className='absolute right-0 z-50 w-auto bg-white border border-gray-200 rounded-md shadow-lg'>
			<div className='flex flex-col p-4'>
				<div className='flex flex-row items-center space-x-4 pb-4 border-b'>
					<span className='bg-gray-300 rounded-full p-3 text-gray-600 font-medium text-xl'>
						CS
					</span>
					<div className='flex flex-col'>
						<span className='text-base tracking-wider'>
							Ciaran Selwyn
						</span>
						<span className='text-xs text-gray-500 tracking-wider'>
							ciaranselwyn@yahoo.co.nz
						</span>
					</div>
				</div>
				<div>
					<ul className='space-y-2 pt-4'>
						<li className='text-primary-blue font-medium text-sm tracking-wider cursor-pointer'>
							Upgrade To Premium
						</li>
						<li className='text-gray-700 text-sm font-medium tracking-wider cursor-pointer'>
							Account Settings
						</li>
						<li onClick={handleLogout} className='text-gray-700 text-sm font-medium tracking-wider cursor-pointer'>
							Sign Out
						</li>
					</ul>
				</div>
			</div>
			{/* <ul className='py-1'>
				<li className='px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer'>
					Profile
				</li>
				<li className='px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer'>
					Settings
				</li>
				<li className='px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer'>
					Logout
				</li>
			</ul> */}
		</div>
	);
};

export default ProfileDropdown;
