export const avatarColors: string[] = [
  '#95D2B3', // pastelGreen
  '#EF9C66', // pastelOrange
  '#DBB5B5', // pastelRuby
  '#D37676', // pastelRed
  '#A79277', // pastelBrown
  '#51829B', // pastelBlue
  '#AD88C6', // pastelPurple
  '#F1EF99', // pastelYellow
  '#FF90BC', // pastelPink
]

export const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * avatarColors.length);
  return avatarColors[randomIndex];
}