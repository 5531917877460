import { format } from 'date-fns';
import SearchTournamentsResponse from '../../../features/tournament/models/SearchTournamentsResponse';
import { Text } from '../../../Components/ui/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import AvatarList from '../../tournament/components/AvatarList';

type CardProps = {
	tournament: SearchTournamentsResponse;
	onClick: (tournamentId: string) => void;
};

const TournamentCard = ({ tournament, onClick }: CardProps) => {
	return (
		<div
			className='border rounded-md p-4 aspect-video flex flex-col justify-between'
			style={{
				backgroundImage: `
          linear-gradient(to top, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0)), 
          url(${tournament.thumbnailUrl})
        `,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
			}}
			onClick={() => onClick(tournament.tournamentId)}
		>
			<div className='flex flex-row items-center space-x-1'>
				<span className='w-2 h-2 bg-red-400 rounded-full flex' />
				<Text variant='fine1' color='white'>
					Registration open
				</Text>
			</div>
			<div className='space-y-3'>
				<div>
					<div>
						<Text variant='h5' color='white'>
							{tournament.tournamentName}
						</Text>
					</div>
					<div className='flex flex-row space-x-1'>
						<FontAwesomeIcon
							icon={faCalendar}
							className='text-xs text-gray-200'
						/>
						<Text variant='fine1' className='text-gray-200'>
							{format(tournament.startDate, 'EEE do MMMM yyyy')}
						</Text>
					</div>
				</div>
				<div className='flex relative h-10'>
					<AvatarList avatars={tournament.avatarStack} totalPlayers={tournament.playersEntered} />
				</div>
			</div>
		</div>
	);
};

export default TournamentCard;
