export const Lie = Object.freeze({
	TEE: 'tee',
	FAIRWAY: 'fairway',
	ROUGH: 'rough',
	DEEP_ROUGH: 'deepRough',
	GREEN: 'green',
	BUNKER: 'bunker',
	FRINGE: 'fringe',
});

export const Club = Object.freeze({
	NONE: null,
	DRIVER: 'driver',
	THREE_WOOD: '3Wood',
	FIVE_WOOD: '5Wood',
	SEVEN_WOOD: '7Wood',
	HYBRID: 'hybrid',
	TWO_IRON: '2i',
	THREE_IRON: '3-iron',
	FOUR_IRON: '4-iron',
	FIVE_IRON: '5-iron',
	SIX_IRON: '6-iron',
	SEVEN_IRON: '7-iron',
	EIGHT_IRON: '8-iron',
	NINE_IRON: '9-iron',
	PITCHING_WEDGE: 'pitching-wedge',
	GAP_WEDGE: 'gap-wedge',
	SAND_WEDGE: 'sand-wedge',
	LOB_WEDGE: 'lob-wedge',
	SIXTY_DEGREE_WEDGE: '60-degree-wedge',
	PUTTER: 'putter',
});

export const Direction = Object.freeze({
	HIT: 'hit',
	LEFT: 'left',
	RIGHT: 'right',
	LONG: 'long',
	SHORT: 'short',
});
