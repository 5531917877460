import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Text } from '../../../Components/ui/Text';

interface ScoreInputProps {
	par: number;
	onScoreChange: (newScore: number | null) => void;
	value: number | null;
}

const ScoreInput: React.FC<ScoreInputProps> = ({
	par,
	onScoreChange,
	value,
}) => {
	const handlePlusClick = () => {
		let newScore: number;
		if (value === null) {
			newScore = par;
		} else {
			newScore = value + 1;
		}
		onScoreChange(newScore);
	};

	const handleMinusClick = () => {
		if (value !== null) {
			if (value > 1) {
				const newScore = value - 1;
				onScoreChange(newScore);
			} else {
				onScoreChange(null);
			}
		}
	};

	const getScoreLabelAndColors = () => {
		if (value === null)
			return {
				label: 'Par',
				bgColor: 'bg-gray-200',
				textColor: 'text-black',
			};

		const scoreDifference = value - par;

		if (value === 1)
			return {
				label: 'Ace',
				bgColor: 'bg-lime-500',
				textColor: 'text-white',
			};
		if (scoreDifference <= -3)
			return {
				label: 'Albatross',
				bgColor: 'bg-lime-500',
				textColor: 'text-white',
			};
		if (scoreDifference === -2)
			return {
				label: 'Eagle',
				bgColor: 'bg-lime-500',
				textColor: 'text-white',
			};
		if (scoreDifference === -1)
			return {
				label: 'Birdie',
				bgColor: 'bg-primary-blue',
				textColor: 'text-white',
			};
		if (scoreDifference === 0)
			return {
				label: 'Par',
				bgColor: 'bg-gray-200',
				textColor: 'text-black',
			};
		if (scoreDifference === 1)
			return {
				label: 'Bogey',
				bgColor: 'bg-gray-600',
				textColor: 'text-white',
			};

		// Handle double bogey, triple bogey, etc.
		return {
			label: `${scoreDifference} Bogey`,
			bgColor: 'bg-gray-600',
			textColor: 'text-white',
		};
	};

	const { label, bgColor, textColor } = getScoreLabelAndColors();

	return (
		<div className='flex items-center'>
			{value !== null && (
				<button
					onClick={handleMinusClick}
					className='w-9 h-9 flex items-center justify-center bg-gray-200 rounded-md mr-2'
				>
					<FontAwesomeIcon
						icon={faMinus}
						className='text-base text-gray-600'
					/>
				</button>
			)}
			<div className='flex flex-row relative'>
				{value !== null && (
					<Text
						variant='h6'
						className='flex justify-center items-center w-9 h-9 border border-r-0 rounded-l-md border-gray-200 z-30 bg-white'
					>
						{value}
					</Text>
				)}

				<button
					onClick={handlePlusClick}
					className={`w-9 h-9 flex items-center justify-center border border-gray-200 rounded-r-md z-30 bg-white ${
						value !== null
							? 'border-l-0'
							: 'border-l-1 rounded-l-md'
					}`}
				>
					<FontAwesomeIcon
						icon={faPlus}
						className='text-base text-gray-600'
					/>
				</button>
				<span className='w-px h-5 absolute bg-gray-300 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40' />
				{value && (
					<div
						className={`flex items-center justify-center rounded-br-md rounded-bl-md absolute w-full -bottom-4 h-6 pt-2 z-10 ${bgColor}`}
					>
						<Text
							variant='fine2'
							className={`font-semibold ${textColor}`}
						>
							{label}
						</Text>
					</div>
				)}
			</div>
		</div>
	);
};

export default ScoreInput;
