import {
	faCalendar,
	faChevronLeft,
	faImage,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	useGetRoundByIdQuery,
	useUpdateRoundStatusMutation,
} from '../../../features/round/roundApiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { Text } from '../../../Components/ui/Text';
import { formatDate } from '../../../utils/dateFormatter';
import { Button } from '../../../Components/ui/Button';
import { getRandomColor } from '../../../utils/avatarUtils';
import { RoundStatusEnum } from '../../../features/round/enums/roundStatusEnum';
import UpdateRoundStatusRequest from '../../../features/round/types/UpdateRoundStatusRequest';

const ViewRoundPage = () => {
	const navigate = useNavigate();
	const { roundId } = useParams<{ roundId: string }>();

	const {
		data: roundData,
		isLoading: roundDataLoading,
		isError: roundDataError,
	} = useGetRoundByIdQuery(roundId!);

	const [updateRoundStatus, { isLoading, isError, data }] =
		useUpdateRoundStatusMutation();

	function handleBackClick(): void {
		navigate(-1);
	}

	const handleStartRound = async () => {
		if (roundData?.status !== RoundStatusEnum.InProgress) {
			try {
				const req: UpdateRoundStatusRequest = {
					roundId: roundData!.roundId,
					newStatus: RoundStatusEnum.InProgress,
				};
				await updateRoundStatus(req).unwrap();
			} catch (error) {
				console.error('Failed to update round status:', error);
				return;
			}
		}
		navigate(`/round-entry/${roundId}/1`, {
			state: {
				groupId: roundData?.groupId,
			},
		});
	};

	if (roundDataLoading) {
		return <div>Loading...</div>;
	}

	if (roundDataError) {
		return <div>Error loading round data.</div>;
	}

	return (
		<div className='bg-muted-gray min-h-full'>
			<div className='fixed w-full top-0 left-0 z-10 h-16 flex justify-between items-center border-b p-4 shadow-sm bg-white'>
				<FontAwesomeIcon
					icon={faChevronLeft}
					className='text-2xl text-gray-700'
					onClick={handleBackClick}
				/>
			</div>
			<div className='pt-16 overflow-y-scroll'>
				<div className='bg-white p-4'>
					<div className='flex items-center justify-center w-full aspect-video bg-gray-200 rounded-md shadow-sm'>
						<FontAwesomeIcon
							icon={faImage}
							className='text-5xl text-gray-600'
							onClick={handleBackClick}
						/>
					</div>
					<div className='pt-4 bg-white'>
						<Text variant='h3'>{roundData?.courseName}</Text>
						<div className='flex flex-row space-x-2'>
							<div className='flex flex-row items-center space-x-1'>
								<span className='flex w-2 h-2 rounded-full bg-primary-blue-darker ' />
								<Text variant='fine2'>{roundData?.format}</Text>
							</div>
							<div className='flex flex-row items-center space-x-1'>
								<span className='flex w-2 h-2 rounded-full bg-primary-blue-darker ' />
								<Text variant='fine2'>
									{roundData?.layout.teeName} Tees
								</Text>
							</div>
						</div>
						<div className='space-y-5 bg-white pt-4'>
							<div className='flex flex-row items-baseline space-x-3'>
								<FontAwesomeIcon
									icon={faCalendar}
									className='text-lg text-gray-500'
								/>
								<Text
									variant='h6'
									color='darker'
									className='font-medium'
								>
									{roundData?.roundDate &&
										formatDate(roundData?.roundDate)}
								</Text>
							</div>
						</div>
					</div>
				</div>
				<div className='p-4 bg-white mt-2 space-y-0'>
					<Text variant='h5'>Course Info</Text>
					<div className='space-y-2 bg-white pt-3'>
						<div className='flex flex-row items-center'>
							<Text
								variant='h6'
								color='darker'
								className='font-medium'
							>
								Par {roundData?.layout.par}
							</Text>
						</div>
						<div className='flex -flex-row items-center'>
							<Text
								variant='h6'
								color='darker'
								className='font-medium'
							>
								{roundData?.layout.length} m
							</Text>
						</div>
						<div className='flex -flex-row items-center'>
							<Text
								variant='h6'
								color='darker'
								className='font-medium'
							>
								{roundData?.layout.courseRating} CR
							</Text>
						</div>
						<div className='flex -flex-row items-center'>
							<Text
								variant='h6'
								color='darker'
								className='font-medium'
							>
								{roundData?.layout.slopeRating} SR
							</Text>
						</div>
					</div>
				</div>
				<div className='relative p-4 bg-white mt-2 space-y-2'>
					<Text variant='h5'>Playing</Text>
					<div className='flex space-x-5'>
						{roundData?.players.map((player, index) => (
							<div className='flex flex-col items-center space-y-2'>
								<div
									key={index}
									className='w-10 h-10 rounded-full flex items-center justify-center'
									style={{
										backgroundColor: player.profileImageUrl
											? 'transparent'
											: getRandomColor(),
									}}
								>
									{player.profileImageUrl ? (
										<img
											src={player.profileImageUrl}
											alt={player.initials}
											className='w-full h-full rounded-full object-cover'
										/>
									) : (
										<div>
											<Text variant='body2'>
												{player.initials}
											</Text>
										</div>
									)}
								</div>
								<Text variant='fine1'>{player.givenName}</Text>
							</div>
						))}
					</div>
				</div>
				<div className='fixed flex left-0 bottom-0 w-full p-4 bg-white'>
					<Button
						className='w-full'
						onClick={handleStartRound}
						loading={isLoading}
					>
						{roundData?.status === RoundStatusEnum.InProgress
							? 'Continue'
							: 'Play'}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ViewRoundPage;
