import React, { useEffect, useState } from 'react';
import { getDrivingStats } from '../../Services/statsService';

const DrivingStats = () => {
	const [stats, setStats] = useState(null);

	useEffect(() => {
		getDrivingStats().then((data) => {
			console.log(data);
			setStats(data);
		});
	}, []);
	return <div className='w-full h-full'>DrivingStats</div>;
};

export default DrivingStats;
