import { useNavigate } from 'react-router-dom';
import TournamentSummary from '../../../../features/tournament/models/TournamentSummary';
import TournamentListItem from './TournamentListItem';

type ListProps = {
	tournaments: TournamentSummary[];
};

const TournamentsList = ({ tournaments }: ListProps) => {
	const navigate = useNavigate();

	const handleViewTournament = (tournamentId: string) => {
		navigate(`/tournament/${tournamentId}`);
	};

	return (
		<div className='space-y-4 py-4'>
			{tournaments?.map((tournament, index) => (
				<TournamentListItem
					key={index}
					tournament={tournament}
					onClick={() => {
						handleViewTournament(tournament.tournamentId);
					}}
				/>
			))}
		</div>
	);
};

export default TournamentsList;
