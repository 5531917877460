import { useParams, useNavigate } from 'react-router-dom';
import {
	useDeleteTournamentMutation,
	useGetTournamentByIdQuery,
} from '../../../features/tournament/tournamentApiSlice';
import { Text } from '../../../Components/ui/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faShareNodes,
	faChevronLeft,
	faCalendar,
	faLocationArrow,
	faDollarSign,
	faUser,
	faEnvelope,
	faPhone,
	faTrash,
	faEllipsis,
} from '@fortawesome/free-solid-svg-icons';
import { formatTournamentDate } from '../../../utils/dateFormatter';
import { Button } from '../../../Components/ui/Button';
import AvatarList from '../components/AvatarList';
import PopupModal from '../../../Components/ui/modals/PopupModal';
import ConfirmationBox from '../../../Components/ui/ConfirmationBox';
import { useModal } from '../../../Hooks/useModal';
import { toast } from 'react-toastify';

const ViewTournamentPage = () => {
	const { tournamentId } = useParams();
	const navigate = useNavigate();

	const {
		isOpen: isModalOpen,
		openModal: openModal,
		closeModal: closeModal,
	} = useModal();

	const {
		data: tournamentDetails,
		error,
		isLoading,
	} = useGetTournamentByIdQuery(tournamentId!, { skip: !tournamentId });
	console.log('deets', tournamentDetails);

	const [deleteTournament] = useDeleteTournamentMutation();

	const tournamentDate = formatTournamentDate(
		tournamentDetails?.tournamentRounds || []
	);

	const handleBackClick = () => {
		const referrer = document.referrer;
		console.log(referrer);
		if (referrer.includes('/new-tournament')) {
			navigate('/');
		} else if (referrer.includes('/register')) {
			navigate(-2);
		} else if (window.history.length > 1) {
			navigate(-1);
		} else {
			navigate('/');
		}
	};
	const handleRegisterClick = () => {
		navigate(`/tournament/${tournamentDetails?.tournamentId}/register`);
	};

	const handleDeleteTournament = async () => {
		try {
			await deleteTournament(tournamentId!).unwrap();
			closeModal();
			toast.success('Tournament deleted.');
			navigate('/');
		} catch (err) {
			console.error('Failed to delete tournament:', err);
			toast.error('Failed to delete the tournament. Please try again.');
		}
	};

	const handleViewPairings = () => {
		navigate(`/tournament/${tournamentId}/pairings`);
	};

	if (isLoading) return <div>Loading...</div>;
	if (error || !tournamentDetails)
		return <div>Error: Could not load tournament</div>;

	return (
		<div className='bg-muted-gray'>
			<div className='fixed w-full top-0 left-0 z-10 flex justify-between items-center border-b p-4 shadow-sm bg-white'>
				<FontAwesomeIcon
					icon={faChevronLeft}
					className='text-2xl text-gray-700'
					onClick={handleBackClick}
				/>
				{!tournamentDetails.isHost ? (
					<span className='flex items-center justify-center w-10 h-10 rounded-full bg-muted-gray'>
						<FontAwesomeIcon
							icon={faShareNodes}
							className='h-4 w-4 text-gray-700'
						/>
					</span>
				) : (
					<>
						<span
							className='flex items-center justify-center w-10 h-10 rounded-full bg-muted-gray'
							onClick={openModal}
						>
							<FontAwesomeIcon
								icon={faTrash}
								className='h-4 w-4 text-gray-700'
							/>
						</span>
						<PopupModal open={isModalOpen} onClose={closeModal}>
							<ConfirmationBox
								onClose={closeModal}
								onConfirm={handleDeleteTournament}
								title='Delete Tournament'
								description='Are you sure you want to Delete this Tournament?'
							/>
						</PopupModal>
					</>
				)}
			</div>
			<div className='py-[4.5rem] overflow-y-scroll'>
				<div className='bg-white p-4'>
					<img
						src={tournamentDetails.thumbnailUrl}
						alt={tournamentDetails.tournamentName}
						className='w-full h-auto rounded-md aspect-video shadow-sm'
					/>
					<div className='pt-4 bg-white'>
						<Text variant='h3'>
							{tournamentDetails.tournamentName}
						</Text>
						<div className='flex flex-row space-x-2'>
							<div className='flex flex-row items-center space-x-1'>
								<span className='flex w-2 h-2 rounded-full bg-primary-blue-darker ' />
								<Text variant='fine2'>
									{
										tournamentDetails.tournamentRounds[0]
											.format
									}
								</Text>
							</div>
							<div className='flex flex-row items-center space-x-1'>
								<span className='flex w-2 h-2 rounded-full bg-primary-blue-darker ' />
								<Text variant='fine2'>
									{tournamentDetails.tournamentRounds[0].tee}{' '}
									Tees
								</Text>
							</div>
						</div>
						<div className='space-y-5 bg-white pt-4'>
							<div className='flex flex-row items-center space-x-3'>
								<FontAwesomeIcon
									icon={faCalendar}
									className='text-xl text-gray-500'
								/>
								<Text
									variant='h6'
									color='darker'
									className='font-medium'
								>
									{tournamentDate}
								</Text>
							</div>
							<div className='flex -flex-row items-center space-x-3'>
								<FontAwesomeIcon
									icon={faLocationArrow}
									className='text-xl text-gray-500'
								/>
								<Text
									variant='h6'
									color='darker'
									className='font-medium'
								>
									{
										tournamentDetails.tournamentRounds[0]
											.courseName
									}
								</Text>
							</div>
							<div className='flex -flex-row items-center space-x-3'>
								<FontAwesomeIcon
									icon={faDollarSign}
									className='text-xl text-gray-500'
								/>
								<Text
									variant='h6'
									color='darker'
									className='font-medium'
								>
									No Entry Fee
								</Text>
							</div>
						</div>
					</div>
				</div>
				<div className='relative p-4 bg-white mt-2 space-y-2'>
					<Text variant='h5'>Playing</Text>
					<div className='flex h-10'>
						<div className='relative'>
							<AvatarList
								avatars={tournamentDetails.avatarStack}
								totalPlayers={tournamentDetails.playersEntered}
							/>
						</div>
					</div>
					{tournamentDetails.isHost && (
						<FontAwesomeIcon
							icon={faEllipsis}
							className='absolute right-4 top-0 text-2xl text-gray-500'
							onClick={handleViewPairings}
						/>
					)}
				</div>
				<div className='p-4 bg-white mt-2 space-y-2'>
					<Text variant='h5'>Contact</Text>
					<div className='space-y-5 bg-white pt-4'>
						<div className='flex flex-row items-center space-x-3'>
							<FontAwesomeIcon
								icon={faUser}
								className='text-xl text-gray-500'
							/>
							<Text
								variant='h6'
								color='darker'
								className='font-medium'
							>
								{tournamentDetails.hostName}
							</Text>
						</div>
						<div className='flex -flex-row items-center space-x-3'>
							<FontAwesomeIcon
								icon={faEnvelope}
								className='text-xl text-gray-500'
							/>
							<Text
								variant='h6'
								color='darker'
								className='font-medium'
							>
								{tournamentDetails.hostEmail}
							</Text>
						</div>
						<div className='flex -flex-row items-center space-x-3'>
							<FontAwesomeIcon
								icon={faPhone}
								className='text-xl text-gray-500'
							/>
							<Text
								variant='h6'
								color='darker'
								className='font-medium'
							>
								{tournamentDetails.hostPhone}
							</Text>
						</div>
					</div>
				</div>
				{!tournamentDetails.isRegistered &&
					!tournamentDetails.isHost && (
						<div className='fixed flex left-0 bottom-0 w-full p-4 bg-white'>
							<Button
								className='w-full'
								onClick={handleRegisterClick}
							>
								Register
							</Button>
						</div>
					)}
			</div>
		</div>
	);
};

export default ViewTournamentPage;
