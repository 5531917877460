import React from 'react';
import { motion } from 'framer-motion';

interface ToggleProps {
	checked: boolean;
	onChange: (checked: boolean) => void;
	className?: string;
	size?: 'sm' | 'md' | 'lg';
}

const spring = {
	type: 'spring',
	stiffness: 700,
	damping: 30,
};

const Toggle: React.FC<ToggleProps> = ({
	checked,
	onChange,
	className = '',
	size = 'sm',
}) => {
	const handleToggleChange = () => {
		onChange(!checked);
	};

	const sizeClasses = {
		sm: 'w-8 h-4',
		md: 'w-[55px] h-[30px]',
		lg: 'w-16 h-8',
	};

	const knobSizeClasses = {
		sm: 'w-4 h-4',
		md: 'w-[23px] h-[23px]',
		lg: 'w-8 h-8',
	};

	return (
		<div
			className={`${
				sizeClasses[size]
			} flex justify-start items-center rounded-[50px] px-[4px] cursor-pointer transition-colors duration-300 ${
				checked
					? 'bg-primary-blue-darker bg-opacity-50 justify-end'
					: 'bg-gray-300'
			} ${className}`}
			onClick={handleToggleChange}
		>
			<motion.div
				className={`bg-white rounded-[40px] ${knobSizeClasses[size]}`}
        layout
				transition={spring}
			/>
		</div>
	);
};

export default Toggle;
