import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserDetailsQuery } from '../../../features/user/userApiSlice';
import { Button } from '../../../Components/ui/Button';
import { Text } from '../../../Components/ui/Text';
import { useRegisterForTournamentMutation } from '../../../features/tournament/tournamentApiSlice';
import TournamentRegistrationRequest from '../../../features/tournament/models/TournamentRegistrationRequest';
import RegistrationForm from './components/RegistrationForm';
import { toast } from 'react-toastify';
import { handleApiError } from '../../../utils/apiErrorHandler';

const TournamentRegistrationPage = () => {
	const { tournamentId } = useParams();
	const navigate = useNavigate();
	const {
		data: userDetails,
		error,
		isLoading: isUserDetailsLoading,
	} = useGetUserDetailsQuery();
	const [register, { isLoading: isRegistering, isError, data }] =
		useRegisterForTournamentMutation();

	const handleBackClick = () => {
		navigate(`/tournament/${tournamentId}`);
	};

	const handleFormSubmit = async (values: {
		givenName: string;
		surname: string;
		email: string;
		phone: string;
		dateOfBirth: Date;
		comments: string;
	}) => {
		try {
			const request: TournamentRegistrationRequest = {
				tournamentId: tournamentId!,
				givenName: values.givenName,
				surname: values.surname,
				email: values.email,
				phone: values.phone,
				dateOfBirth: new Date(values.dateOfBirth).toISOString(),
				comments: values.comments,
			};
			await register(request).unwrap();

			toast.success(
				'Registration successfull. Go to My Tournaments to view'
			);
			navigate('/explore/tournaments');
		} catch (error) {
			handleApiError(error);
		}
	};

	if (isUserDetailsLoading) {
		return <div>Loading user details...</div>;
	}

	if (error) {
		return <div>Error loading user details.</div>;
	}

	return (
		<div>
			<div className='fixed w-full top-0 left-0 z-10 flex justify-between items-center border-b p-4 shadow-sm bg-white'>
				<div className='h-10 flex items-center justify-center'>
					<FontAwesomeIcon
						icon={faChevronLeft}
						className='text-2xl text-gray-700'
						onClick={handleBackClick}
					/>
				</div>
				<Text variant='h6'>Tournament Registration</Text>
			</div>
			<RegistrationForm
				userDetails={userDetails}
				handleSubmit={handleFormSubmit}
				isSubmitting={isRegistering}
			/>
			<div className='fixed w-full bottom-0 p-4 z-10'>
				<Button
					className='w-full'
					type='submit'
					form='register-form'
					loading={isRegistering}
					disabled={isRegistering}
				>
					Submit
				</Button>
			</div>
		</div>
	);
};

export default TournamentRegistrationPage;
