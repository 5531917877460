import React, { useState } from 'react';
import { Input } from '../../../Components/ui/Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '../../../Components/ui/Button';
import { Alert } from '../../../Components/ui/Alert';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../../redux/features/auth/authActions';
import { userApiSlice } from '../../../features/user/userApiSlice';

const LoginForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [error, setError] = useState(null);

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email('Invalid Email')
				.required('Email is required'),
			password: Yup.string().required('Please enter a password'),
		}),
		onSubmit: async (values) => {
			console.log(values);
			const resultAction = await dispatch(loginUser(values));
			if (loginUser.fulfilled.match(resultAction)) {
				//await dispatch(userApiSlice.endpoints.getUserDetails.initiate());
				navigate('/');
			}
			if (loginUser.rejected.match(resultAction)) {
				console.log('failed mate', resultAction.payload);
				if (resultAction.payload.description) {
					console.log('error occurred');
					setError(resultAction.payload.description);
				}
			}
		},
	});

	return (
		<div className='flex flex-col items-center justify-center w-full lg:w-1/5 space-y-12'>
			<div className='w-full flex justify-center items-center flex-col'>
				<h1 className='text-gray-700 text-3xl font-medium tracking-wider'>
					Sign in
				</h1>
			</div>
			<div className='w-full flex flex-col space-y-3'>
				{error && <Alert variant='error' message={error} />}
				<Input
					type='email'
					variant={
						formik.touched.email && formik.errors.email
							? 'error'
							: 'default'
					}
					name='email'
					value={formik.values.email}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					placeholder='Email'
				/>
				<Input
					type='password'
					variant={
						formik.touched.password && formik.errors.password
							? 'error'
							: 'default'
					}
					name='password'
					value={formik.values.password}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					placeholder='Password'
				/>
				<div className='flex flex-col justify-end space-y-2'>
					<Button onClick={formik.handleSubmit} className='w-full'>
						Continue
					</Button>
					<footer className='flex justify-center'>
						<p className='text-gray-700 text-sm tracking-wider'>
							Already have an account?{' '}
							<span
								className='underline cursor-pointer font-medium text-gray-800'
								onClick={() => {
									navigate('/sign-up');
								}}
							>
								Sign up
							</span>
						</p>
					</footer>
				</div>
			</div>
		</div>
	);
};

export default LoginForm;
