import { Text } from '../../../../Components/ui/Text';
import { TournamentRound } from '../../../../models/Tournament/TornamentRound.type';
import RoundCard from '../RoundCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import Modal from '../../../../Components/ui/Modal';
import RoundDetails from '../RoundDetails';
import { GameModeEnum } from '../../../../models/Enums/gameModeEnum';
import { useModal } from '../../../../Hooks/useModal';
import { useFormikContext } from 'formik';
import { TournamentFormData } from '../../types/TournamentFormData';

const EditRoundsStep = () => {
	const { values, setFieldValue } = useFormikContext<TournamentFormData>();
	const [editingRoundIndex, setEditingRoundIndex] = useState<number | null>(
		null
	);

	const {
		isOpen: isModalOpen,
		openModal: openModal,
		closeModal: closeModal,
	} = useModal();

	const addNewRound = () => {
		let updatedRounds = values.rounds ? [...values.rounds] : [];

		if (updatedRounds && updatedRounds.length > 0) {
			const lastRound = updatedRounds[updatedRounds.length - 1];
			const newRound: TournamentRound = {
				...lastRound,
				roundNumber: lastRound.roundNumber + 1,
				date: new Date(lastRound.date.getTime() + 24 * 60 * 60 * 1000),
			};
			updatedRounds.push(newRound);
		} else {
			const newRound: TournamentRound = {
				roundNumber: 1,
				date: new Date(),
				course: null,
				format: GameModeEnum.StrokePlay,
				tee: null,
			};
			updatedRounds = [newRound];
		}

		setFieldValue('rounds', updatedRounds);
		setEditingRoundIndex(updatedRounds.length - 1);
		openModal();
	};

	const deleteRound = (indexToDelete: number) => {
		if (values.rounds) {
			// Filter out the round to be deleted
			const updatedRounds = values.rounds
				.filter((_, index) => index !== indexToDelete)
				.map((round, index) => ({
					...round,
					roundNumber: index + 1,
				}));
			setFieldValue('rounds', updatedRounds);
		}
	};

	const saveRound = (updatedRound: TournamentRound) => {
		if (editingRoundIndex !== null && values.rounds) {
			const updatedRounds = values.rounds.map((round) =>
				round.roundNumber === updatedRound.roundNumber
					? updatedRound
					: round
			);
			setFieldValue('rounds', updatedRounds);
			closeModal();
		}
	};

	const handleEdit = (index: number) => {
		setEditingRoundIndex(index);
		openModal();
	};

	return (
		<>
			<div className='flex w-full flex-col overflow-y-scroll items-center space-y-4'>
				{values.rounds && values.rounds.length > 0 ? (
					values.rounds.map(
						(round: TournamentRound, index: number) => (
							<RoundCard
								key={index}
								index={index}
								round={round}
								onDelete={deleteRound}
								onEdit={() => handleEdit(index)}
							/>
						)
					)
				) : (
					<Text variant='body2' color='default'>
						No rounds have been added yet.
					</Text>
				)}
				{(values.rounds === null || values.rounds.length < 6) && (
					<span
						className='flex bg-primary-blue-darker items-center justify-center border border-gray-200 w-10 h-10 shadow-sm rounded-2xl'
						onClick={addNewRound}
					>
						<FontAwesomeIcon
							icon={faPlus}
							className='text-white text-base'
						/>
					</span>
				)}
			</div>
			{editingRoundIndex !== null &&
				values.rounds &&
				values.rounds[editingRoundIndex] && (
					<Modal
						open={isModalOpen}
						onClose={closeModal}
						onExitComplete={() => setEditingRoundIndex(null)}
						animationType='slideRight'
						title={`Round ${values.rounds[editingRoundIndex].roundNumber}`}
					>
						<RoundDetails
							round={values.rounds[editingRoundIndex]}
							onSaveChanges={saveRound}
						/>
					</Modal>
				)}
		</>
	);
};

export default EditRoundsStep;
