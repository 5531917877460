import { Text } from '../../../../Components/ui/Text';
import { Input } from '../../../../Components/ui/Input';
import { useFormikContext } from 'formik';
import { TournamentFormData } from '../../types/TournamentFormData';

const ChooseNameStep = () => {
	const { values, handleChange, errors, touched } =
		useFormikContext<TournamentFormData>();

	return (
		<div className='flex flex-col'>
			<div className='mb-24'>
				<Text variant='h2'>What is the name of the Tournament?</Text>
				<Text variant='body2' color='default'>
					Choose a name that will give people an idea of what the
					Tournament is about. You can always change this later.
				</Text>
			</div>
			<Input
				label='Choose Name'
				name='tournamentName'
				value={values.tournamentName}
				onChange={handleChange}
			/>
			{touched.tournamentName && errors.tournamentName ? (
				<Text variant='fine1' color='error'>
					{errors.tournamentName}
				</Text>
			) : null}
		</div>
	);
};

export default ChooseNameStep;
