import { toast } from 'react-toastify';

export const handleApiError = (error: any) => {
	if (error?.data?.errors) {
		const errorDescription = error.data.errors[0]?.description;
		if (errorDescription) {
			toast.error(errorDescription);
			return;
		}
	}
	toast.error('An unexpected error occurred. Please contact support.');
};